import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { ReassignValidationSchema, termsOptions } from './utils';
import { CustomTextField } from 'components/CustomTextField';
import { handlePeriodos } from 'utils';
import CustomSelect from 'components/CustomSelect';
import { useOnSubmitReassign } from './useOnSubmitReassign';

export const ReassignModal = ({ open, handleClose, movementId }) => {
  const { handleSubmitReassign } = useOnSubmitReassign(handleClose, movementId);
  const { periodos: getPeriods } = handlePeriodos();
  const terms = getPeriods();

  const formik = useFormik({
    initialValues: {
      periodo: terms[0],
      matricula: '',
    },
    onSubmit: (values) => handleSubmitReassign(values, formik.resetForm),
    validateOnChange: true,
    validationSchema: ReassignValidationSchema,
  });

  const closeModal = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={closeModal} fullWidth>
      <DialogContent sx={{ padding: '32px 48px' }}>
        <DialogTitle color='primary.darker' sx={{ padding: 0 }}>
          Reasignar movimiento
        </DialogTitle>
      </DialogContent>
      <form onSubmit={formik.handleSubmit}>
        <Box
          display='flex'
          columnGap='32px'
          rowGap={['24px', '24px', '32px']}
          flexDirection={['column', 'column', 'row']}
          padding='0 48px 32px 48px'>
          <Box
            display='flex'
            flexDirection='column'
            rowGap='16px'
            width={['100%', '100%', '50%']}>
            <Typography variant='subtitle1' sx={{ color: 'secondary.main' }}>
              Matrícula del estudiante
            </Typography>
            <CustomTextField
              id='matricula'
              name='matricula'
              value={formik.values.matricula}
              onChange={formik.handleChange}
              error={formik.errors.matricula}
              fullWidth
            />
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            rowGap='16px'
            width={['100%', '100%', '50%']}>
            <Typography variant='subtitle1' sx={{ color: 'secondary.main' }}>
              Periodo
            </Typography>
            <CustomSelect
              id='periodo'
              name='periodo'
              options={termsOptions(terms)}
              value={formik.values.periodo}
              handleChange={formik.handleChange}
              error={formik.errors.periodo}
              fullWidth
            />
          </Box>
        </Box>

        <DialogActions sx={{ padding: '0 48px 32px 48px' }}>
          <Button onClick={closeModal}>Cancelar</Button>
          <Button variant='contained' type='submit'>
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
