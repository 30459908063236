import { Box, Typography } from '@mui/material';
import { pieArcLabelClasses, PieChart } from '@mui/x-charts';
import { memo, useContext } from 'react';
import { ResourcesContext } from 'state/ResourcesProvider';

export const Stats = memo(({ title }) => {
  const { state } = useContext(ResourcesContext);

  const debtorsData = state?.dashboard?.debtorsData;

  const enrolled = debtorsData?.totalEstudiantes;
  const debtors = debtorsData?.totalDeudores;
  const partialDebtors = debtorsData?.totalDeudoresParciales;
  const noDebtors = debtorsData?.totalNoDeudores;

  const graphData = [
    {
      id: 0,
      value: noDebtors,
      label: `No deudores: ${noDebtors}`,
      color: '#61C480',
    },
    {
      id: 1,
      value: debtors,
      label: `Deudores: ${debtors}`,
      color: '#C46173',
    },
    {
      id: 2,
      value: partialDebtors,
      label: `Deudores parciales: ${partialDebtors}`,
      color: '#FFC260',
    },
  ];
  return (
    <Box width='300px' display='grid' gap='16px'>
      <Box
        display='flex'
        alignItems='center'
        gap='8px'
        justifyContent='space-between'
        padding='16px'
        borderRadius='8px'
        bgcolor='secondary.darker'>
        <Typography variant='h6' color='grey.0'>
          Estudiantes inscritos en el perido: {enrolled}
        </Typography>
      </Box>
      <Box
        height='460px'
        width='100%'
        padding='24px'
        alignItems='center'
        justifyItems='center'
        display='grid'
        borderRadius='8px'
        boxShadow='0px 2px 4px 0px rgba(0, 0, 0, 0.25)'>
        <Typography variant='h6' color='primary.darker' textAlign='left'>
          {title}
        </Typography>
        <PieChart
          series={[
            {
              data: graphData,
              innerRadius: 70,
              outerRadius: 100,
              cx: 125,
              cy: 125,
              arcLabel: (item) =>
                Math.ceil((item.value / enrolled) * 100) + '%',
            },
          ]}
          slotProps={{
            legend: {
              position: {
                horizontal: 'middle',
                vertical: 'bottom',
              },
            },
          }}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontWeight: 'bold',
              fontSize: 12,
            },
          }}
        />
      </Box>
    </Box>
  );
});
