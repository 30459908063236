import { Box, IconButton, Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GET_CENTROS_EDUCATIVOS } from 'utils/api';

export const EducationalCentersList = () => {
  const navigate = useNavigate();

  const { isLoading, data } = useQuery(
    ['educationalCenters'],
    () => GET_CENTROS_EDUCATIVOS(),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Box display='flex' flexDirection='column' gap='8px'>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '70vh',
          }}>
          <ClipLoader color='#61C4B2' />
        </Box>
      ) : (
        data?.data?.centros?.map((center) => (
          <Box
            key={center.id}
            borderBottom='1px solid rgb(230 229 231 / 70%)'
            padding='1rem'
            display='flex'
            flexDirection='column'
            gap='8px'>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'>
              <Box id='center-name'>
                <Typography variant='subtitle1' color='secondary'>
                  {center.nombreCentroEducativo}
                </Typography>
              </Box>
              <Box
                id='center-key'
                display='flex'
                alignItems='center'
                gap='16px'>
                <Typography variant='subtitle1'>{center.clave}</Typography>
                <IconButton
                  sx={{
                    color: 'grey.600',
                    justifySelf: 'end',
                  }}
                  onClick={() =>
                    navigate(
                      `/c/adminEscolar/centros-educativos/editar/${center.id}`,
                      {
                        state: {
                          title: 'Editar Centro Educativo',
                          type: 'edit',
                          elementKey: center.clave,
                        },
                      }
                    )
                  }>
                  <EditIcon />
                </IconButton>
              </Box>
            </Box>
            <Box
              display='grid'
              gridTemplateColumns={['100%', 'repeat(2, 50%)']}
              gap='16px'>
              {center.Direccion?.Usuario && (
                <Box id='manager'>
                  <Typography variant='subtitle2' color='primary'>
                    Encargado
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{
                      wordBreak: 'break-word',
                      width: '100%',
                    }}>
                    {center.Direccion?.Usuario?.nombre}
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{
                      wordBreak: 'break-word',
                      width: '100%',
                    }}>
                    {center.Direccion?.Usuario?.correo}
                  </Typography>
                </Box>
              )}
              {center.Direccion?.calle && (
                <Box id='address'>
                  <Typography variant='subtitle2' color='primary'>
                    Ubicación
                  </Typography>
                  <Box
                    display='grid'
                    gridTemplateColumns={['1fr', 'repeat(2, 50%)']}
                    alignContent={['flex-start', 'center']}
                    gap={[0, '16px']}>
                    <Typography
                      variant='body2'
                      sx={{
                        wordBreak: 'break-word',
                        width: '100%',
                      }}>
                      Calle: {center.Direccion?.calle}
                    </Typography>
                    <Typography variant='body2'>
                      Num. exterior: {center.Direccion?.numExt}
                    </Typography>
                  </Box>
                  <Box
                    display='grid'
                    gridTemplateColumns={['1fr', 'repeat(2, 50%)']}
                    alignContent={['flex-start', 'center']}
                    gap={[0, '16px']}>
                    <Typography variant='body2'>
                      C.P.: {center.Direccion?.codigoPostal}
                    </Typography>
                    {center.Direccion?.numInt && (
                      <Typography variant='body2'>
                        Num. interior: {center.Direccion?.numInt}
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        ))
      )}
    </Box>
  );
};
