import { useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';

export const UserOptions = ({ actions, user }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {actions.map((action) => (
          <MenuItem
            key={action.key}
            onClick={() => {
              action.onClick(user);
              handleClose();
            }}>
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
