export const Paises = [
  { id: 1, nombre: 'Afganistán', codigo: 'AFG' },
  { id: 2, nombre: 'Albania', codigo: 'ALB' },
  { id: 3, nombre: 'Alemania', codigo: 'DEU' },
  { id: 4, nombre: 'Andorra', codigo: 'AND' },
  { id: 5, nombre: 'Angola', codigo: 'AGO' },
  { id: 6, nombre: 'Anguila', codigo: 'AIA' },
  { id: 7, nombre: 'Antártida', codigo: 'ATA' },
  { id: 8, nombre: 'Antigua y Bermuda', codigo: 'ATG' },
  { id: 9, nombre: 'Arabia Saudita', codigo: 'SAU' },
  { id: 10, nombre: 'Argelia', codigo: 'DZA' },
  { id: 11, nombre: 'Argentina', codigo: 'ARG' },
  { id: 12, nombre: 'Armenia', codigo: 'AM' },
  { id: 13, nombre: 'Aruba', codigo: 'ABW' },
  { id: 14, nombre: 'Australia', codigo: 'AUS' },
  { id: 15, nombre: 'Austria', codigo: 'AUT' },
  { id: 16, nombre: 'Azerbayán', codigo: 'AZE' },
  { id: 17, nombre: 'Bahamas', codigo: 'BHS' },
  { id: 18, nombre: 'Bangladés', codigo: 'BGD' },
  { id: 19, nombre: 'Barbados', codigo: 'BRB' },
  { id: 20, nombre: 'Baréin', codigo: 'BHR' },
  { id: 21, nombre: 'Bélgica', codigo: 'BEL' },
  { id: 22, nombre: 'Belice', codigo: 'BLZ' },
  { id: 23, nombre: 'Benín', codigo: 'BEN' },
  { id: 24, nombre: 'Bermudas', codigo: 'BMU' },
  { id: 25, nombre: 'Bielorusia', codigo: 'BLR' },
  { id: 26, nombre: 'Bolivia', codigo: 'BOL' },
  { id: 27, nombre: 'Bosnia y Herzegovina', codigo: 'BIH' },
  { id: 28, nombre: 'Botsuana', codigo: 'BWA' },
  { id: 29, nombre: 'Brasil', codigo: 'BRA' },
  { id: 30, nombre: 'Brunéi Darussalam', codigo: 'BRN' },
  { id: 31, nombre: 'Bulgaria', codigo: 'BGR' },
  { id: 32, nombre: 'Burkina Faso', codigo: 'BFA' },
  { id: 33, nombre: 'Burundi', codigo: 'BDI' },
  { id: 34, nombre: 'Bután', codigo: 'BTN' },
  { id: 35, nombre: 'Cabo Verde', codigo: 'CPV' },
  { id: 36, nombre: 'Camboya', codigo: 'KHM' },
  { id: 37, nombre: 'Camerún', codigo: 'CMR' },
  { id: 38, nombre: 'Canadá', codigo: 'CAN' },
  { id: 39, nombre: 'Chad', codigo: 'TCD' },
  { id: 40, nombre: 'Chile', codigo: 'CHL' },
  { id: 41, nombre: 'China', codigo: 'CHN' },
  { id: 42, nombre: 'Chipre', codigo: 'CYP' },
  { id: 43, nombre: 'Colombia', codigo: 'COL' },
  { id: 44, nombre: 'Comoras', codigo: 'COM' },
  { id: 45, nombre: 'Congo', codigo: 'COG' },
  { id: 46, nombre: 'Corea, República de', codigo: 'KOR' },
  { id: 47, nombre: 'Corea, República Democrática Popular de', codigo: 'PRK' },
  { id: 48, nombre: 'Costa de Marfil', codigo: 'CIV' },
  { id: 49, nombre: 'Costa Rica', codigo: 'CRI' },
  { id: 50, nombre: 'Croacia', codigo: 'HRV' },
  { id: 51, nombre: 'Cuba', codigo: 'CUB' },
  { id: 52, nombre: 'Curazao', codigo: 'CUW' },
  { id: 53, nombre: 'Dinamarca', codigo: 'DNK' },
  { id: 54, nombre: 'Dominica', codigo: 'DMA' },
  { id: 55, nombre: 'Ecuador', codigo: 'ECU' },
  { id: 56, nombre: 'Egipto', codigo: 'EGY' },
  { id: 57, nombre: 'El Salvador', codigo: 'SLV' },
  { id: 58, nombre: 'Emiratos Árabes Unidos', codigo: 'ARE' },
  { id: 59, nombre: 'Eritrea', codigo: 'ERI' },
  { id: 60, nombre: 'Eslovaquia', codigo: 'SVK' },
  { id: 61, nombre: 'Eslovenia', codigo: 'SVN' },
  { id: 62, nombre: 'España', codigo: 'ESP' },
  { id: 63, nombre: 'Estados Unidos de América', codigo: 'USA' },
  { id: 64, nombre: 'Estonia', codigo: 'EST' },
  { id: 65, nombre: 'Etiopía', codigo: 'ETH' },
  { id: 66, nombre: 'Filipinas', codigo: 'PHL' },
  { id: 67, nombre: 'Finlandia', codigo: 'FIN' },
  { id: 68, nombre: 'Fiji', codigo: 'FJI' },
  { id: 69, nombre: 'Francia', codigo: 'FRA' },
  { id: 70, nombre: 'Gabón', codigo: 'GAB' },
  { id: 71, nombre: 'Gambia', codigo: 'GMB' },
  { id: 72, nombre: 'Georgia', codigo: 'GEO' },
  { id: 73, nombre: 'Ghana', codigo: 'GHA' },
  { id: 74, nombre: 'Gibraltar', codigo: 'GIB' },
  { id: 75, nombre: 'Granada', codigo: 'GRD' },
  { id: 76, nombre: 'Grecia', codigo: 'GRC' },
  { id: 77, nombre: 'Groenlandia', codigo: 'GRL' },
  { id: 78, nombre: 'Guadalupe', codigo: 'GLP' },
  { id: 79, nombre: 'Guam', codigo: 'GUM' },
  { id: 80, nombre: 'Guatemala', codigo: 'GTM' },
  { id: 81, nombre: 'Guayana Francesa', codigo: 'GUF' },
  { id: 82, nombre: 'Guernsey', codigo: 'GGY' },
  { id: 83, nombre: 'Guinea-Bisáu', codigo: 'GNB' },
  { id: 84, nombre: 'Guinea Ecuatorial', codigo: 'GNQ' },
  { id: 85, nombre: 'Guinea', codigo: 'GIN' },
  { id: 86, nombre: 'Guyana', codigo: 'GUY' },
  { id: 87, nombre: 'Haití', codigo: 'HTI' },
  { id: 88, nombre: 'Honduras', codigo: 'HND' },
  { id: 89, nombre: 'Hong Kong', codigo: 'HKG' },
  { id: 90, nombre: 'Hungria', codigo: 'HUN' },
  { id: 91, nombre: 'India', codigo: 'IND' },
  { id: 92, nombre: 'Indonesia', codigo: 'IDN' },
  { id: 93, nombre: 'Irak', codigo: 'IRQ' },
  { id: 94, nombre: 'Irán', codigo: 'IRN' },
  { id: 95, nombre: 'Irlanda', codigo: 'IRL' },
  { id: 96, nombre: 'Isla Bouvet', codigo: 'BVT' },
  { id: 97, nombre: 'Isla de Man', codigo: 'IMN' },
  { id: 98, nombre: 'Isla de Navidad', codigo: 'CXR' },
  { id: 99, nombre: 'Isla Norfolk', codigo: 'NFK' },
  { id: 100, nombre: 'Islandia', codigo: 'ISL' },
  { id: 101, nombre: 'Islas Åland', codigo: 'ALA' },
  { id: 102, nombre: 'Islas Caimán', codigo: 'CYM' },
  { id: 103, nombre: 'Islas Cocos (Keeling)', codigo: 'CCK' },
  { id: 104, nombre: 'Islas Cook', codigo: 'COK' },
  { id: 105, nombre: 'Islas Falkland (Malvinas)', codigo: 'FLK' },
  { id: 106, nombre: 'Islas Feroe', codigo: 'FRO' },
  {
    id: 107,
    nombre: 'Islas Georgias del Sur y Sandwich del Sur',
    codigo: 'SGS',
  },
  { id: 108, nombre: 'Islas Heard y Mcdonald', codigo: 'HMD' },
  { id: 109, nombre: 'Islas Marianas del Norte', codigo: 'MNP' },
  { id: 110, nombre: 'Islas Marshall', codigo: 'MHL' },
  { id: 111, nombre: 'Islas Salomón', codigo: 'SLB' },
  { id: 112, nombre: 'Islas Turcas y Caicos', codigo: 'TCA' },
  {
    id: 113,
    nombre: 'Islas Ultramarinas Menores de Estados Unidos',
    codigo: 'UMI',
  },
  { id: 114, nombre: 'Islas Vírgenes Británicas', codigo: 'VGB' },
  { id: 115, nombre: 'Islas Vírgenes de Los Estados Unidos', codigo: 'VIR' },
  { id: 116, nombre: 'Israel', codigo: 'ISR' },
  { id: 117, nombre: 'Italia', codigo: 'ITA' },
  { id: 118, nombre: 'Jamaica', codigo: 'JAM' },
  { id: 119, nombre: 'Japón', codigo: 'JPN' },
  { id: 120, nombre: 'Jersey', codigo: 'JEY' },
  { id: 121, nombre: 'Jordania', codigo: 'JOR' },
  { id: 122, nombre: 'Kazajistan', codigo: 'KAZ' },
  { id: 123, nombre: 'Kenia', codigo: 'KEN' },
  { id: 124, nombre: 'Kirguistán', codigo: 'KGZ' },
  { id: 125, nombre: 'Kiribati', codigo: 'KIR' },
  { id: 126, nombre: 'Kuwait', codigo: 'KWT' },
  { id: 127, nombre: 'Lesoto', codigo: 'LSO' },
  { id: 128, nombre: 'Letonia', codigo: 'LVA' },
  { id: 129, nombre: 'Líbano', codigo: 'LBN' },
  { id: 130, nombre: 'Liberia', codigo: 'LBR' },
  { id: 131, nombre: 'Liechtenstein', codigo: 'LIE' },
  { id: 132, nombre: 'Lituania', codigo: 'LTU' },
  { id: 133, nombre: 'Macao', codigo: 'MAC' },
  { id: 134, nombre: 'Luxemburgo', codigo: 'LUX' },
  { id: 135, nombre: 'Macedonia', codigo: 'MKD' },
  { id: 136, nombre: 'Madagascar', codigo: 'MDG' },
  { id: 137, nombre: 'Malasia', codigo: 'MYS' },
  { id: 138, nombre: 'Malaui', codigo: 'MWI' },
  { id: 139, nombre: 'Maldivas', codigo: 'MDV' },
  { id: 140, nombre: 'Mali', codigo: 'MLI' },
  { id: 141, nombre: 'Malta', codigo: 'MLT' },
  { id: 142, nombre: 'Marruecos', codigo: 'MAR' },
  { id: 143, nombre: 'Martinica', codigo: 'MTQ' },
  { id: 144, nombre: 'Mauricio', codigo: 'MUS' },
  { id: 145, nombre: 'Mauritania', codigo: 'MRT' },
  { id: 146, nombre: 'México', codigo: 'MEX' },
  { id: 147, nombre: 'Micronesia', codigo: 'FSM' },
  { id: 148, nombre: 'Moldavia', codigo: 'MDA' },
  { id: 149, nombre: 'Mónaco', codigo: 'MCO' },
  { id: 150, nombre: 'Mongolia', codigo: 'MNG' },
  { id: 151, nombre: 'Montenegro', codigo: 'MNE' },
  { id: 152, nombre: 'Montserrat', codigo: 'MSR' },
  { id: 153, nombre: 'Mozambique', codigo: 'MOZ' },
  { id: 154, nombre: 'Myanmar', codigo: 'MMR' },
  { id: 155, nombre: 'Namibia', codigo: 'NAM' },
  { id: 156, nombre: 'Nauru', codigo: 'NRU' },
  { id: 157, nombre: 'Nepal', codigo: 'NPL' },
  { id: 158, nombre: 'Nicaragua', codigo: 'NIC' },
  { id: 159, nombre: 'Nigeria', codigo: 'NGA' },
  { id: 160, nombre: 'Niger', codigo: 'NER' },
  { id: 161, nombre: 'Niue', codigo: 'NIU' },
  { id: 162, nombre: 'Noruega', codigo: 'NOR' },
  { id: 163, nombre: 'Nueva Caledonia', codigo: 'NCL' },
  { id: 164, nombre: 'Nueva Zelanda', codigo: 'NZL' },
  { id: 165, nombre: 'Omán', codigo: 'OMN' },
  { id: 166, nombre: 'Países Bajos', codigo: 'NLD' },
  { id: 167, nombre: 'Pakistan', codigo: 'PAK' },
  { id: 168, nombre: 'Palaos', codigo: 'PLW' },
  { id: 169, nombre: 'Palestina', codigo: 'PSE' },
  { id: 170, nombre: 'Panamá', codigo: 'PAN' },
  { id: 171, nombre: 'Papúa Nueva Guinea', codigo: 'PNG' },
  { id: 172, nombre: 'Paraguay', codigo: 'PRY' },
  { id: 173, nombre: 'Perú', codigo: 'PER' },
  { id: 174, nombre: 'Pitcairn', codigo: 'PCN' },
  { id: 175, nombre: 'Polinesia Francesa', codigo: 'PYF' },
  { id: 176, nombre: 'Polonia', codigo: 'POL' },
  { id: 177, nombre: 'Portugal', codigo: 'PRT' },
  { id: 178, nombre: 'Puerto Rico', codigo: 'PRI' },
  { id: 179, nombre: 'Qatar', codigo: 'QAT' },
  { id: 180, nombre: 'Reino Unido', codigo: 'GBR' },
  { id: 181, nombre: 'República Centroafricana', codigo: 'CAF' },
  { id: 182, nombre: 'República Democrática Popular Lao', codigo: 'LAO' },
  { id: 183, nombre: 'República Dominicana', codigo: 'DOM' },
  { id: 184, nombre: 'Reunión', codigo: 'REU' },
  { id: 185, nombre: 'Ruanda', codigo: 'RWA' },
  { id: 186, nombre: 'Rumania', codigo: 'ROU' },
  { id: 187, nombre: 'Rusia', codigo: 'RUS' },
  { id: 188, nombre: 'Sahara Occidental', codigo: 'ESH' },
  { id: 189, nombre: 'Samoa Americana', codigo: 'ASM' },
  { id: 190, nombre: 'Samoa', codigo: 'WSM' },
  { id: 191, nombre: 'San Bartolomé', codigo: 'BLM' },
  { id: 192, nombre: 'San Cristóbal y Nieves', codigo: 'KNA' },
  { id: 193, nombre: 'San Marino', codigo: 'SMR' },
  { id: 194, nombre: 'San Martín', codigo: 'MAF' },
  { id: 195, nombre: 'San Vicente y Las Granadinas', codigo: 'VCT' },
  {
    id: 196,
    nombre: 'Santa Helena Ascensión y Tristán de Acuña',
    codigo: 'SHN',
  },
  { id: 197, nombre: 'Santa Lucía', codigo: 'LCA' },
  { id: 198, nombre: 'Santa Sede (Ciudad Estado Vaticano)', codigo: 'VAT' },
  { id: 199, nombre: 'Santo Tomé y Principe', codigo: 'STP' },
  { id: 200, nombre: 'Senegal', codigo: 'SEN' },
  { id: 201, nombre: 'Serbia', codigo: 'SRB' },
  { id: 202, nombre: 'Seychelles', codigo: 'SYC' },
  { id: 203, nombre: 'Sierra Leona', codigo: 'SLE' },
  { id: 204, nombre: 'Singapur', codigo: 'SGP' },
  { id: 205, nombre: 'Sint Maarten', codigo: 'SXM' },
  { id: 206, nombre: 'Siria', codigo: 'SYR' },
  { id: 207, nombre: 'Somalia', codigo: 'SOM' },
  { id: 208, nombre: 'Sri Lanka', codigo: 'LKA' },
  { id: 209, nombre: 'Suazilandia', codigo: 'SWZ' },
  { id: 210, nombre: 'Sudáfrica', codigo: 'ZAF' },
  { id: 211, nombre: 'Sudán del Sur', codigo: 'SSD' },
  { id: 212, nombre: 'Sudán', codigo: 'SDN' },
  { id: 213, nombre: 'Suecia', codigo: 'SWE' },
  { id: 214, nombre: 'Suiza', codigo: 'CHE' },
  { id: 215, nombre: 'Surinam', codigo: 'SUR' },
  { id: 216, nombre: 'Svalbard y Jan Mayen', codigo: 'SJM' },
  { id: 217, nombre: 'Tailandia', codigo: 'THA' },
  { id: 218, nombre: 'Taiwán', codigo: 'TWN' },
  { id: 219, nombre: 'Tanzania', codigo: 'TZA' },
  { id: 220, nombre: 'Tayikistán', codigo: 'TJK' },
  { id: 221, nombre: 'Timor-Leste', codigo: 'TLS' },
  { id: 222, nombre: 'Togo', codigo: 'TGO' },
  { id: 223, nombre: 'Tokelau', codigo: 'TKL' },
  { id: 224, nombre: 'Tonga', codigo: 'TON' },
  { id: 225, nombre: 'Trinidad y Tobago', codigo: 'TTO' },
  { id: 226, nombre: 'Túnez', codigo: 'TUN' },
  { id: 227, nombre: 'Turkmenistán', codigo: 'TKM' },
  { id: 228, nombre: 'Turquia', codigo: 'TUR' },
  { id: 229, nombre: 'Tuvalu', codigo: 'TUV' },
  { id: 230, nombre: 'Ucrania', codigo: 'UKR' },
  { id: 231, nombre: 'Uganda', codigo: 'UGA' },
  { id: 232, nombre: 'Uruguay', codigo: 'URY' },
  { id: 233, nombre: 'Uzbekistan', codigo: 'UZB' },
  { id: 234, nombre: 'Vanuatu', codigo: 'VUT' },
  { id: 235, nombre: 'Venezuela', codigo: 'VEN' },
  { id: 236, nombre: 'Vietnam', codigo: 'VNM' },
  { id: 237, nombre: 'Wallis y Futuna', codigo: 'WLF' },
  { id: 238, nombre: 'Yemen', codigo: 'YEM' },
  { id: 239, nombre: 'Yibuti', codigo: 'DJI' },
  { id: 240, nombre: 'Zambia', codigo: 'ZMB' },
  { id: 241, nombre: 'Zimbabue', codigo: 'ZWE' },
];
