import CancelIcon from '@mui/icons-material/Cancel';
import Confirma from 'utils/Confirma';
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import EditIcon from '@mui/icons-material/Edit';
import Error from 'components/Error';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import esLocale from 'date-fns/locale/es';
import useForm from 'utils/useForm';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Paises } from 'utils/Paises';
import { ResourcesContext } from 'state/ResourcesProvider';
import { handleAlerts } from 'utils';
import { PUT_STUDENT } from 'utils/api';

const StudentInfoEdit = () => {
  const { state, dispatch } = React.useContext(ResourcesContext);
  const { estudiante: student } = state;
  const initialData = {
    nombre: student?.Persona.nombre,
    apellidoPaterno: student?.Persona.apellidoPaterno,
    apellidoMaterno: student?.Persona.apellidoMaterno,
    sexo: student?.Persona.sexo,
    matricula: student?.matricula,
    fechaNacimiento: student?.Persona.fechaNacimiento,
    religion: student?.Persona.ReligionId,
    centroEducativo: student?.CentroEducativoId,
    CURP: student?.Persona?.CURP,
    suspendido: student?.suspendido,
    marcarReg: student?.marcarReg,
    observaciones: student?.observaciones,
    PaisId: student?.PaisId,
  };

  const [initialState, setInitialState] = React.useState(initialData);
  const [editInfo, setEditInfo] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const { successMessage, errorMessage } = handleAlerts();

  const resetEstudiante = () => setInitialState(initialData);
  const handleEdit = () => setEditInfo(true);
  const handleCancel = () => setOpen(true);

  const validations = {
    nombre: {
      required: {
        message: 'El nombre es requerido',
      },
      custom: {
        isValid: (value) => value.length >= 3,
        message: 'Debe tener al menos tres caracteres de longitud',
      },
    },
    apellidoPaterno: {
      required: {
        message: 'Es requerido el apellido paterno',
      },
    },
    sexo: {
      required: {
        message: 'Es requerido el sexo del estudiante',
      },
    },
    religion: {
      required: {
        message: 'Es requerida la religión',
      },
    },
    centroEducativo: {
      required: {
        message: 'Es requerido el centro educativo',
      },
    },
    PaisId: {
      required: {
        message: 'Es requerida la nacionalidad',
      },
    },
    fechaNacimiento: {
      required: {
        message: 'La fecha de nacimiento es requerida',
      },
      custom: {
        isValid: (value) => {
          const fechaNac = new Date(value);
          const now = new Date();
          const time = now.getTime() - fechaNac.getTime();
          return time > 441504000000;
        },
        message:
          'Verifica la fecha de nacimiento, no puede tener menos de 14 años',
      },
    },
  };

  const onSubmit = async (data) => {
    try {
      Object.keys(data).forEach((key) => {
        if (data[key] === '') {
          data[key] = null;
        }
      });
      const response = await PUT_STUDENT(student.matricula, data);
      const estudiante = response.data.estudiante;
      dispatch({ type: 'SET_ESTUDIANTE', payload: estudiante });
      setEditInfo(false);
      successMessage('Se ha guardado de manera exitosa.');
    } catch (error) {
      console.log(
        'Error',
        error.response?.data?.message || error?.message || error
      );
      errorMessage('Ha ocurrido un error');
    }
  };

  const {
    data,
    handleChange,
    handleSubmit,
    handleChangeCustom,
    errors,
    reset,
  } = useForm({
    onSubmit: onSubmit,
    initialValues: initialState,
    validations: validations,
  });

  React.useEffect(() => {
    reset();
    setInitialState({
      nombre: student?.Persona.nombre,
      apellidoPaterno: student?.Persona.apellidoPaterno,
      apellidoMaterno: student?.Persona.apellidoMaterno,
      sexo: student?.Persona.sexo,
      matricula: student?.matricula,
      fechaNacimiento: student?.Persona.fechaNacimiento,
      religion: student?.Persona.ReligionId,
      centroEducativo: student?.CentroEducativoId,
      CURP: student?.Persona?.CURP,
      suspendido: student?.suspendido,
      marcarReg: student?.marcarReg,
      observaciones: student?.observaciones,
      PaisId: student?.PaisId,
    });
  }, [student]);

  const handleFecha = (date) => {
    handleChangeCustom('fechaNacimiento', date);
  };

  const handleSuspendido = (event) => {
    handleChangeCustom('suspendido', event.target.checked);
  };

  return (
    <>
      <Confirma
        titulo='¿Deseas descartar los cambios?'
        open={open}
        setOpen={setOpen}
        action={() => {
          resetEstudiante();
          setOpen(false);
          setEditInfo(false);
        }}
      />
      <Card>
        <CardHeader
          title={
            <Typography variant='subtitle2'>
              Información Básica del Estudiante
            </Typography>
          }
          action={
            editInfo ? (
              <>
                <Tooltip title='Guardar edición' placement='top'>
                  <IconButton size='small' onClick={handleSubmit}>
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Cancelar edición' placement='top'>
                  <IconButton size='small' onClick={handleCancel}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title='Editar' placement='top'>
                  <IconButton size='small' onClick={handleEdit}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </>
            )
          }
        />
        <CardContent>
          <Grid container spacing={2} alignItems='stretch'>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id='nombres'
                label='Nombre(s)'
                value={data.nombre || ''}
                disabled={!editInfo}
                onChange={handleChange('nombre')}
              />
              {errors.nombre && <Error>{errors.nombre}</Error>}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id='apellidoP'
                label='Apellido Paterno'
                value={data.apellidoPaterno || ''}
                disabled={!editInfo}
                onChange={handleChange('apellidoPaterno')}
              />
              {errors.apellidoPaterno && (
                <Error>{errors.apellidoPaterno}</Error>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id='apellidoM'
                label='Apellido Materno'
                value={data.apellidoMaterno || ''}
                disabled={!editInfo}
                onChange={handleChange('apellidoMaterno')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id='select-sex-label'>Sexo</InputLabel>
                <Select
                  labelId='select-sex-label'
                  id='select-sex'
                  value={data.sexo || ''}
                  onChange={handleChange('sexo')}
                  disabled={!editInfo}
                  label='Sexo'
                >
                  <MenuItem value='Masculino' name='sexo'>
                    Masculino
                  </MenuItem>
                  <MenuItem value='Femenino' name='sexo'>
                    Femenino
                  </MenuItem>
                </Select>
              </FormControl>
              {errors.sexo && <Error>{errors.sexo}</Error>}
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider
                dateAdapter={DateAdapter}
                adapterLocale={esLocale}
              >
                <DesktopDatePicker
                  label='Fecha de nacimiento'
                  inputFormat='dd/MM/yyyy'
                  value={data.fechaNacimiento}
                  disabled={!editInfo}
                  onChange={handleFecha}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
              {errors.fechaNacimiento && (
                <Error>{errors.fechaNacimiento}</Error>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id='curp'
                label='CURP'
                disabled={!editInfo}
                value={data.CURP || ''}
                onChange={handleChange('CURP')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id='select-religion-label'>Religión</InputLabel>
                <Select
                  labelId='select-religion-label'
                  id='select-religion'
                  defaultValue=''
                  disabled={!editInfo}
                  label='Religión'
                  value={data.religion ?? ''}
                  onChange={handleChange('religion')}
                >
                  <MenuItem value=''>Sin asignar valor</MenuItem>
                  {state.religiones?.map((religion) => (
                    <MenuItem key={religion.id} value={religion.id}>
                      {religion.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.religion && <Error>{errors.religion}</Error>}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id='select-centros-label'>
                  Centro Educativo
                </InputLabel>
                <Select
                  labelId='select-centros-label'
                  label='Centro Educativo'
                  id='select-centros'
                  disabled={!editInfo}
                  value={data.centroEducativo ?? ''}
                  onChange={handleChange('centroEducativo')}
                >
                  <MenuItem value=''>Sin asignar valor</MenuItem>
                  {state.centros?.map((centro) => (
                    <MenuItem key={centro.id} value={centro.id}>
                      {centro.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.centroEducativo && (
                <Error>{errors.centroEducativo}</Error>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                disabled={!editInfo}
                id='marcarReg'
                label='Marca'
                name='marcarReg'
                value={data.marcarReg ?? ''}
                onChange={handleChange('marcarReg')}
              >
                <MenuItem value=''>
                  <em>Ninguno</em>
                </MenuItem>
                <MenuItem value='1'>
                  <span>1</span>
                </MenuItem>
                <MenuItem value='2'>
                  <span>2</span>
                </MenuItem>
                <MenuItem value='3'>
                  <span>3</span>
                </MenuItem>
                <MenuItem value='4'>
                  <span>4</span>
                </MenuItem>
                <MenuItem value='5'>
                  <span>5</span>
                </MenuItem>
                <MenuItem value='6'>
                  <span>6</span>
                </MenuItem>
                <MenuItem value='7'>
                  <span>7</span>
                </MenuItem>
                <MenuItem value='8'>
                  <span>8</span>
                </MenuItem>
                <MenuItem value='9'>
                  <span>9</span>
                </MenuItem>
                <MenuItem value='10'>
                  <span>10</span>
                </MenuItem>
                <MenuItem value='11'>
                  <span>11</span>
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                disabled={!editInfo}
                control={
                  <Switch
                    checked={data.suspendido || false}
                    onChange={handleSuspendido}
                    name='suspendido'
                  />
                }
                label='Suspendido'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id='select-nacionalidad-label'>
                  Nacionalidad
                </InputLabel>
                <Select
                  labelId='select-nacionalidad-label'
                  label='Nacionalidad'
                  id='select-nacionalidad'
                  value={data.PaisId || ''}
                  disabled={!editInfo}
                  onChange={handleChange('PaisId')}
                >
                  {Paises.map((pais) => (
                    <MenuItem key={pais.id} value={pais.id}>
                      {pais.nombre}
                    </MenuItem>
                  ))}
                </Select>
                {errors.PaisId && <Error>{errors.PaisId}</Error>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                maxRows={3}
                fullWidth
                disabled={!editInfo}
                id='observaciones'
                label='Observaciones'
                value={data.observaciones || ''}
                onChange={handleChange('observaciones')}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default StudentInfoEdit;
