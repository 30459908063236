import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useContext, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  TableHead,
  TablePagination,
  Typography,
} from '@mui/material';
import { UserOptions } from './UserOptions';
import { StyledTableCell } from './StyledCell';
import { ChangePasswordModal } from './ChangePasswordModal';
import { CustomTextField } from 'components/CustomTextField';
import CustomSelect from 'components/CustomSelect';
import { ResourcesContext } from 'state/ResourcesProvider';
import { fromCamelCaseToTitle } from 'utils';
import { EditUserModal } from './EditUserModal';
import Confirma from 'utils/Confirma';
import { useHandleUsers } from './hooks/useHandleUsers';
import { TablePaginationActions } from 'components/TablePaginationActions';

export const UsersTable = ({
  rows,
  typeOfFilter,
  searchParam,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  setTypeOfFilter,
  setSearchParam,
  count,
  refetch,
}) => {
  const [changePasswordModal, setChangePasswordModal] = useState({
    open: false,
    user: null,
  });
  const [editModal, setEditModal] = useState({
    open: false,
    initialValues: null,
    type: null,
    handleClose: () => {},
  });
  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    userId: null,
  });

  const { state, dispatch } = useContext(ResourcesContext);
  const {
    usersFilters: { selectedStatusFilter },
  } = state;

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseEditModal = () => {
    setEditModal({ open: false, user: null, type: null });
  };

  const addRole = (roles) => {
    const rolesName = roles.map((role) => role.nombre);
    return (
      rolesName.includes('administrativo') || rolesName.includes('sistemas')
    );
  };

  const { deleteUser } = useHandleUsers({
    handleClose: () => {
      setOpenDeleteModal(false);
    },
    refetch,
  });
  const rowActions = [
    {
      key: 'changePassword',
      label: 'Cambiar contraseña',
      onClick: (user) => {
        setChangePasswordModal({ open: true, user });
      },
    },
    {
      key: 'edit',
      label: 'Editar',
      onClick: (user) => {
        setEditModal({
          open: true,
          type: 'edit',
          initialValues: {
            userEmail: user?.correo,
            ...(addRole(user?.Roles) && { role: user?.Roles?.[0]?.nombre }),
          },
          handleClose: handleCloseEditModal,
          refetch,
          userId: user?.id,
        });
      },
    },
    {
      key: 'delete',
      label: 'Eliminar',
      onClick: (user) => {
        setOpenDeleteModal({ open: true, userId: user.id });
      },
    },
  ];

  return (
    <Box display='flex' flexDirection='column'>
      {editModal.open && <EditUserModal editModalData={editModal} />}
      <Confirma
        open={openDeleteModal.open}
        setOpen={setOpenDeleteModal}
        titulo='¿Deseas eliminar el usuario?'
        action={() => deleteUser(openDeleteModal.userId)}
      />
      <Box
        display='flex'
        flexDirection={['column', 'column', 'column', 'row']}
        justifyContent='space-between'
        alignItems='center'
        padding='24px'
        gap='24px'
        bgcolor='secondary.darker'
        borderRadius='8px'>
        <Box
          display='flex'
          gap='24px'
          width={['100%', '100%', '100%', '500px']}>
          <Box width='calc(100% - 150px)'>
            <CustomTextField
              sx={{ width: '100%' }}
              placeholder='Buscar'
              value={searchParam}
              onChange={(e) => setSearchParam(e.target.value)}
            />
          </Box>
          <Box width='150px'>
            <CustomSelect
              fullWidth
              placeholder='Filtrar por'
              options={[
                { value: 'user', label: 'Usuario' },
                { value: 'email', label: 'Correo' },
              ]}
              handleChange={(e) => setTypeOfFilter(e.target.value)}
              value={typeOfFilter}
            />
          </Box>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          flexDirection={['column', 'row']}
          gap='24px'
          width={['100%', '100%', '100%', '400px']}>
          <Box width={['100%', 'calc(100% - 170px)']}>
            <CustomSelect
              fullWidth
              placeholder='Filtrar por'
              options={[
                { value: 'cuentaCreada', label: 'Cuenta creada' },
                { value: 'conDatosPersonales', label: 'Con datos personales' },
                { value: 'conDireccion', label: 'Con dirección' },
              ]}
              handleChange={(e) =>
                dispatch({
                  type: 'SET_USERS_FILTERS',
                  payload: { selectedStatusFilter: e.target.value },
                })
              }
              value={selectedStatusFilter}
            />
          </Box>
          <Button
            variant='outlined'
            color='secondary'
            sx={{ bgcolor: '#fff' }}
            onClick={() => {
              setEditModal({
                open: true,
                type: 'add',
                initialValues: {
                  userEmail: '',
                  role: '',
                  pass: '',
                },
                handleClose: handleCloseEditModal,
                refetch,
              });
            }}>
            Agregar usuario
          </Button>
        </Box>
      </Box>
      <Box
        padding='16px 24px'
        display='flex'
        alignItems='center'
        gap='24px'
        flexWrap='wrap'>
        <Typography variant='subtitle1' color='text.secondary'>
          Filtros aplicados:
        </Typography>
        {selectedStatusFilter && (
          <Chip
            label={fromCamelCaseToTitle(selectedStatusFilter)}
            color='primary'
            onDelete={() =>
              dispatch({
                type: 'SET_USERS_FILTERS',
                payload: { selectedStatusFilter: null },
              })
            }
          />
        )}
      </Box>
      <Box
        display='grid'
        overflow='auto'
        width='100%'
        padding='24px'
        borderRadius='8px'
        boxShadow='0px 2px 4px 0px rgba(0, 0, 0, 0.25)'>
        {rows?.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label='custom pagination table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Usuario</StyledTableCell>
                  <StyledTableCell align='left'>Email</StyledTableCell>
                  <TableCell align='right'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row) => (
                  <TableRow key={row.name}>
                    <StyledTableCell component='th' scope='row'>
                      {row.usuario}
                    </StyledTableCell>
                    <StyledTableCell align='left'>{row.correo}</StyledTableCell>
                    <StyledTableCell align='right'>
                      <UserOptions actions={rowActions} user={row} />
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                    labelRowsPerPage='Usuarios por página'
                    colSpan={3}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    slotProps={{
                      select: {
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No se encontraron resultados</Typography>
        )}
        <ChangePasswordModal
          open={changePasswordModal.open}
          user={changePasswordModal.user}
          handleClose={() =>
            setChangePasswordModal({ open: false, user: null })
          }
        />
      </Box>
    </Box>
  );
};
