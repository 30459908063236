import { Box, Container, Typography } from '@mui/material';
import Page from 'components/Page';
import { EducationalCentersForm } from 'components/administration/educationalCenters/educationalCentersForm/EducationalCentersForm';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import Loader from 'react-spinners/BarLoader';
import { GET_CENTRO_EDUCATIVO } from 'utils/api';

const EditEducationalCenter = () => {
  const { state } = useLocation();
  const { title, elementKey, type } = state;

  const { data, isLoading } = useQuery(
    ['educationalCenter', elementKey],
    () => GET_CENTRO_EDUCATIVO(elementKey),
    {
      enabled: !!elementKey,
    }
  );

  return (
    <Page title={title}>
      <Container maxWidth='xl'>
        <Box
          sx={{
            pb: '32px',
            display: 'flex',
            justifyContent: 'space-between',
            rowGap: '1rem',
          }}>
          <Typography variant='h4'>{title}</Typography>
          {elementKey && (
            <Typography variant='overline'>{elementKey}</Typography>
          )}
        </Box>
        {isLoading ? (
          <Box
            width='100%'
            height='80vh'
            display='flex'
            alignItems='center'
            justifyContent='center'>
            <Loader color='#773286' />
          </Box>
        ) : (
          <EducationalCentersForm
            type={type}
            defaultValues={data?.data?.centro}
          />
        )}
      </Container>
    </Page>
  );
};

export default EditEducationalCenter;
