import React from 'react';
import {
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const CardTerms = ({ id, name, dateStart, dateEnd, defaultValues }) => {
  const navigate = useNavigate();
  return (
    <Card
      id={id}
      sx={{
        boxShadow: 'none',
        border: '1px solid rgb(230 229 231 /100%)',
        height: '114px',
      }}>
      <CardHeader
        title={name}
        onClick={() => {
          navigate(`/c/adminEscolar/periodos/editar/${id}`, {
            state: {
              title: 'Editar periodo',
              type: 'edit',
              defaultValues: defaultValues,
            },
          });
        }}
        action={
          <IconButton
            sx={{
              color: 'grey.600',
              justifySelf: 'end',
            }}>
            <EditIcon />
          </IconButton>
        }
      />
      <CardContent>
        {dateStart && (
          <Box display='flex' columnGap='0.5rem'>
            <Typography
              variant='body1'
              sx={{ fontWeight: 'bold', color: 'secondary.main' }}>
              Inicio:
            </Typography>
            <Typography variant='body1'>
              {dayjs(dateStart).locale('es').format('DD-MMM-YYYY')}
            </Typography>
          </Box>
        )}
        {dateEnd && (
          <Box display='flex' columnGap='0.5rem'>
            <Typography
              variant='body1'
              sx={{ fontWeight: 'bold', color: 'secondary.main' }}>
              Fin:
            </Typography>
            <Typography variant='body1'>
              {dayjs(dateEnd).locale('es').format('DD-MMM-YYYY')}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

CardTerms.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
  defaultValues: PropTypes.object.isRequired,
};

export default CardTerms;
