import * as R from 'ramda';

import { Edit, ExpandLess, ExpandMore } from '@mui/icons-material';
import React from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { toFloat, formatDate } from 'utils';
import { ResourcesContext } from 'state/ResourcesProvider';
import { ReassignModal } from './ReassignModal';
import { EditConceptModal } from './EditConceptModal';
import Confirma from 'utils/Confirma';
import { useDeleteMovement } from './useDeleteMovement';

const formatPaid = (status) => {
  const statusLC = status?.toLowerCase();
  return statusLC === 'paid' || statusLC === 'succeeded'
    ? 'Pagado'
    : statusLC === 'pending_payment'
    ? 'Pendiente'
    : status;
};

const PaymentTable = () => {
  const { state } = React.useContext(ResourcesContext);
  const { estudiante } = state;
  const [infoCuenta, setInfoCuenta] = React.useState([]);

  React.useEffect(() => {
    const periodos = estudiante.Periodos;
    const noVacios = R.sort(
      R.descend(R.compose(R.prop('periodo'), R.prop('Periodo')))
    )(
      R.filter(
        (periodo) =>
          !R.isEmpty(periodo.Movimientos) || !R.isEmpty(periodo.deudas)
      )(periodos)
    );
    setInfoCuenta(noVacios);
  }, [estudiante]);

  return (
    <Stack direction='column' alignItems='stretch' spacing={2}>
      <Typography variant='subtitle1' color='primary.darker'>
        Historial de Pagos
      </Typography>
      {infoCuenta?.length > 0 ? (
        infoCuenta.map((periodo, idx) => (
          <Operaciones periodo={periodo} key={idx} />
        ))
      ) : (
        <Card sx={{ mx: 2 }}>
          <CardContent>
            <Typography variant='h6'>
              No se ha registrado algún pago y/o recargo desde la inscripción
              del estudiante.
            </Typography>
          </CardContent>
        </Card>
      )}
    </Stack>
  );
};

const Operaciones = ({ periodo }) => {
  const [openPeriodo, setOpenPeriodo] = React.useState(false);
  return (
    <>
      <Button
        onClick={() => setOpenPeriodo((open) => !open)}
        endIcon={openPeriodo ? <ExpandLess /> : <ExpandMore />}
        sx={{ border: '1px dashed #a9a9a9', my: 1 }}
        fullWidth>
        Periodo {periodo.Periodo.periodo}
      </Button>
      <Collapse in={openPeriodo} timeout='auto' unmountOnExit>
        <Stack
          direction='column'
          alignItems='stretch'
          spacing={2}
          sx={{ px: 2 }}>
          <Typography variant='subtitle2' color='primary.darker'>
            Pagos
          </Typography>
          {R.isEmpty(periodo.Movimientos) ? (
            <Card>
              <CardContent>
                <Typography variant='h6'>No hay pagos registrados</Typography>
              </CardContent>
            </Card>
          ) : (
            periodo.Movimientos.sort(
              (a, b) =>
                new Date(a.fechaDeOperacion) - new Date(b.fechaDeOperacion)
            ).map((mov, idx) => (
              <MovimientoCard
                movimiento={mov}
                periodo={periodo.Periodo.periodo}
                key={idx}
              />
            ))
          )}
        </Stack>
      </Collapse>
    </>
  );
};

const MovimientoCard = ({ movimiento, periodo }) => {
  const status = formatPaid(movimiento.status);
  const [open, setOpen] = React.useState(false);
  const [openEditConcept, setOpenEditConcept] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const { handleDeleteMovement } = useDeleteMovement(movimiento.id, () =>
    setOpenDeleteModal(false)
  );

  return (
    <>
      <Card sx={{ mx: 2 }}>
        <CardContent>
          <Box display='flex' flexDirection='column' rowGap='16px'>
            <Box display='flex' flexDirection='column' rowGap='12px'>
              <Box display='flex' justifyContent='space-between'>
                <Box display='flex' alignItems='center' columnGap='16px'>
                  <Typography
                    variant='subtitle1'
                    sx={{ color: 'secondary.main' }}>
                    {movimiento.concepto}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setOpenEditConcept(!openEditConcept);
                    }}>
                    <Edit style={{ width: '20px', height: '20px' }} />
                  </IconButton>
                </Box>
                <Typography
                  variant='subtitle1'
                  sx={{
                    color: status === 'Pagado' ? 'success.main' : 'error.main',
                  }}>
                  {status}
                </Typography>
              </Box>

              <Box
                display='flex'
                columnGap='12px'
                flexDirection={['column', 'column', 'row']}>
                <Typography variant='subtitle2'>Fecha de creación:</Typography>
                <Typography variant='body2'>
                  {formatDate(movimiento.fechaDeOperacion)}
                </Typography>
              </Box>
              <Box
                display='flex'
                columnGap='8px'
                flexDirection={['column', 'column', 'row']}>
                <Typography variant='subtitle2'>
                  Fecha de actualización:
                </Typography>
                <Typography variant='body2'>
                  {formatDate(movimiento.updatedAt)}
                </Typography>
              </Box>
            </Box>
            <Box display='flex' flexDirection='column' rowGap='8px'>
              <Typography variant='subtitle1' sx={{ color: 'secondary.main' }}>
                Detalles
              </Typography>
              <Box
                display='flex'
                columnGap='32px'
                rowGap='8px'
                flexDirection={['column', 'column', 'row']}>
                <Box display='flex' columnGap='8px'>
                  <Typography variant='subtitle2'>Referencia:</Typography>
                  <Typography variant='body2'>
                    {movimiento.referenceId}
                  </Typography>
                </Box>
                <Box display='flex' columnGap='8px'>
                  <Typography variant='subtitle2'>
                    Tipo de referencia:
                  </Typography>
                  <Typography variant='body2'>
                    {movimiento.referenceType}
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' columnGap='8px'>
                <Typography variant='subtitle2'>Monto:</Typography>
                <Typography variant='body2'>
                  ${toFloat(movimiento.cantidad)} {movimiento.moneda}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            gap='24px'>
            <Button
              variant='text'
              color='info'
              onClick={() => {
                setOpenDeleteModal(true);
              }}>
              Eliminar
            </Button>
            <Button
              variant='contained'
              color='primary'
              size='small'
              onClick={() => {
                setOpen(!open);
              }}>
              Reasignar
            </Button>
          </Box>
        </CardContent>
      </Card>
      <ReassignModal
        open={open}
        handleClose={() => {
          setOpen(!open);
        }}
        movementId={movimiento.id}
      />
      <EditConceptModal
        open={openEditConcept}
        handleClose={() => {
          setOpenEditConcept(!openEditConcept);
        }}
        movement={movimiento}
        periodo={periodo}
      />
      <Confirma
        titulo='¿Deseas eliminar el movimiento?'
        open={openDeleteModal}
        setOpen={() => {
          setOpenDeleteModal(!openDeleteModal);
        }}
        action={handleDeleteMovement}
      />
    </>
  );
};

export default PaymentTable;
