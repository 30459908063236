const env = process.env.REACT_APP_ENV || 'development';
const config = require('../config/default.json')[env];

const hostname = process.env.REACT_APP_HOST || config.app.hostname;
const port = process.env.REACT_APP_PORT || config.app.port;

const backend = () => {
  const uri =
    env === 'development'
      ? `http://${hostname}:${port}`
      : `https://${hostname}`;
  return `${uri}/api`;
};

export default backend;
