import React from 'react';
import Typography from '@mui/material/Typography';

const DataDisplay = ({ titulo, valor }) => (
  <>
    <Typography
      id={titulo}
      variant='subtitle2'
      gutterBottom
      color='textSecondary'>
      {titulo}:
    </Typography>
    <Typography
      variant='body1'
      gutterBottom
      color='textPrimary'
      component={'span'}>
      {valor}
    </Typography>
  </>
);

export default DataDisplay;
