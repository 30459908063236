import { Box, Button, CardContent, Card, CardHeader } from '@mui/material';
import { CustomTextField } from 'components/CustomTextField';
import { useContext, useState } from 'react';
import { ResourcesContext } from 'state/ResourcesProvider';
import { handleAlerts } from 'utils';
import { PUT_DOCUMENTS_TRACK_ID } from 'utils/api';

export const AddDocumentsTrackId = () => {
  const { state, dispatch } = useContext(ResourcesContext);
  const { estudiante } = state;

  const { successMessage, errorMessage } = handleAlerts();

  const [trackId, setTrackId] = useState(estudiante?.documentosGraduadoTrackId);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await PUT_DOCUMENTS_TRACK_ID(estudiante.matricula, {
        documentosGraduadoTrackId: trackId,
      });
      dispatch({ type: 'SET_ESTUDIANTE', payload: response.data.estudiante });
      successMessage(
        'Se ha actualizado correctamente el número de guía de los documentos de graduado.'
      );
    } catch (error) {
      console.log(
        'Error',
        error.message || error.response?.data?.message || error
      );
      errorMessage('Error al actualizar la información');
    }
  };

  return (
    <Card>
      <CardHeader
        title='Número de guía de documentos de graduado'
        subheader='Ingresa el número de guía de los documentos de graduado que se enviarán al estudiante.'
      />
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Box
            display='flex'
            flexDirection='column'
            rowGap='16px'
            marginTop='16px'>
            <CustomTextField
              id='trackId'
              name='trackId'
              placeholder='Número de guía'
              fullWidth
              value={trackId}
              onChange={(e) => setTrackId(e.target.value)}
            />
            <Box display='flex' justifyContent='flex-end'>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                size='small'>
                Agregar
              </Button>
            </Box>
          </Box>
        </CardContent>
      </form>
    </Card>
  );
};
