import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow, styled } from '@mui/material';

const StyledTableCell = styled(TableCell)(() => ({
  border: 'none',
}));

export const TableHeaders = ({ headers }) => {
  return (
    <TableHead>
      <TableRow>
        {headers.map((header, index) => (
          <StyledTableCell key={header} align={index === 0 ? 'left' : 'center'}>
            {header}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

TableHeaders.propTypes = {
  headers: PropTypes.array.isRequired,
};
