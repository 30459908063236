import { useContext } from 'react';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import CustomSelect from 'components/CustomSelect';
import { CustomTextField } from 'components/CustomTextField';
import { TablePaginationActions } from 'components/TablePaginationActions';
import { useNavigate } from 'react-router-dom';
import { ResourcesContext } from 'state/ResourcesProvider';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px',
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.secondary.main,
    width: 'calc( 70% / 3)',
  },
  [`&.${tableCellClasses.head}:nth-child(1)`]: {
    width: '30%',
  },
}));

export const DashboardList = ({
  setPage,
  setRowsPerPage,
  searchParam,
  setSearchParam,
  setTypeOfFilter,
  typeOfFilter,
  rowsPerPage,
  page,
}) => {
  const navigate = useNavigate();

  const { state } = useContext(ResourcesContext);
  const students = state?.dashboard?.studentsData;
  const count = state?.dashboard?.debtorsData?.totalDeudores;

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box display='flex' flexDirection='column' gap='16px'>
      <Box
        display='flex'
        flexDirection={['column', 'column', 'column', 'row']}
        justifyContent='space-between'
        alignItems='center'
        padding='24px'
        gap='24px'
        bgcolor='info.darker'
        borderRadius='8px'>
        <Box display='flex' gap='24px' width='100%'>
          <Box width='calc(100% - 150px)'>
            <CustomTextField
              sx={{ width: '100%' }}
              placeholder='Buscar'
              value={searchParam}
              onChange={(e) => setSearchParam(e.target.value)}
            />
          </Box>
          <Box width='150px'>
            <CustomSelect
              fullWidth
              placeholder='Filtrar por'
              options={[
                { value: 'nombreCompleto', label: 'Nombre' },
                { value: 'matricula', label: 'Matricula' },
              ]}
              handleChange={(e) => setTypeOfFilter(e.target.value)}
              value={typeOfFilter}
            />
          </Box>
        </Box>
      </Box>
      <Box
        display='grid'
        height='440px'
        width='100%'
        paddingTop='24px'
        borderRadius='8px'
        boxShadow='0px 2px 4px 0px rgba(0, 0, 0, 0.25)'
        padding='16px'
        overflow='scroll'>
        <Table sx={{ height: '100%' }}>
          <TableHead>
            <TableRow key='headers'>
              <StyledTableCell>Estudiante</StyledTableCell>
              <StyledTableCell align='right'>Matrícula</StyledTableCell>
              <StyledTableCell align='right'>Deuda total</StyledTableCell>
              <StyledTableCell align='right'>Total Pagado</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students?.map((row) => (
              <TableRow
                style={{ cursor: 'pointer', verticalAlign: 'top' }}
                key={row.personaId}
                onClick={() => {
                  navigate(`/c/estudiante/${row.matricula}`);
                }}>
                <StyledTableCell component='th' scope='row'>
                  {row.nombreCompleto.toUpperCase()}
                </StyledTableCell>
                <StyledTableCell align='right'>{row.matricula}</StyledTableCell>
                <StyledTableCell align='right'>
                  {row.deudaTotal}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {row.totalPagado}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              labelRowsPerPage='Usuarios por página'
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableFooter>
        </Table>
      </Box>
    </Box>
  );
};
