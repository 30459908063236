import { Box, Container } from '@mui/material';
import Page from 'components/Page';
import { UsersTable } from 'components/users/UsersTable';
import { useDebounce } from 'hooks/useDebounce/useDebounce';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import Loader from 'react-spinners/BarLoader';
import { ResourcesContext } from 'state/ResourcesProvider';
import { GET_USERS } from 'utils/api';

export const Users = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [typeOfFilter, setTypeOfFilter] = useState('user');
  const [searchParam, setSearchParam] = useState('');

  const { state } = useContext(ResourcesContext);
  const {
    usersFilters: { selectedStatusFilter },
  } = state;

  const debouncedValue = useDebounce(searchParam, 500);

  const {
    data: users,
    isLoading,
    refetch,
  } = useQuery(
    [
      'users',
      rowsPerPage,
      page,
      debouncedValue,
      typeOfFilter,
      selectedStatusFilter,
    ],
    () =>
      GET_USERS({
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        filters: {
          ...(selectedStatusFilter && { [selectedStatusFilter]: true }),
          ...(typeOfFilter === 'user' &&
            debouncedValue && { usuario: debouncedValue }),
          ...(typeOfFilter === 'email' &&
            debouncedValue && { correo: debouncedValue }),
        },
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const rows = users?.data?.usuarios;
  const count = users?.data?.count;

  return (
    <Page title='Usuarios'>
      <Container maxWidth='xl'>
        <Box display='flex' flexDirection='column' gap='24px'>
          {isLoading ? (
            <Loader color='#FFD700' />
          ) : (
            <UsersTable
              rows={rows}
              page={page}
              rowsPerPage={rowsPerPage}
              searchParam={searchParam}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              setSearchParam={setSearchParam}
              setTypeOfFilter={setTypeOfFilter}
              typeOfFilter={typeOfFilter}
              count={count}
              refetch={refetch}
            />
          )}
        </Box>
      </Container>
    </Page>
  );
};
