import Grades from './Grades/Grades';
import Information from './Information';
import TabPanel from 'components/TabPanel';
import { Grid } from '@mui/material';

const AcademicDetails = ({ value, index, dir }) => {
  return (
    <TabPanel value={value} index={index} dir={dir}>
      <Grid container spacing={3} alignItems='stretch'>
        <Grid item xs={12} sm={8}>
          <Grades />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Information />
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default AcademicDetails;
