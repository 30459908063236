import * as Yup from 'yup';

export const termsOptions = (terms) =>
  terms
    .map((period) => ({
      value: period,
      label: period,
    }))
    .sort((a, b) => a.value - b.value);

export const NewPaymentValidationSchema = Yup.object().shape({
  referenceId: Yup.string().required('Este campo es requerido'),
  periodo: Yup.string().required('Este campo es requerido'),
});

export const ReassignValidationSchema = Yup.object().shape({
  matricula: Yup.string().required('Este campo es requerido'),
  periodo: Yup.string().required('Este campo es requerido'),
});
