import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Alert,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { sessionManagement, SubmissionError } from '../../../utils/auth';

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    usuario: Yup.string()
      .email('El correo electrónico debe tener un formato válido.')
      .required('El correo electrónico es requerido'),
    password: Yup.string().required('La contraseña es requerida'),
  });

  const formik = useFormik({
    initialValues: {
      usuario: '',
      password: '',
      remember: false,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, actions) => {
      try {
        await sessionManagement.login({
          ...values,
          rememberme: values.remember,
        });
        navigate('/c', { replace: true });
      } catch (error) {
        console.log(error);
        if (error instanceof SubmissionError) {
          actions.setStatus(error.submissionMessage);
        }
        console.log('Error', error.response?.data?.message || error);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    status,
  } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            name='usuario'
            autoComplete='username'
            type='email'
            label='Correo electrónico'
            {...getFieldProps('usuario')}
            error={Boolean(touched.usuario && errors.usuario)}
            helperText={touched.usuario && errors.usuario}
          />

          <TextField
            fullWidth
            name='password'
            autoComplete='current-password'
            type={showPassword ? 'text' : 'password'}
            label='Contraseña'
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handleShowPassword} edge='end'>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ my: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps('remember')}
                checked={values.remember}
              />
            }
            label='Recuérdame'
          />
          <Link component={RouterLink} variant='subtitle2' to='#'>
            ¿Olvidaste tu contraseña?
          </Link>
        </Stack>

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ my: 2 }}>
          {status && (
            <Alert severity='error' sx={{ width: 1 }}>
              {status}
            </Alert>
          )}
        </Stack>

        <Stack alignItems='center'>
          <LoadingButton
            size='large'
            type='submit'
            variant='contained'
            sx={{ width: '60%' }}
            loading={isSubmitting}>
            Iniciar sesión
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
