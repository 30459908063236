import Confirma from 'utils/Confirma';
import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Cancel, Edit, Save } from '@mui/icons-material';
import { handleAlerts } from 'utils';
import { PUT_STUDENT_CONTACT } from 'utils/api';
import { ResourcesContext } from 'state/ResourcesProvider';

const ContactEdit = () => {
  const { state, dispatch } = React.useContext(ResourcesContext);
  const { estudiante } = state;
  const data = {
    nombreTutor: estudiante.nombreTutor ?? '',
    correoTutor: estudiante.correoTutor ?? '',
    telefonoTutor: estudiante.telefonoTutor ?? '',
    email: estudiante.Persona?.Contacto?.email ?? '',
    telefono: estudiante.Persona?.Contacto?.telefono ?? '',
  };

  const [contacto, setContacto] = React.useState(data);
  const [editContacto, setEditContacto] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { successMessage, errorMessage } = handleAlerts();

  const resetContacto = () => setContacto(data);
  const handleEdit = () => setEditContacto(true);
  const handleCancel = () => setOpen(true);

  const handleSave = async () => {
    try {
      const response = await PUT_STUDENT_CONTACT(
        estudiante.matricula,
        contacto
      );
      dispatch({ type: 'SET_ESTUDIANTE', payload: response.data.estudiante });
      successMessage('Se ha actualizado el contacto del estudiante.');
      setEditContacto(false);
    } catch (error) {
      console.log(
        'Error',
        error.message || error.response?.data?.message || error
      );
      errorMessage('Error al actualizar la información');
    }
  };

  React.useEffect(() => {
    resetContacto();
  }, [estudiante]);

  return (
    <>
      <Confirma
        titulo='¿Deseas descartar los cambios?'
        open={open}
        setOpen={setOpen}
        action={() => {
          resetContacto();
          setOpen(false);
          setEditContacto(false);
        }}
      />
      <Card>
        <CardHeader
          title={<Typography variant='subtitle2'>Datos de Contacto</Typography>}
          action={
            <>
              {editContacto ? (
                <>
                  <Tooltip title='Guardar edición' placement='top'>
                    <IconButton size='small' onClick={handleSave}>
                      <Save />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Cancelar edición' placement='top'>
                    <IconButton size='small' onClick={handleCancel}>
                      <Cancel />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title='Editar' placement='top'>
                  <IconButton size='small' onClick={handleEdit}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
            </>
          }
        />
        <CardContent>
          <Grid item container spacing={2} alignItems='stretch'>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!editContacto}
                id='tutor'
                label='Nombre del Tutor'
                value={contacto.nombreTutor}
                onChange={(event) => {
                  event.persist();
                  setContacto((prev) => {
                    return {
                      ...prev,
                      nombreTutor: event.target.value,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!editContacto}
                id='correoTutor'
                label='Correo (Tutor)'
                value={contacto.correoTutor}
                onChange={(event) => {
                  event.persist();
                  setContacto((prev) => {
                    return {
                      ...prev,
                      correoTutor: event.target.value,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!editContacto}
                label='Teléfono (Tutor)'
                value={contacto.telefonoTutor}
                onChange={(event) => {
                  event.persist();
                  setContacto((prev) => {
                    return {
                      ...prev,
                      telefonoTutor: event.target.value,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!editContacto}
                id='email'
                label='Correo (Estudiante)'
                value={contacto.email}
                onChange={(event) => {
                  event.persist();
                  setContacto((prev) => {
                    return {
                      ...prev,
                      email: event.target.value,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!editContacto}
                id='telefono'
                label='Teléfono'
                value={contacto.telefono}
                onChange={(event) => {
                  event.persist();
                  setContacto((prev) => {
                    return {
                      ...prev,
                      telefono: event.target.value,
                    };
                  });
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ContactEdit;
