import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Page from 'components/Page';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { POST_ESTUDIANTE } from 'utils/api';
import { Paises } from 'utils/Paises';
import { handleAlerts } from 'utils';
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

const NewStudentBreadcrumbs = () => {
  const breadcrumbs = [
    <Link style={{ color: '#000', textDecoration: 'initial' }} key='1' to='/'>
      Inicio
    </Link>,
    <Link
      key='2'
      style={{ color: '#000', textDecoration: 'initial' }}
      to='/c/estudiantes/'
    >
      Estudiantes
    </Link>,
    <Typography key='3' color='text.primary'>
      Nuevo Estudiante
    </Typography>,
  ];

  return (
    <Stack spacing={2} mb={4} ml={4}>
      <Typography variant='h4' gutterBottom>
        Nuevo Estudiante
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize='small' />}
        aria-label='breadcrumb'
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

const NuevoEstudiante = () => {
  const { errorMessage } = handleAlerts();
  const navigate = useNavigate();

  const referencias = [
    {
      id: 'recomendacion',
      nombre: 'Recomendación',
    },
    { id: 'publicidad', nombre: 'Publicidad' },
    { id: 'educazion', nombre: 'Educazion' },
  ];

  const [data, setData] = React.useState({
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    password: '',
    confirmaPassword: '',
    telefono: '',
    email: '',
    nombreTutor: '',
    correoTutor: '',
    telefonoTutor: '',
    PaisId: 146,
    referencia: '',
  });

  const handleEdit = (key) => (event) => {
    const newData = { ...data };
    newData[key] = event.target.value;
    setData(newData);
  };

  const validatePassword = (data) => data.password === data.confirmaPassword;

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (validatePassword(data)) {
        const response = await POST_ESTUDIANTE(data);
        const estudiante = response.data.estudiante;
        // Redirecciona a la vista del estudiante
        navigate(`/c/estudiante/${estudiante.matricula}`);
      } else {
        throw 'Las contraseñas deben ser iguales';
      }
    } catch (error) {
      console.log(
        'Error',
        error.response?.data?.message || error.message || JSON.stringify(error)
      );
      errorMessage(
        error.response?.data?.message || error.message || JSON.stringify(error)
      );
    }
  };

  return (
    <Page title='Nuevo Estudiante | Control Escolar'>
      <NewStudentBreadcrumbs />
      <Container maxWidth='lg'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <form onSubmit={handleSubmit}>
                <CardHeader title='Crear un nuevo estudiante' />
                <CardContent>
                  <Grid item container spacing={3} alignItems='stretch'>
                    <Grid item xs={6}>
                      <TextField
                        label='Nombre'
                        fullWidth
                        value={data.nombre}
                        onChange={handleEdit('nombre')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label='Apellido Paterno'
                        fullWidth
                        value={data.apellidoPaterno}
                        onChange={handleEdit('apellidoPaterno')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label='Apellido Materno'
                        fullWidth
                        value={data.apellidoMaterno}
                        onChange={handleEdit('apellidoMaterno')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label='Password'
                        type='password'
                        fullWidth
                        value={data.password}
                        onChange={handleEdit('password')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label='Confirma Password'
                        type='password'
                        fullWidth
                        value={data.confirmaPassword}
                        onChange={handleEdit('confirmaPassword')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label='Teléfono'
                        fullWidth
                        value={data.telefono}
                        onChange={handleEdit('telefono')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label='Correo Electrónico'
                        fullWidth
                        value={data.email}
                        onChange={handleEdit('email')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label='Nombre del tutor'
                        fullWidth
                        value={data.nombreTutor}
                        onChange={handleEdit('nombreTutor')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label='Correo Electrónico Tutor'
                        fullWidth
                        value={data.correoTutor}
                        onChange={handleEdit('correoTutor')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label='Teléfono del Tutor'
                        fullWidth
                        value={data.telefonoTutor}
                        onChange={handleEdit('telefonoTutor')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id='select-paises-label'>País</InputLabel>
                        <Select
                          labelId='select-paises-label'
                          label='País'
                          id='select-paises'
                          value={data.PaisId}
                          onChange={handleEdit('PaisId')}
                        >
                          {Paises.map((pais) => (
                            <MenuItem key={pais.id} value={pais.id}>
                              {pais.nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id='select-referencia-label'>
                          Referencia
                        </InputLabel>
                        <Select
                          labelId='select-referencia-label'
                          label='Referencia'
                          id='select-referencia'
                          value={data.referencia}
                          onChange={handleEdit('referencia')}
                        >
                          {referencias.map((ref) => (
                            <MenuItem key={ref.id} value={ref.id}>
                              {ref.nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
                <DialogActions sx={{ mr: 1, mb: 1 }}>
                  <Button sx={{ mr: 1 }} component={Link} to='/c/estudiantes'>
                    Cancelar
                  </Button>
                  <Button sx={{ ml: 1 }} variant='contained' type='submit'>
                    Añadir Estudiante
                  </Button>
                </DialogActions>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default NuevoEstudiante;
