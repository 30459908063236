import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const DialogUpload = ({
  open,
  handleClose,
  handleUploadKey,
  message,
  document,
  fns,
}) => {
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const handleUploadFn = fns[handleUploadKey];

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  const changePage = (offset) =>
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md'>
      <DialogTitle>{message}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} alignItems='center' justify='center'>
          <Grid item xs={12}>
            <div
              style={{
                height: 400,
                overflowX: 'hidden',
                overflowY: 'scroll',
                border: '1px solid #A9A9A9',
                borderRadius: '4px',
              }}>
              <Box
                sx={{
                  '& .react-pdf__Page__textContent': {
                    border: '1px solid darkgrey',
                    boxShadow: '5px 5px 5px 1px #ccc',
                    borderRadius: '5px',
                  },
                  '& .react-pdf__Page__canvas': {
                    margin: '0 auto',
                  },
                  '.react-pdf__Page': {
                    marginTop: '10px',
                    marginBottom: '10px',
                  },
                }}>
                <Document file={document} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} width={700} />
                </Document>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton
                disabled={pageNumber <= 1}
                onClick={previousPage}
                sx={{ marginRight: 2, border: '1px solid #A9A9A9' }}
                size='small'>
                <NavigateBeforeIcon />
              </IconButton>
              <Typography
                variant='body2'
                sx={{ display: 'inline-block', marginTop: 1 }}>
                Página {pageNumber || (numPages ? 1 : '--')} de{' '}
                {numPages || '--'}
              </Typography>
              <IconButton
                disabled={pageNumber >= numPages}
                onClick={nextPage}
                size='small'
                sx={{ marginLeft: 2, border: '1px solid #A9A9A9' }}>
                <NavigateNextIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUploadFn} variant='contained' color='primary'>
          Subir documento
        </Button>
        <Button
          onClick={() => {
            handleClose();
            setNumPages(null);
            setPageNumber(1);
          }}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUpload;
