import { Box, Container, Typography } from '@mui/material';
import Page from 'components/Page';
import { SubjectsForm } from 'components/administration/subjects/subjectsForm/SubjectsForm';
import { TermsForm } from 'components/administration/terms/termsForm/TermsForm';
import { useLocation } from 'react-router-dom';

const EditForm = () => {
  const { state } = useLocation();
  const { title, elementKey, type, defaultValues } = state;

  return (
    <Page title={title}>
      <Container maxWidth='xl'>
        <Box
          sx={{
            pb: '32px',
            display: 'flex',
            justifyContent: 'space-between',
            rowGap: '1rem',
          }}>
          <Typography variant='h4'>{title}</Typography>
          {elementKey && (
            <Typography variant='overline'>{elementKey}</Typography>
          )}
        </Box>
        {title.includes('materia') ? (
          <SubjectsForm type={type} defaultValues={defaultValues} />
        ) : (
          <TermsForm type={type} defaultValues={defaultValues} />
        )}
      </Container>
    </Page>
  );
};

export default EditForm;
