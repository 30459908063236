import { integerValidation } from 'utils/validations';
import * as Yup from 'yup';

export const subcycleOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
];

export const TermsFormValidationSchema = Yup.object().shape({
  title: Yup.string().required('Este campo es requerido'),
  cycle: Yup.number()
    .test('integer', 'El número debe ser entero', (value) =>
      integerValidation(value)
    )
    .min(2020, 'El ciclo debe ser mayor a 2020')
    .required('Este campo es requerido'),
  subcycle: Yup.string().required('Este campo es requerido').nullable(),
  termStartDate: Yup.string().required('Este campo es requerido').nullable(),
  termEndDate: Yup.string().required('Este campo es requerido').nullable(),
  enrolmentStartDate: Yup.string()
    .required('Este campo es requerido')
    .nullable(),
  enrolmentEndDate: Yup.string().required('Este campo es requerido').nullable(),
  reEnrolmentStartDate: Yup.string()
    .required('Este campo es requerido')
    .nullable(),
  reEnrolmentEndDate: Yup.string()
    .required('Este campo es requerido')
    .nullable(),
  moodleUploadUsersDate: Yup.string()
    .required('Este campo es requerido')
    .nullable(),
  moodleUploadCoursesDate: Yup.string()
    .required('Este campo es requerido')
    .nullable(),
  holidaysStartDate: Yup.string()
    .required('Este campo es requerido')
    .nullable(),
  holidaysEndDate: Yup.string().required('Este campo es requerido').nullable(),
});
