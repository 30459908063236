import * as R from 'ramda';

const sortTerms = R.sortWith([
  R.descend(R.prop('ciclo')),
  R.ascend(R.prop('nombre')),
]);

export const groupByCycle = (results) => {
  const sortedResults = sortTerms(results);
  const groups = {};

  sortedResults.forEach((result) => {
    if (!groups[result.ciclo]) {
      groups[result.ciclo] = {
        becado: result.becado,
        title: result.ciclo,
        subjects: [],
      };
    }
    groups[result.ciclo].subjects.push(result);
  });

  const subjectsByGroup = Object.values(groups);

  return subjectsByGroup;
};

export const getLetra = (cal) => {
  switch (cal) {
    case 10:
      return 'A+';
    case 9:
      return 'A';
    case 8:
      return 'B';
    case 7:
      return 'C';
    case 6:
      return 'D';
    case 5:
      return 'E';
    default:
      return 'F';
  }
};

export const processed = (periodo) => (materias, Periodo) =>
  R.map((materia) => ({
    id: materia.Calificacion?.id,
    ciclo: periodo,
    nombre: materia.Materia?.titulo,
    calificacion: materia.Calificacion?.valor || null,
    grado: getLetra(materia.Calificacion?.valor),
    EstudianteMateriaId:
      materia.Calificacion?.EstudianteMateriaId || materia.id,
    clave: materia.Materia.clave,
    becado: Periodo.becado,
  }))(materias);

export const findSubject = (clave, estudiante) => {
  const materias = R.flatten(
    R.map((p) => {
      return p.Materias;
    })(estudiante.Periodos)
  );
  const materia = R.find((m) => m.Materia.clave === clave)(materias);
  return materia;
};
