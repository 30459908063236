import * as R from 'ramda';
import React from 'react';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
  Publish as PublishIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import download from 'downloadjs';
import DialogUpload from './DialogUpload';
import { handleAlerts, fileToBase64 } from 'utils';
import backend from 'utils/backend';

import {
  POST_UPLOAD_DOCUMENT,
  GET_DOWNLOAD_DOCUMENT,
  PUT_ESTUDIANTE,
} from 'utils/api';
import { ResourcesContext } from 'state/ResourcesProvider';

const Documents = () => {
  const urlBackend = backend();
  const { state, dispatch } = React.useContext(ResourcesContext);
  const { estudiante } = state;
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [handleUploadKey, setHandleUploadKey] = React.useState(null);
  const { successMessage, errorMessage } = handleAlerts();

  const tieneActa = estudiante.Persona?.documentoOficial;
  const tieneCurp = estudiante.Persona?.documentoCurp;
  const tieneCert = estudiante.documentoCertificadoSecundaria;
  const hiddenFileInput = React.useRef(null);

  const validateAndSet = R.cond([
    [
      (file) => !R.equals('application/pdf')(R.prop('type', file)),
      () => errorMessage('El tipo del archivo debe ser PDF'),
    ],
    [
      (file) => R.propSatisfies((s) => s > 10000000, 'size', file),
      () => errorMessage('El tamaño máximo para el archivo debe ser de 5 MB'),
    ],
    [
      R.T,
      async (file) => {
        const raw = await fileToBase64(file);
        R.ifElse(
          R.propSatisfies((x) => !R.isNil(x), 'error'),
          () => {
            errorMessage(
              'Ha ocurrido un error al procesar la imagen. Intente de nuevo o con otro archivo'
            );
            return;
          },
          () => {
            setSelectedFile(file);
            setOpenDialog(true);
          }
        )(raw);
      },
    ],
  ]);

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    validateAndSet(file);
  };

  const handleSelectFile = (message, key) => () => {
    setHandleUploadKey(key);
    setMessage(message);
    hiddenFileInput.current.click();
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setOpenDialog(false);
  };

  const onFileUpload = async (url, formData) => {
    try {
      const { data } = await POST_UPLOAD_DOCUMENT(url, formData);
      handleCloseDialog();
      successMessage(data.message);
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      handleCloseDialog();
      errorMessage(message);
      console.log('Error', message);
    }
  };

  const handleFileUploadActa = async () => {
    const url = `${urlBackend}/estudiantes/${estudiante.matricula}/uploadDocumento?tipo=acta_de_nacimiento`;
    const formData = new FormData();
    formData.append('documento', selectedFile);
    await onFileUpload(url, formData);
  };

  const handleFileUploadCURP = async () => {
    const url = `${urlBackend}/estudiantes/${estudiante.matricula}/uploadDocumento?tipo=curp`;
    const formData = new FormData();
    formData.append('documento', selectedFile);
    await onFileUpload(url, formData);
  };

  const handleFileUploadCertificado = async () => {
    const url = `${urlBackend}/estudiantes/${estudiante.matricula}/uploadDocumento?tipo=certificado_secundaria`;
    const formData = new FormData();
    formData.append('documento', selectedFile);
    await onFileUpload(url, formData);
  };

  const onDownload = async (url, documentName, message) => {
    try {
      const response = await GET_DOWNLOAD_DOCUMENT(url);
      download(response.data, documentName, 'application/pdf');
      successMessage(message);
    } catch (error) {
      errorMessage(error.message);
      console.log('Error', error.response.data?.message || error.message);
    }
  };

  const handleDownloadActa = async () => {
    const url = `${urlBackend}/estudiantes/${estudiante.matricula}/download?tipo=acta_de_nacimiento`;
    const documentName = `acta_${estudiante.matricula}.pdf`;
    const message = 'Se ha descargado correctamente el Acta de Nacimiento';
    await onDownload(url, documentName, message);
  };

  const handleDownloadCertificado = async () => {
    const url = `${urlBackend}/estudiantes/${estudiante.matricula}/download?tipo=certificado_secundaria`;
    const documentName = `certificado_${estudiante.matricula}.pdf`;
    const message =
      'Se ha descargado correctamente el Certificado de Secundaria';
    await onDownload(url, documentName, message);
  };

  const handleDownloadCurp = async () => {
    const url = `${urlBackend}/estudiantes/${estudiante.matricula}/download?tipo=curp`;
    const documentName = `curp_${estudiante.matricula}.pdf`;
    const message = 'Se ha descargado correctamente la CURP';
    await onDownload(url, documentName, message);
  };

  const handleFns = {
    acta: handleFileUploadActa,
    curp: handleFileUploadCURP,
    certificado: handleFileUploadCertificado,
  };

  const ValidatedDocument = ({ isValid, validMessage, invalidMessage }) => {
    return isValid ? (
      <Tooltip title={validMessage} placement='top'>
        <CheckCircleOutlineIcon color='success' />
      </Tooltip>
    ) : (
      <Tooltip title={invalidMessage} placement='top'>
        <ErrorOutlineIcon color='error' />
      </Tooltip>
    );
  };

  const [checked, setChecked] = React.useState(estudiante.documentado);

  const handleChangeDocumented = (event) => {
    try {
      const call = async () => {
        await PUT_ESTUDIANTE(
          { documentado: event.target.checked },
          estudiante.matricula
        );
      };
      call();
      dispatch({
        type: 'SET_ESTUDIANTE',
        payload: {
          ...estudiante,
          documentado: event.target.checked,
        },
      });
      setChecked(event.target.checked);
    } catch (error) {
      console.log(
        'Error',
        error.response.data?.message || error.message || error
      );
      dispatch({
        type: 'SET_ESTUDIANTE',
        payload: {
          ...estudiante,
          documentado: !event.target.checked,
        },
      });
      setChecked(!event.target.checked);
    }
  };

  return (
    <>
      <DialogUpload
        open={openDialog}
        handleClose={handleCloseDialog}
        handleUploadKey={handleUploadKey}
        message={message}
        document={selectedFile}
        fns={handleFns}
      />
      <input
        type='file'
        ref={hiddenFileInput}
        onChange={onFileChange}
        style={{ display: 'none' }}
      />
      <Grid container alignItems='center' justify='center'>
        <Grid item xs={3} sm={6}>
          <Typography variant='h6'>Documentos Personales</Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <Typography variant='subtitle1'>Estado</Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <Typography variant='subtitle1'>Subir</Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <Typography variant='subtitle1'>Consultar</Typography>
        </Grid>
        <Grid item xs={3} sm={6}>
          <Typography variant='subtitle2'>Acta de nacimiento</Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <ValidatedDocument
            isValid={tieneActa}
            validMessage='Acta de Nacimiento agregada correctamente'
            invalidMessage='Debes añadir el Acta'
          />
        </Grid>
        <Grid item xs={3} sm={2}>
          <Tooltip title='Subir documento' placement='top'>
            <IconButton
              onClick={handleSelectFile(
                '¿Deseas subir el acta de nacimiento?',
                'acta'
              )}
            >
              <PublishIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3} sm={2}>
          {tieneActa ? (
            <Tooltip title='Consultar Acta' placement='top'>
              <IconButton onClick={handleDownloadActa}>
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton disabled>
              <GetAppIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={3} sm={6}>
          <Typography variant='subtitle2'>Certificado de secundaria</Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <ValidatedDocument
            isValid={tieneCert}
            validMessage='Certificado de Estudios agregado correctamente'
            invalidMessage='Debes añadir el Certificado'
          />
        </Grid>
        <Grid item xs={3} sm={2}>
          <Tooltip title='Subir documento' placement='top'>
            <IconButton
              onClick={handleSelectFile(
                '¿Deseas subir el Certificado de Secundaria?',
                'certificado'
              )}
            >
              <PublishIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3} sm={2}>
          {tieneCert ? (
            <Tooltip title='Consultar Certificado' placement='top'>
              <IconButton onClick={handleDownloadCertificado}>
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton disabled>
              <GetAppIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={3} sm={6}>
          <Typography variant='subtitle2'>CURP</Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <ValidatedDocument
            isValid={tieneCurp}
            validMessage='CURP agregado correctamente'
            invalidMessage='Debes añadir el CURP'
          />
        </Grid>
        <Grid item xs={3} sm={2}>
          <Tooltip title='Subir documento' placement='top'>
            <IconButton
              onClick={handleSelectFile('¿Deseas subir la CURP?', 'curp')}
            >
              <PublishIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3} sm={2}>
          {tieneCurp ? (
            <Tooltip title='Consultar Curp' placement='top'>
              <IconButton onClick={handleDownloadCurp}>
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton disabled>
              <GetAppIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid item container spacing={3} alignItems='stretch'>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={checked} onChange={handleChangeDocumented} />
              }
              label='¿Ha entregado documentación en físico?'
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );

  // const global = React.useContext(sesionStore);
  // const {state} = global;
  // const {estudiante } = state.estudiante;

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [selectedFile, setSelectedFile] = React.useState(null);
  // const [openDialog, setOpenDialog] = React.useState(false);
  // const [message, setMessage] = React.useState('');
  // const [handleUploadKey, setHandleUploadKey] = React.useState(null);
  // const hiddenFileInput = React.useRef(null);
  // const {successMessage, errorMessage} = handleAlerts();
  // const isMountedRef = useIsMountedRef();

  // const handleSelectFile =

  // const handleClose = () => setAnchorEl(null);
  // const handleClick = (event) => setAnchorEl(event.currentTarget);

  // return (
  //   <>
  //     <Tooltip title='Abrir Constancia de Estudios'>
  //       <IconButton size='medium' onClick={handleClick}>
  //         <DescriptionIcon fontSize='inherit' />
  //       </IconButton>
  //     </Tooltip>
  //     <Menu
  //       id='menu'
  //       anchorEl={anchorEl}
  //       keepMounted
  //       open={!!anchorEl}
  //       onClose={handleClose}>
  //       <MenuItem>Abrir Acta de Nacimiento</MenuItem>
  //       <MenuItem>Abrir Constancia de Estudios</MenuItem>
  //       <MenuItem>Abrir CURP</MenuItem>
  //     </Menu>
  //   </>
  // );
};

export default Documents;
