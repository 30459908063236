import { useContext } from 'react';
import { ResourcesContext } from 'state/ResourcesProvider';
import { handleAlerts } from 'utils';
import { DELETE_MOVIMIENTO, GET_ESTUDIANTE } from 'utils/api';

export const useDeleteMovement = (movementId, closeDeleteModal) => {
  const {
    state: { estudiante },
    dispatch,
  } = useContext(ResourcesContext);
  const { successMessage, errorMessage } = handleAlerts();

  const handleDeleteMovement = async () => {
    try {
      await DELETE_MOVIMIENTO(movementId);

      const response = await GET_ESTUDIANTE(estudiante.matricula);
      dispatch({ type: 'SET_ESTUDIANTE', payload: response.data.estudiante });

      successMessage('Se eliminó correctamente el movimiento.');
    } catch (error) {
      console.log('Error', error);
      errorMessage(error);
    } finally {
      closeDeleteModal();
    }
  };

  return { handleDeleteMovement };
};
