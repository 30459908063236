import { handleAlerts } from 'utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { POST_PASSWORD_STUDENT } from 'utils/api';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { CustomTextField } from 'components/CustomTextField';

const FormSchema = yup.object().shape({
  pass: yup.string(),
  confirm: yup
    .string()
    .oneOf([yup.ref('pass'), null], 'Las contraseñas no coinciden'),
});

export const ChangePasswordModal = ({ open, user, handleClose }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const { successMessage, errorMessage } = handleAlerts();

  const handleSubmit = async (values) => {
    try {
      const response = await POST_PASSWORD_STUDENT(
        user.id,
        values.pass,
        values.confirm
      );
      successMessage(response.data.message);
      handleClose();
    } catch (error) {
      errorMessage(error.message || error.response?.data?.message || error);
    }
  };

  const formik = useFormik({
    initialValues: {
      pass: '',
      confirm: '',
    },
    validateOnChange: true,
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
      formik.resetForm();
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm'>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <DialogTitle color='primary.darker' style={{ paddingBottom: 0 }}>
          <Typography variant='h5'>Cambio de contraseña</Typography>
        </DialogTitle>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Box display='flex' flexDirection='column' gap='24px'>
            <DialogContentText>
              <Box display='flex' flexDirection='column' gap='8px'>
                <Typography variant='subtitle1' color='secondary'>
                  Por favor, ingresa la nueva contraseña.
                </Typography>
              </Box>
            </DialogContentText>
            <Box display='flex' flexDirection='column' gap='24px'>
              <CustomTextField
                id='password'
                label='Nueva contraseña'
                name='pass'
                type={showPassword ? 'text' : 'password'}
                onChange={formik.handleChange('pass')}
                value={formik.values?.pass}
                error={formik.errors.pass}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <CustomTextField
                id='confirm-password'
                label='Confirmar contraseña'
                name='confirm'
                type={showConfirm ? 'text' : 'password'}
                onChange={formik.handleChange('confirm')}
                value={formik.values?.confirm}
                error={formik.errors.confirm}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => {
                          setShowConfirm(!showConfirm);
                        }}>
                        {showConfirm ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </DialogContent>

        <DialogActions sx={{ padding: '16px 24px' }}>
          <Button
            onClick={() => {
              handleClose();
              formik.resetForm();
            }}>
            Cancelar
          </Button>
          <Button variant='contained' type='submit' color='primary'>
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
