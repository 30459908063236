import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { concepts } from 'utils';
import CustomSelect from 'components/CustomSelect';
import { useEditConcept } from './useEditConcept';

export const EditConceptModal = ({ open, handleClose, movement, periodo }) => {
  const { handleEditConcept } = useEditConcept(handleClose, movement, periodo);

  const formik = useFormik({
    initialValues: {
      concepto: '',
    },
    onSubmit: (values) => handleEditConcept(values, formik.resetForm),
  });

  const closeModal = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={closeModal} fullWidth>
      <DialogContent sx={{ padding: '32px 48px' }}>
        <DialogTitle color='primary.darker' sx={{ padding: 0 }}>
          Editar concepto
        </DialogTitle>
      </DialogContent>
      <form onSubmit={formik.handleSubmit}>
        <Box
          display='flex'
          columnGap='32px'
          rowGap={['24px', '24px', '32px']}
          flexDirection={['column', 'column', 'row']}
          padding='0 48px 32px 48px'>
          <Box width='100%'>
            <Typography
              variant='subtitle1'
              sx={{ color: 'secondary.main', marginBottom: '8px' }}>
              Concepto
            </Typography>
            <CustomSelect
              id='concepto'
              name='concepto'
              options={concepts.map((concepto) => ({
                value: concepto,
                label: concepto,
              }))}
              value={formik.values.concepto}
              handleChange={formik.handleChange}
              fullWidth
            />
          </Box>
        </Box>

        <DialogActions sx={{ padding: '0 48px 32px 48px' }}>
          <Button onClick={closeModal}>Cancelar</Button>
          <Button variant='contained' type='submit'>
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
