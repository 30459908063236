import Page from '../components/Page';
import { Stack, Container } from '@mui/material';
import { LoginForm } from '../components/authentication/login';
import { styled } from '@mui/material/styles';
import Logo from 'components/Logo';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  return (
    <RootStyle title='Iniciar sesión | Control Escolar'>
      <Container maxWidth='sm'>
        <ContentStyle>
          <Stack sx={{ mb: 5 }} alignItems='center'>
            <Logo />
          </Stack>
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
