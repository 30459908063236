import React from 'react';
import { Stack, Typography } from '@mui/material';

const TextIcon = ({ text, description, icon }) => {
  return (
    <Stack direction='row' alignItems='center'>
      {icon}
      <Typography variant='subtitle1' component='span'>
        &nbsp;{text}:&nbsp;
      </Typography>
      <Typography variant='body1' component='span' sx={{}}>
        {description}
      </Typography>
    </Stack>
  );
};

export default TextIcon;
