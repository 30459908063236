import { FormControl, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export const CustomTextField = ({
  label,
  placeholder,
  onChange,
  value,
  name,
  id,
  type = 'text',
  error,
  sx,
  ...props
}) => {
  return (
    <FormControl fullWidth>
      <Typography
        variant='body2'
        sx={{ fontWeight: 'bold', color: 'grey.600' }}>
        {label}
      </Typography>
      <TextField
        id={id}
        name={name}
        hiddenLabel
        placeholder={placeholder || label}
        size='small'
        onChange={onChange}
        value={value}
        fullWidth
        type={type}
        error={!!error}
        helperText={error}
        sx={{ bgcolor: 'white', borderRadius: '8px', ...sx }}
        {...props}
      />
    </FormControl>
  );
};

CustomTextField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'password']),
  error: PropTypes.string,
};
