import * as R from 'ramda';
import Confirma from 'utils/Confirma';
import React from 'react';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { handleAlerts, hasHistorial, hasPeriodo, handlePeriodos } from 'utils';
import { ResourcesContext } from 'state/ResourcesProvider';
import { DELETE_ASSOCIATE_STUDENT_SUBJECT } from 'utils/api';

const CurrentSubjects = () => {
  const { state, dispatch } = React.useContext(ResourcesContext);
  const { estudiante } = state;
  const heightMatch = useMediaQuery('(max-height:766px)');
  const [materiasActuales, setMateriasActuales] = React.useState([]);

  const [dialogs, setDialogs] = React.useState({
    openDelete: false,
    currentRow: null,
  });

  const { successMessage, errorMessage } = handleAlerts();
  const { periodoActual } = handlePeriodos();

  React.useEffect(() => {
    if (!R.isNil(estudiante)) {
      const hasHist = hasHistorial(estudiante);
      const currentPeriod = periodoActual();
      const hasLastPeriod = hasPeriodo(estudiante, currentPeriod);
      if (hasHist && hasLastPeriod) {
        const last = R.find(
          (p) => p.Periodo.periodo === currentPeriod,
          estudiante.Periodos
        );
        setMateriasActuales(last.Materias);
      }
    }
  }, [estudiante]);

  const handleDelete = async () => {
    const row = dialogs.currentRow;
    try {
      await DELETE_ASSOCIATE_STUDENT_SUBJECT(row.id);
      const newEst = { ...estudiante };
      const periodos = newEst.Periodos;
      const currentPeriod = periodoActual();
      const periodoN = R.find(
        (periodo) => periodo.Periodo.periodo === currentPeriod
      )(periodos);
      const Materias = R.filter((materia) => materia.id !== row.id)(
        periodoN.Materias
      );
      periodoN.Materias = Materias;
      dispatch({ type: 'SET_ESTUDIANTE', payload: newEst });
      successMessage('Materia eliminada correctamente');
    } catch (error) {
      console.log('Error', error.response.data?.message || error);
      errorMessage(error.message || error.response?.data?.message || error);
    } finally {
      setDialogs((dialogs) => ({ ...dialogs, openDelete: false }));
    }
  };

  return (
    <>
      <Confirma
        titulo='¿Deseas eliminar la materia?'
        open={dialogs.openDelete}
        setOpen={(open) =>
          setDialogs((dialogs) => ({ ...dialogs, openDelete: open }))
        }
        action={handleDelete}
      />
      <Grid container justify='flex-start' alignItems='center' spacing={1}>
        <Grid item xs={12}>
          <Typography variant='subtitle2'>Materias Cursando</Typography>
        </Grid>
        <Grid item xs={12}>
          {materiasActuales?.length > 0 ? (
            <TableContainer
              style={{
                height: heightMatch ? 260 : 460,
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              <Table size='small' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Clave</TableCell>
                    <TableCell>Titulo</TableCell>
                    <TableCell>Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {materiasActuales.map((materia, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{materia?.Materia.clave}</TableCell>
                      <TableCell>{materia?.Materia.titulo}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            setDialogs((dialogs) => ({
                              ...dialogs,
                              currentRow: materia,
                              openDelete: true,
                            }))
                          }
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant='body2'>
              No se encuentra cursando materias en el periodo actual
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CurrentSubjects;
