import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ResourcesContext } from 'state/ResourcesProvider';
import {
  GET_PERIODO_ACTUAL,
  GET_PERIODO_ANTERIOR,
  GET_PERIODOS,
} from 'utils/api';
import { AppBar, Box } from '@mui/material';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './sidebar/DashboardSidebar';

// ----------------------------------------------------------------------
const DRAWER_WIDTH = 270;
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const context = useContext(ResourcesContext);
  const { state, dispatch } = context;

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(async () => {
    if (state.periodoAnterior === null) {
      try {
        const response = await GET_PERIODO_ANTERIOR();
        dispatch({
          type: 'SET_PERIODO_ANTERIOR',
          payload: response.data.periodo,
        });
      } catch (error) {
        console.log('Error', error.response.data?.message || error);
      }
    }
    if (state.periodoActual === null) {
      try {
        const response = await GET_PERIODO_ACTUAL();
        dispatch({
          type: 'SET_PERIODO_ACTUAL',
          payload: response.data.periodo,
        });
      } catch (error) {
        console.log('Error', error.response.data?.message || error);
      }
    }
    if (state.periodos === null) {
      try {
        const response = await GET_PERIODOS();
        console.log('periodos', response.data);
        dispatch({ type: 'SET_PERIODOS', payload: response.data.periodos });
      } catch (error) {
        console.log('Error', error.response.data?.message || error);
      }
    }
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
          ml: { sm: `${DRAWER_WIDTH}px` },
        }}>
        <DashboardNavbar
          onOpenSidebar={handleDrawerToggle}
          drawerWidth={DRAWER_WIDTH}
        />
      </AppBar>
      <DashboardSidebar
        drawerWidth={DRAWER_WIDTH}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
          marginTop: { xs: `${APP_BAR_MOBILE}px`, lg: `${APP_BAR_DESKTOP}px` },
        }}>
        <Outlet />
      </Box>
    </Box>
  );
}
