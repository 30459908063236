import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { pieArcLabelClasses, PieChart } from '@mui/x-charts';
import { toInteger } from 'lodash';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ResourcesContext } from 'state/ResourcesProvider';
import { fromCamelCaseToTitle } from 'utils';
import { GET_STATUS_LIST } from 'utils/api';

export const StudentsStats = () => {
  const { state, dispatch } = useContext(ResourcesContext);
  const studentsStats = state?.dashboard?.studentsStats;

  const isTablet = useMediaQuery('(min-width: 768px)');

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await GET_STATUS_LIST();
        dispatch({ type: 'SET_STATUS_LIST', payload: response.data.statuses });
      } catch (error) {
        console.log('Error', error.response.data?.message || error);
      }
    };
    if (state.statusList === null) {
      fetch();
    }
  }, [state.statusList]);

  const navigate = useNavigate();
  const handleFilterStatus = (id) => {
    dispatch({
      type: 'SET_FILTERS',
      payload: {
        selectedStatusFilter: state.statusList.find(
          (item) => item.descripcion === id
        )?.id,
      },
    });
    navigate('/c/estudiantes');
  };

  const graphData = studentsStats
    ?.map((item) => ({
      id: item.estatus,
      value: item.count,
      label: `${item.estatus}: ${item.count}`,
      color: '#000000'.replace(/0/g, function () {
        return (~~(Math.random() * 16)).toString(16);
      }),
    }))
    .sort((a, b) => b.value - a.value);

  const total = graphData?.reduce(
    (acc, item) => acc + toInteger(item.value),
    0
  );

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='24px'
      borderRadius='8px'
      boxShadow='0px 2px 4px 0px rgba(0, 0, 0, 0.25)'
      height='auto'
      width='100%'
      padding='24px'>
      <Typography variant='h6' color='secondary.darker'>
        Estudiantes por estatus
      </Typography>
      <Box
        alignItems='center'
        justifyItems='center'
        display='flex'
        flexDirection={['column', 'row']}>
        <Box width={['100%', '65%']} height='300px'>
          <PieChart
            series={[
              {
                data: graphData,
                innerRadius: isTablet ? 100 : 50,
                arcLabel: (item) => `${Math.ceil((item.value / total) * 100)}%`,
                arcLabelMinAngle: 10,
              },
            ]}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: 'white',
                fontWeight: 'bold',
                fontSize: 12,
              },
            }}
          />
        </Box>
        <Box
          display='flex'
          flexDirection={['row', 'column']}
          flexWrap={['wrap', 'nowrap']}
          width={['100%', '35%']}
          alignItems='flex-start'>
          {graphData?.map((item) => (
            <Button
              style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              key={item.id}
              onClick={() => handleFilterStatus(item.id)}
              color='inherit'
              variant='text'>
              <div
                style={{
                  width: '15px',
                  height: '15px',
                  background: item.color,
                  borderRadius: '50%',
                }}></div>
              {fromCamelCaseToTitle(item.label)}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
