import { useContext } from 'react';
import { ResourcesContext } from 'state/ResourcesProvider';
import { handleAlerts } from 'utils';
import { GET_ESTUDIANTE, PUT_REASSIGN } from 'utils/api';

export const useOnSubmitReassign = (closeModal, movementId) => {
  const {
    state: { estudiante },
    dispatch,
  } = useContext(ResourcesContext);
  const { successMessage, errorMessage } = handleAlerts();

  const handleSubmitReassign = async (data, restForm) => {
    try {
      await PUT_REASSIGN(movementId, {
        matricula: data.matricula,
        periodo: data.periodo,
      });

      const response = await GET_ESTUDIANTE(estudiante.matricula);
      dispatch({ type: 'SET_ESTUDIANTE', payload: response.data.estudiante });

      successMessage('Se reasignó el movimiento correctamente');
    } catch (error) {
      console.log('Error', error.response?.data?.message || error);
      errorMessage(error.response?.data?.message || error.message);
    } finally {
      closeModal();
      restForm();
    }
  };
  return { handleSubmitReassign };
};
