import { useNavigate } from 'react-router-dom';
import { handleAlerts } from 'utils';
import { POST_PERIODO, PUT_PERIODO } from 'utils/api';

export const useOnSubmit = (type) => {
  const { successMessage, errorMessage } = handleAlerts();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    const term = {
      periodo: values.title,
      cicloSEP: values.cycle,
      subcicloSEP: values.subcycle,
      fechaTermino: values.termEndDate,
      fechaInicio: values.termStartDate,
      fechaInicioInscripciones: values.enrolmentStartDate,
      fechaFinInscripciones: values.enrolmentEndDate,
      fechaInicioReinscripciones: values.reEnrolmentStartDate,
      fechaFinReinscripciones: values.reEnrolmentEndDate,
      moodleUploadUsersDate: values.moodleUploadUsersDate,
      moodleUploadCoursesDate: values.moodleUploadCoursesDate,
      inicioVacaciones: values.holidaysStartDate,
      finVacaciones: values.holidaysEndDate,
    };

    if (type === 'create') {
      try {
        await POST_PERIODO(term);
        successMessage('Se ha creado el periodo correctamente');
      } catch (error) {
        console.log('Error', error.response?.data?.message || error);
        errorMessage(error.message || error.response?.data?.message || error);
      } finally {
        navigate(-1);
      }
    } else {
      try {
        await PUT_PERIODO(values.id, term);
        successMessage('Periodo actualizado correctamente');
      } catch (error) {
        console.log('Error', error.response.data?.message || error);
        errorMessage(error.message || error.response?.data?.message || error);
      } finally {
        navigate(-1);
      }
    }
  };

  return onSubmit;
};
