import * as R from 'ramda';
import PropTypes from 'prop-types';
import sidebarConfig from '../SidebarConfig';
import { Box, Drawer } from '@mui/material';
import { sessionManagement } from '../../../utils/auth';
import { DrawerBody } from './components/DrawerBody';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const DashboardSidebar = ({ drawerWidth, mobileOpen, handleDrawerToggle }) => {
  const { pathname } = useLocation();

  const logged = sessionManagement.loggedIn();
  if (!logged) return null;
  const user = sessionManagement.getProfile();
  const userRoles = R.map((rol) => rol.nombre)(user.roles);
  const hasPermission = (page, userRoles) => {
    const pageRoles = page.roles;
    return R.any(R.identity)(
      R.map((rol) => R.contains(rol, pageRoles))(userRoles)
    );
  };

  const pages = R.filter((page) => hasPermission(page, userRoles))(
    sidebarConfig
  );

  useEffect(() => {
    if (mobileOpen) {
      handleDrawerToggle();
    }
  }, [pathname]);

  return (
    <Box
      component='nav'
      sx={{
        width: { lg: drawerWidth },
        flexShrink: { lg: 0 },
      }}
      aria-label='sidebar'>
      {/* Mobile */}
      <Drawer
        variant='temporary'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: {
            xs: 'block',
            lg: 'none',
          },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}>
        <DrawerBody pages={pages} />
      </Drawer>
      {/* Desktop */}
      <Drawer
        variant='permanent'
        sx={{
          display: { xs: 'none', lg: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open>
        <DrawerBody pages={pages} />
      </Drawer>
    </Box>
  );
};

DashboardSidebar.propTypes = {
  drawerWidth: PropTypes.number.isRequired,
  mobileOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default DashboardSidebar;
