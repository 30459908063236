import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import Page from 'components/Page';
import { SubjectsList } from 'components/administration/subjects/SubjectsList';
import CustomSelect from 'components/CustomSelect';

export const Subjects = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = React.useState('');

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Page title='Materias'>
      <Container maxWidth='xl'>
        <Box
          sx={{
            pb: 5,
            display: 'flex',
            justifyContent: 'space-between',
            rowGap: '1rem',
          }}
          flexDirection={['column', 'row']}>
          <Typography variant='h4'>Materias</Typography>
          <Box
            display='flex'
            sx={{ columnGap: '24px' }}
            justifyContent={['space-between', 'flex-start']}
            alignItems='end'>
            <CustomSelect
              placeholder='Filtrar por tipo'
              value={filter}
              handleChange={handleChange}
              options={[
                { label: 'Todas', value: 'all' },
                { label: 'Obligatorias', value: 'mandatory' },
                { label: 'Optativas', value: 'optional' },
              ]}
            />
            <Button
              variant='contained'
              onClick={() =>
                navigate('/c/adminEscolar/materias/crear', {
                  state: {
                    title: 'Crear materia',
                    type: 'create',
                  },
                })
              }>
              Agregar materia
            </Button>
          </Box>
        </Box>
        <SubjectsList filter={filter} />
      </Container>
    </Page>
  );
};
