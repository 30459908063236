import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GET_ALL_MATERIAS } from 'utils/api';
import CardSubjects from './CardSubjects';
import { Box } from '@mui/material';
import { ClipLoader } from 'react-spinners';

export const SubjectsList = ({ filter }) => {
  const [subjects, setSubjects] = useState(null);
  const [filteredSubjects, setFilteredSubjects] = useState(null);

  useEffect(async () => {
    if (subjects == null) {
      const response = await GET_ALL_MATERIAS();
      setSubjects(response.data.materias);
      setFilteredSubjects(response.data.materias);
    }
  }, [subjects]);

  useEffect(() => {
    if (subjects !== null) {
      if (filter === 'mandatory') {
        setFilteredSubjects(
          subjects.filter((subject) => subject.optativa === false)
        );
      } else if (filter === 'optional') {
        setFilteredSubjects(
          subjects.filter((subject) => subject.optativa === true)
        );
      } else {
        setFilteredSubjects(subjects);
      }
    }
  }, [filter]);

  return (
    <Box display='flex' flexDirection='column'>
      {filteredSubjects === null ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '70vh',
          }}>
          <ClipLoader color='#61C4B2' />
        </Box>
      ) : (
        filteredSubjects.map((subject) => (
          <CardSubjects key={subject.id} subject={subject} />
        ))
      )}
    </Box>
  );
};

SubjectsList.propTypes = {
  filter: PropTypes.string.isRequired,
};
