import * as R from 'ramda';
import CakeIcon from '@mui/icons-material/Cake';
import ContactsIcon from '@mui/icons-material/Contacts';
import Documents from 'components/students/Information/Documents';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import FlagIcon from '@mui/icons-material/Flag';
import React from 'react';
import TextIcon from 'components/TextIcon';
import {
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
  Tooltip,
} from '@mui/material';
import { BUCKET, getAge, handleAlerts, fileToBase64 } from 'utils';
import { useTheme, alpha } from '@mui/material/styles';
import { Publish as PublishIcon } from '@mui/icons-material';
import { POST_UPLOAD_IMAGE } from 'utils/api';

const StudentHeader = ({ estudiante }) => {
  const theme = useTheme();
  const [isHover, setIsHover] = React.useState(false);
  const [urlLocalImg, setUrlLocalImg] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const { successMessage, errorMessage } = handleAlerts();

  const validate = R.cond([
    [
      (file) =>
        !R.contains(R.prop('type', file), [
          'image/jpeg',
          'image/jpg',
          'image/png',
          'image/bmp',
        ]),
      () => {
        errorMessage(
          'Los formatos soportados para este archivo son: JPEG, JPG, PNG, BMP'
        );
        return false;
      },
    ],
    [
      (file) => R.propSatisfies((s) => s > 2000000, 'size', file),
      (file) => {
        errorMessage(
          `El tamaño máximo para el archivo debe de ser de 2MB. La suya pesa ${
            R.prop('size', file) / 1000000
          }MB`
        );
        return false;
      },
    ],
    [
      R.T,
      async (file) => {
        const raw = await fileToBase64(file);
        const valid = R.ifElse(
          R.propSatisfies((x) => !R.isNil(x), 'error'),
          () => {
            errorMessage(
              'Ha ocurrido un error al procesar la imagen. Intente de nuevo o con otro archivo'
            );
            return false;
          },
          () => {
            return true;
          }
        )(raw);
        return valid;
      },
    ],
  ]);

  const handleUploadImage = async (event) => {
    setUploading(true);
    const file = event.target.files[0];
    if (!file) return;
    const valid = await validate(file);
    if (valid) {
      try {
        const formData = new FormData();
        formData.append('fotografia', file);
        const { data } = await POST_UPLOAD_IMAGE(
          formData,
          estudiante.matricula
        );
        successMessage(data.message);
        setUrlLocalImg(URL.createObjectURL(file));
      } catch (error) {
        console.log(
          'Error',
          error.message || error.response?.data?.message || error
        );
        errorMessage(error.message || error.response?.data?.message || error);
      }
    }
    setUploading(false);
  };

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems='center'
      spacing={1}>
      <input
        type='file'
        id='imginput'
        name='img'
        onChange={handleUploadImage}
        hidden
      />
      <CardMedia
        sx={{
          width: '18%',
          mx: 2,
          mb: 2,
          pb: '18%',
          height: 0,
          borderRadius: '50%',
          backgroundColor: isHover
            ? '#ffffff'
            : 'background: rgba(26, 33, 43, 0.5)',
          position: 'relative',
          border: `solid 4px ${theme.palette.grey[300]}`,
          [theme.breakpoints.down('md')]: {
            mt: 2,
            mb: theme.spacing(-2),
          },
          [theme.breakpoints.down('sm')]: {
            width: '60%',
            pb: '60%',
          },
        }}
        image={
          urlLocalImg ??
          `${BUCKET}/images/alumnos/${R.last(
            estudiante.Persona?.fotografiaOficial?.split('/') || [
              'standard.png',
            ]
          )}`
        }
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}>
        {isHover && (
          <Tooltip placement='top' title='Actualizar fotografía'>
            <IconButton
              sx={{
                textAlign: 'center',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                color: alpha(theme.palette.grey[0], 0.3),
                backgroundColor: alpha(theme.palette.grey[900], 0.3),
                '&:hover, &.Mui-focusVisible': {
                  backgroundColor: theme.palette.grey[900],
                  color: theme.palette.grey[0],
                },
              }}
              onClick={() => document.getElementById('imginput').click()}>
              <PublishIcon />
            </IconButton>
          </Tooltip>
        )}
        {uploading && (
          <CircularProgress
            sx={{
              textAlign: 'center',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              zIndex: 1,
              marginTop: '-20px',
              marginLeft: '-20px',
            }}
          />
        )}
      </CardMedia>
      <CardContent
        sx={{
          ml: 1,
          width: '80%',
          [theme.breakpoints.up('md')]: {
            borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
          },
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
        }}>
        <Grid container alignItems='stretch' spacing={2}>
          <Grid item xs={12} md={5}>
            <Stack direction='column' alignItems='stretch' spacing={1.5}>
              <Typography variant='h6'>
                {estudiante.Persona?.nombreCompleto}
              </Typography>
              <TextIcon
                icon={<CakeIcon />}
                text='Edad'
                description={getAge(estudiante.Persona?.fechaNacimiento)}
              />
              <TextIcon
                icon={<ContactsIcon />}
                text='Matrícula'
                description={estudiante.matricula}
              />
              <TextIcon
                icon={<FingerprintIcon />}
                text='CURP'
                description={estudiante.Persona?.CURP || 'No registrada'}
              />
              <TextIcon
                icon={<FlagIcon />}
                text='Nacionalidad'
                description={
                  estudiante.Pais?.nombre ?? 'Nacionalidad no capturada'
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Documents />
          </Grid>
        </Grid>
      </CardContent>
    </Stack>
  );
};

export default StudentHeader;
