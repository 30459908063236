import Confirma from 'utils/Confirma';
import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Cancel, Edit, Save } from '@mui/icons-material';
import { Paises } from 'utils/Paises';
import { handleAlerts } from 'utils';
import { PUT_STUDENT_ADDRESS } from 'utils/api';
import { ResourcesContext } from 'state/ResourcesProvider';

const AddressEdit = () => {
  const { state, dispatch } = React.useContext(ResourcesContext);
  const { estudiante } = state;
  const Direccion = estudiante.Persona?.Usuario?.Direccion || {};
  const data = {
    calle: Direccion.calle ?? '',
    numExt: Direccion.numExt ?? '',
    numInt: Direccion.numInt ?? '',
    ciudad: Direccion.ciudad ?? '',
    colonia: Direccion.colonia ?? '',
    codigoPostal: Direccion.codigoPostal ?? '',
    municipio: Direccion.municipio ?? '',
    estado: Direccion.estado ?? '',
    PaisId: Direccion.PaisId ?? '',
  };

  const [editDireccion, setEditDireccion] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [direccion, setDireccion] = React.useState(data);

  const { successMessage, errorMessage } = handleAlerts();

  const resetDireccion = () => setDireccion(data);

  const handleEdit = () => setEditDireccion(true);

  const handleCancel = () => setOpen(true);

  const handleSave = async () => {
    try {
      const response = await PUT_STUDENT_ADDRESS(
        estudiante.matricula,
        direccion
      );
      dispatch({ type: 'SET_ESTUDIANTE', payload: response.data.estudiante });
      successMessage('Se ha actualizado la dirección del estudiante.');
      setEditDireccion(false);
    } catch (error) {
      console.log(
        'Error',
        error.message || error.response?.data?.message || error
      );
      errorMessage('Error al actualizar la información');
    }
  };

  React.useEffect(() => {
    resetDireccion();
  }, [estudiante]);

  return (
    <>
      <Confirma
        titulo='¿Deseas descartar los cambios?'
        open={open}
        setOpen={setOpen}
        action={() => {
          resetDireccion();
          setOpen(false);
          setEditDireccion(false);
        }}
      />
      <Card>
        <CardHeader
          title={
            <Typography variant='subtitle2'>
              Dirección del Estudiante
            </Typography>
          }
          action={
            <>
              {editDireccion ? (
                <>
                  <Tooltip title='Guardar edición' placement='top'>
                    <IconButton size='small' onClick={handleSave}>
                      <Save />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Cancelar edición' placement='top'>
                    <IconButton size='small' onClick={handleCancel}>
                      <Cancel />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title='Editar' placement='top'>
                  <IconButton size='small' onClick={handleEdit}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
            </>
          }
        />
        <CardContent>
          <Grid item container spacing={2} alignItems='stretch'>
            <Grid item xs={12}>
              <Tooltip title={direccion.calle} placement='top'>
                <TextField
                  fullWidth
                  disabled={!editDireccion}
                  id='calle'
                  label='Calle'
                  value={direccion.calle}
                  onChange={(event) => {
                    event.persist();
                    setDireccion((prev) => {
                      return {
                        ...prev,
                        calle: event.target.value,
                      };
                    });
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!editDireccion}
                id='numInt'
                label='Número Interior'
                value={direccion.numInt}
                onChange={(event) => {
                  event.persist();
                  setDireccion((prev) => {
                    return {
                      ...prev,
                      numInt: event.target.value,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!editDireccion}
                id='numExt'
                label='Número Exterior'
                value={direccion.numExt}
                onChange={(event) => {
                  event.persist();
                  setDireccion((prev) => {
                    return {
                      ...prev,
                      numExt: event.target.value,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!editDireccion}
                id='ciudad'
                label='Ciudad'
                value={direccion.ciudad}
                onChange={(event) => {
                  event.persist();
                  setDireccion((prev) => {
                    return {
                      ...prev,
                      ciudad: event.target.value,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!editDireccion}
                id='colonia'
                label='Colonia'
                value={direccion.colonia}
                onChange={(event) => {
                  event.persist();
                  setDireccion((prev) => {
                    return {
                      ...prev,
                      colonia: event.target.value,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!editDireccion}
                id='codigoPostal'
                label='Código Postal'
                value={direccion.codigoPostal}
                onChange={(event) => {
                  event.persist();
                  setDireccion((prev) => {
                    return {
                      ...prev,
                      codigoPostal: event.target.value,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!editDireccion}
                id='municipio'
                label='Municipio'
                value={direccion.municipio}
                onChange={(event) => {
                  event.persist();
                  setDireccion((prev) => {
                    return {
                      ...prev,
                      municipio: event.target.value,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled={!editDireccion}
                id='estado'
                label='Estado'
                value={direccion.estado}
                onChange={(event) => {
                  event.persist();
                  setDireccion((prev) => {
                    return {
                      ...prev,
                      estado: event.target.value,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id='select-paises-label'>País</InputLabel>
                <Select
                  labelId='select-paises-label'
                  label='País'
                  id='select-paises'
                  value={direccion.PaisId}
                  onChange={(event) => {
                    event.persist();
                    setDireccion((prev) => {
                      return {
                        ...prev,
                        PaisId: event.target.value,
                      };
                    });
                  }}
                  disabled={!editDireccion}>
                  {Paises.map((pais) => (
                    <MenuItem key={pais.id} value={pais.id}>
                      {pais.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default AddressEdit;
