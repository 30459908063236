import { Delete, Edit } from '@mui/icons-material';
import {
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  styled,
} from '@mui/material';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const StyledTableRow = styled(TableRow)(() => ({
  border: 'none',
}));

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isFailed',
})(({ theme, isFailed }) => ({
  border: 'none',
  color: isFailed ? theme.palette.error.main : 'inherit',
  fontWeight: isFailed ? 'bold' : 'inherit',
  '&:first-child': {
    width: '60%',
  },
}));

export const SubjectRow = ({
  subject,
  isEdition,
  handleSelect,
  setDialogs,
}) => {
  const isFailed = subject.calificacion <= 6;

  return (
    <StyledTableRow>
      <StyledTableCell align='left' isFailed={isFailed}>
        {subject.nombre}
      </StyledTableCell>
      <StyledTableCell align='center' isFailed={isFailed}>
        {isEdition ? (
          <TextField
            select
            fullWidth
            id='calificacion'
            name='calificacion'
            size='small'
            disabled={R.isNil(subject.calificacion)}
            value={subject.calificacion || ''}
            onChange={(event) => handleSelect(event, subject)}>
            <MenuItem value=''>
              <em>N/A</em>
            </MenuItem>
            {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((option) => (
              <MenuItem key={'option' + option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          subject.calificacion
        )}
      </StyledTableCell>
      <StyledTableCell align='center' isFailed={isFailed}>
        {subject.grado}
      </StyledTableCell>
      <StyledTableCell align='center' isFailed={isFailed}>
        <Tooltip title='Reasignar materia'>
          <IconButton
            onClick={() => {
              setDialogs((dialogs) => ({
                ...dialogs,
                openEditTerm: true,
                currentRow: subject,
              }));
            }}>
            <Edit />
          </IconButton>
        </Tooltip>
      </StyledTableCell>
      {isEdition && (
        <StyledTableCell align='center' isFailed={isFailed}>
          <IconButton
            onClick={() => {
              setDialogs((dialogs) => ({
                ...dialogs,
                openDelete: true,
                currentRow: subject,
              }));
            }}>
            <Delete />
          </IconButton>
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
};

SubjectRow.propTypes = {
  subject: PropTypes.object.isRequired,
  isEdition: PropTypes.bool,
  handleSelect: PropTypes.func,
  setDialogs: PropTypes.object,
};
