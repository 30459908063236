import { Box } from '@mui/material';

export const ListItems = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        padding: '1rem',
        borderBottom: '1px solid rgb(230 229 231 / 70%)',
      }}>
      {children}
    </Box>
  );
};
