import { FormControl, Typography, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import esLocale from 'date-fns/locale/es';

export const CustomDateField = ({
  id,
  name,
  label,
  onChange,
  value,
  error,
}) => {
  return (
    <FormControl fullWidth>
      {label && (
        <Typography
          variant='body2'
          sx={{ fontWeight: 'bold', color: 'grey.600' }}>
          {label}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={DateAdapter} adapterLocale={esLocale}>
        <DesktopDatePicker
          inputFormat='dd/MM/yyyy'
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              hiddenLabel
              size='small'
              fullWidth
              id={id || name}
              name={name}
              error={!!error}
              helperText={error}
            />
          )}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

CustomDateField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
  error: PropTypes.string,
};
