import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import {
  Box,
  Typography,
  InputAdornment,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Divider,
} from '@mui/material';
import { CustomTextField } from 'components/CustomTextField';
import CustomSelect from 'components/CustomSelect';
import { FilterAlt } from '@mui/icons-material';
import { firstUpper, fromCamelCaseToTitle } from 'utils';
import { ResourcesContext } from 'state/ResourcesProvider';
import { Paises } from 'utils/Paises';

UserListToolbar.propTypes = {
  statusList: PropTypes.array,
};

export default function UserListToolbar({ statusList }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { state, dispatch } = React.useContext(ResourcesContext);
  const {
    centros,
    religiones,
    filters: {
      filterName,
      filterType,
      selectedStatusFilter,
      checkedFilters,
      filterBySex,
      filterByCenter,
      filterByReligion,
      filterByCountry,
    },
  } = state;
  const statusSelectOptions = React.useMemo(() => {
    if (!statusList) return [];
    const formattedList = statusList.map((item) => ({
      value: item.id,
      label: fromCamelCaseToTitle(item.descripcion),
    }));
    return formattedList;
  }, [statusList]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckFilter = (filter) => {
    dispatch({
      type: 'SET_FILTERS',
      payload: {
        checkedFilters: checkedFilters?.includes(filter)
          ? checkedFilters.filter((item) => item !== filter)
          : [...checkedFilters, filter],
      },
    });
  };

  const handleFilterByName = (event) => {
    dispatch({
      type: 'SET_FILTERS',
      payload: { filterName: event.target.value },
    });
  };

  const handleFilterByType = (event) =>
    dispatch({
      type: 'SET_FILTERS',
      payload: { filterType: event.target.value },
    });

  return (
    <Box>
      <Box bgcolor='primary.darker' borderRadius='8px' padding='16px 24px'>
        <Box
          display='flex'
          flexDirection={['column', 'column', 'row']}
          gap='24px'
          justifyContent='space-between'
          alignItems='center'
          width='100%'>
          <Box display='flex' alignItems='center' gap='16px'>
            <Box width='350px'>
              <CustomTextField
                value={filterName}
                onChange={handleFilterByName}
                placeholder='Buscar'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Box
                        component={Icon}
                        icon={searchFill}
                        sx={{ color: 'text.disabled' }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <CustomSelect
              size='small'
              value={filterType}
              placeholder='Filtrar búsqueda por'
              onChange={handleFilterByType}
              options={[
                { value: 'nombreCompleto', label: 'Nombre' },
                { value: 'matricula', label: 'Matrícula' },
                { value: 'email', label: 'Correo del estudiante' },
                { value: 'telefono', label: 'Teléfono del estudiante' },
                { value: 'correoTutor', label: 'Correo del tutor' },
                { value: 'telefonoTutor', label: 'Teléfono del tutor' },
                { value: 'CURP', label: 'CURP' },
              ]}
            />
          </Box>
          <Box display='flex' alignItems='center' gap='16px'>
            <CustomSelect
              size='small'
              value={selectedStatusFilter}
              placeholder='Estatus'
              onChange={(e) => {
                dispatch({
                  type: 'SET_FILTERS',
                  payload: { selectedStatusFilter: e.target.value },
                });
              }}
              options={statusSelectOptions}
            />
            <Tooltip title='Filtros avanzados' arrow>
              <IconButton onClick={handleClick}>
                <FilterAlt color='info' />
              </IconButton>
            </Tooltip>
            <Menu
              id='filters-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedFilters.includes('documentado')}
                      onChange={() => {
                        handleCheckFilter('documentado');
                      }}
                    />
                  }
                  label='Documentado'
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedFilters.includes('becado')}
                      onChange={() => {
                        handleCheckFilter('becado');
                      }}
                    />
                  }
                  label='Becado'
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedFilters.includes('reinscrito')}
                      onChange={() => {
                        handleCheckFilter('reinscrito');
                      }}
                    />
                  }
                  label='Reinscrito'
                />
              </MenuItem>
              <Divider />
              <MenuItem>
                <CustomSelect
                  fullWidth
                  id='filterBySex'
                  placeholder='Sexo'
                  options={[
                    { value: 'Masculino', label: 'Masculino' },
                    { value: 'Femenino', label: 'Femenino' },
                  ]}
                  value={filterBySex}
                  onChange={(e) => {
                    dispatch({
                      type: 'SET_FILTERS',
                      payload: { filterBySex: e.target.value },
                    });
                    handleClose();
                  }}
                />
              </MenuItem>
              <MenuItem>
                <CustomSelect
                  fullWidth
                  id='filterByEducationCenter'
                  placeholder='Centro educativo'
                  options={centros.map((item) => ({
                    value: item.id,
                    label: item.nombre,
                  }))}
                  value={filterByCenter}
                  onChange={(e) => {
                    dispatch({
                      type: 'SET_FILTERS',
                      payload: { filterByCenter: e.target.value },
                    });
                    handleClose();
                  }}
                />
              </MenuItem>
              <MenuItem>
                <CustomSelect
                  fullWidth
                  id='filterByReligion'
                  placeholder='Religión'
                  options={religiones.map((item) => ({
                    value: item.id,
                    label: item.nombre,
                  }))}
                  value={filterByReligion}
                  onChange={(e) => {
                    dispatch({
                      type: 'SET_FILTERS',
                      payload: { filterByReligion: e.target.value },
                    });
                    handleClose();
                  }}
                />
              </MenuItem>
              <MenuItem>
                <CustomSelect
                  id='filterByCountry'
                  fullWidth
                  placeholder='País'
                  options={Paises.map((item) => ({
                    value: item.id,
                    label: item.nombre,
                  }))}
                  value={filterByCountry}
                  onChange={(e) => {
                    dispatch({
                      type: 'SET_FILTERS',
                      payload: { filterByCountry: e.target.value },
                    });
                    handleClose();
                  }}
                />
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Box
        padding='16px 24px'
        display='flex'
        alignItems='center'
        gap='24px'
        flexWrap='wrap'>
        <Typography variant='subtitle1' color='text.secondary'>
          Filtros aplicados:
        </Typography>
        {selectedStatusFilter && (
          <Chip
            label={fromCamelCaseToTitle(
              statusSelectOptions.find(
                (item) => item.value === selectedStatusFilter
              )?.label
            )}
            color='primary'
            onDelete={() =>
              dispatch({
                type: 'SET_FILTERS',
                payload: { selectedStatusFilter: null },
              })
            }
          />
        )}
        {checkedFilters.map((item) => (
          <Chip
            key={item}
            label={firstUpper(item)}
            color='primary'
            onDelete={() => {
              dispatch({
                type: 'SET_FILTERS',
                payload: {
                  checkedFilters: checkedFilters.filter(
                    (filter) => filter !== item
                  ),
                },
              });
            }}
          />
        ))}
        {filterBySex && (
          <Chip
            label={firstUpper(filterBySex)}
            color='primary'
            onDelete={() =>
              dispatch({
                type: 'SET_FILTERS',
                payload: { filterBySex: '' },
              })
            }
          />
        )}
        {filterByCenter && (
          <Chip
            label={centros.find((item) => item.id === filterByCenter)?.nombre}
            color='primary'
            onDelete={() =>
              dispatch({
                type: 'SET_FILTERS',
                payload: { filterByCenter: '' },
              })
            }
          />
        )}
        {filterByReligion && (
          <Chip
            label={
              religiones.find((item) => item.id === filterByReligion)?.nombre
            }
            color='primary'
            onDelete={() =>
              dispatch({
                type: 'SET_FILTERS',
                payload: { filterByReligion: '' },
              })
            }
          />
        )}
        {filterByCountry && (
          <Chip
            label={Paises.find((item) => item.id === filterByCountry)?.nombre}
            color='primary'
            onDelete={() =>
              dispatch({
                type: 'SET_FILTERS',
                payload: { filterByCountry: '' },
              })
            }
          />
        )}
      </Box>
    </Box>
  );
}

export const FILTER_BASIC_OPTIONS = ['Nombre', 'Matricula', 'CURP'];

export const FILTER_BINARY_STATUS = [
  'Todos',
  'Documentado',
  'Reinscrito',
  'Estatus',
];
