import { Box, Button } from '@mui/material';
import CustomSelect from 'components/CustomSelect';
import { CustomTextField } from 'components/CustomTextField';
import { useFormik } from 'formik';
import {
  SubjectsFormValidationSchema,
  fourthMonthPeriodOptions,
  subjectTypeOptions,
} from './utils';
import { CustomCheckbox } from 'components/CustomCheckbox';
import { useNavigate } from 'react-router-dom';
import { useOnSubmit } from './useOnSubmit';
import PropTypes from 'prop-types';

export const SubjectsForm = ({ type, defaultValues }) => {
  const navigate = useNavigate();
  const onSubmit = useOnSubmit(type);

  const formik = useFormik({
    initialValues: {
      title: defaultValues?.titulo || '',
      description: defaultValues?.descripcion || null,
      englishTitle: defaultValues?.title || '',
      subjectKey: defaultValues?.clave || '',
      tutor: defaultValues?.tutor || '',
      credits: defaultValues?.creditos || '',
      fourthMonthPeriod: defaultValues?.cuatrimestre || '',
      subjectType: defaultValues?.tipo || '',
      isValid: defaultValues?.valida || false,
      isEnglish: defaultValues?.ingles || false,
      isOptional: defaultValues?.optativa || false,
      serialized: defaultValues?.seriada || '',
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
    validateOnChange: true,
    validationSchema: SubjectsFormValidationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        display='flex'
        flexDirection='column'
        rowGap={['24px', '24px', '32px']}>
        <Box
          display='flex'
          columnGap='32px'
          rowGap={['24px', '24px', '32px']}
          flexDirection={['column', 'column', 'row']}>
          <CustomTextField
            id='title'
            name='title'
            label='Título en español'
            placeholder='Nombre de la materia'
            onChange={formik.handleChange}
            value={formik.values.title}
            error={formik.errors.title}
          />
          <CustomTextField
            id='description'
            name='description'
            label='Descripción'
            onChange={formik.handleChange}
            value={formik.values.description}
          />
        </Box>
        <Box
          display='flex'
          columnGap='32px'
          rowGap={['24px', '24px', '32px']}
          flexDirection={['column', 'column', 'row']}>
          <CustomTextField
            id='serialized'
            name='serialized'
            label='Seriada'
            placeholder='Número de serie'
            onChange={formik.handleChange}
            value={formik.values.serialized}
            error={formik.errors.serialized}
          />
        </Box>
        <Box
          display='flex'
          columnGap='32px'
          rowGap={['24px', '24px', '32px']}
          flexDirection={['column', 'column', 'row']}>
          <CustomTextField
            id='englishTitle'
            name='englishTitle'
            label='Título en inglés'
            placeholder='Nombre de la materia en inglés'
            onChange={formik.handleChange}
            value={formik.values.englishTitle}
            error={formik.errors.englishTitle}
          />
          <CustomTextField
            id='subjectKey'
            name='subjectKey'
            label='Clave'
            placeholder='Clave de la materia'
            onChange={formik.handleChange}
            value={formik.values.subjectKey}
            error={formik.errors.subjectKey}
          />
        </Box>
        <Box
          display='flex'
          columnGap='32px'
          rowGap={['24px', '24px', '32px']}
          flexDirection={['column', 'column', 'row']}>
          <CustomTextField
            id='tutor'
            name='tutor'
            label='Tutor'
            placeholder='Nombre del tutor'
            onChange={formik.handleChange}
            value={formik.values.tutor}
            error={formik.errors.tutor}
          />
          <CustomTextField
            id='credits'
            name='credits'
            label='Créditos'
            placeholder='Número de créditos'
            onChange={formik.handleChange}
            value={formik.values.credits}
            type='number'
            error={formik.errors.credits}
          />
        </Box>
        <Box
          display='flex'
          columnGap='32px'
          rowGap={['24px', '24px', '32px']}
          flexDirection={['column', 'column', 'row']}>
          <Box
            display='flex'
            columnGap='32px'
            width={['100%', '100%', 'calc(50% - (32px / 2))']}>
            <CustomSelect
              id='fourthMonthPeriod'
              name='fourthMonthPeriod'
              label='Cuatrimestre'
              placeholder='Selecciona'
              options={fourthMonthPeriodOptions}
              handleChange={formik.handleChange}
              value={formik.values.fourthMonthPeriod}
              fullWidth={true}
              error={formik.errors.fourthMonthPeriod}
            />
            <CustomSelect
              id='subjectType'
              name='subjectType'
              label='Tipo'
              placeholder='Selecciona'
              options={subjectTypeOptions}
              handleChange={formik.handleChange}
              value={formik.values.subjectType}
              fullWidth={true}
            />
          </Box>
          <Box
            display='grid'
            gridTemplateColumns={['100%', '100%', 'repeat(2, 1fr)']}
            width={['100%', '100%', 'calc(50% - (32px / 2))']}>
            <CustomCheckbox
              label='Materia válida'
              id='isValid'
              name='isValid'
              checked={formik.values.isValid}
              onChange={formik.handleChange}
            />
            <CustomCheckbox
              label='Materia en inglés'
              id='isEnglish'
              name='isEnglish'
              checked={formik.values.isEnglish}
              onChange={formik.handleChange}
            />
            <CustomCheckbox
              label='Materia optativa'
              id='isOptional'
              name='isOptional'
              checked={formik.values.isOptional}
              onChange={formik.handleChange}
            />
          </Box>
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
          columnGap={['24px', '24px', '32px']}
          alignSelf='flex-end'
          width={['100%', '100%', '280px']}>
          <Button
            fullWidth
            variant='outlined'
            type='button'
            onClick={() => navigate(-1)}>
            Cancelar
          </Button>
          <Button fullWidth variant='contained' type='submit'>
            {type === 'edit' ? 'Guardar' : 'Crear'}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

SubjectsForm.propTypes = {
  type: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
};
