import * as R from 'ramda';
import backend from 'utils/backend';
import download from 'downloadjs';
import HashLoader from 'react-spinners/HashLoader';
import PacmanLoader from 'react-spinners/PacmanLoader';
import React from 'react';
import { Backdrop, Box, Button, Menu, Stack, Typography } from '@mui/material';
import { Print, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

import { ResourcesContext } from 'state/ResourcesProvider';
import { StateContext } from 'state/StateProvider';
import { handleAlerts } from 'utils';
import { GET_DOCUMENT } from 'utils/api';
import { PrintOption } from './components/PrintOption';

const Prints = () => {
  const { state } = React.useContext(ResourcesContext);
  const { state: stateContext, dispatch: stateDispatch } =
    React.useContext(StateContext);
  const { estudiante, matricula } = state;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [historialLang, setHistorialLang] = React.useState('es');
  const [credencialLang, setCredencialLang] = React.useState('es');
  const [constanciaLang, setConstanciaLang] = React.useState('es');
  const [moneda, setMoneda] = React.useState(false);
  const [isSigned, setIsSigned] = React.useState(false);
  const [isHistorialSigned, setIsHistorialSigned] = React.useState(false);
  const { successMessage, errorMessage } = handleAlerts();

  const server = backend();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadDocument = async (url, documentName, message) => {
    try {
      setMoneda(Math.random() < 0.5);
      stateDispatch({ type: 'START_LOADING' });
      handleClose();
      const response = await GET_DOCUMENT(url);
      download(response.data, documentName, 'application/pdf');
      successMessage(message);
    } catch (error) {
      console.log(
        'Error',
        error.response?.data?.message || error.response?.statusText || error
      );
      errorMessage(
        error.response?.data?.message || error.response?.statusText || error
      );
    } finally {
      stateDispatch({ type: 'STOP_LOADING' });
      handleClose();
    }
  };

  const getHistorialPDF = async () => {
    const url = `${server}/estudiantes/${matricula}/documentos?documento=historial&idioma=${historialLang}&firma=${
      historialLang === 'es' ? false : isHistorialSigned
    }`;
    const document = `historial_${matricula}_${historialLang}.pdf`;
    const message = 'Se ha descargado el historial correctamente';
    await downloadDocument(url, document, message);
  };

  const getCredencialPDF = async () => {
    const url = `${server}/estudiantes/${matricula}/documentos?documento=credencial&idioma=${credencialLang}`;
    const document = `credencial_${matricula}_${credencialLang}.pdf`;
    const message = 'Se ha descargada la credencial correctamente';
    await downloadDocument(url, document, message);
  };

  const getConstanciaPDF = async () => {
    const url = `${server}/estudiantes/${matricula}/documentos?documento=constancia&idioma=${constanciaLang}`;
    const document = `constancia_${matricula}_${constanciaLang}.pdf`;
    const message = 'Se ha descargado la constancia correctamente';
    await downloadDocument(url, document, message);
  };

  const getFinalizacionPDF = async () => {
    const url = `${server}/estudiantes/${matricula}/documentos?documento=finalizacion&idioma=es`;
    const document = `constancia_finalizacion_${matricula}_es.pdf`;
    const message = 'Se ha descargado la constancia correctamente';
    await downloadDocument(url, document, message);
  };

  const getConstBecaPDF = async () => {
    const url = `${server}/estudiantes/${matricula}/documentos?documento=beca&idioma=es`;
    const document = `constancia_finalizacion_${matricula}_es.pdf`;
    const message = 'Se ha descargado la constancia correctamente';
    await downloadDocument(url, document, message);
  };

  const getDiplomaPDF = async () => {
    const url = `${server}/estudiantes/${estudiante.matricula}/documentos?documento=diploma&idioma=en`;
    const document = `diploma_${estudiante.matricula}_en.pdf`;
    const message = 'El diploma se ha descargado correctamente';
    await downloadDocument(url, document, message);
  };

  const getTranscriptPDF = async () => {
    const url = `${server}/estudiantes/${estudiante.matricula}/documentos?documento=transcript&firma=${isSigned}`;
    const document = `transcript_${estudiante.matricula}_en.pdf`;
    const message = 'El transcript se ha descargado correctamente';
    await downloadDocument(url, document, message);
  };

  const getApostilladoPDF = async () => {
    const url = `${server}/estudiantes/${estudiante.matricula}/documentos?documento=apostillado`;
    const document = `apostillado_${estudiante.matricula}_en.pdf`;
    const message = 'El apostillado se ha descargado correctamente';
    await downloadDocument(url, document, message);
  };

  if (R.isNil(estudiante)) return null;

  return (
    <div>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={stateContext.loading}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}>
          <Stack direction='column' spacing={3} alignItems='center'>
            {moneda ? (
              <PacmanLoader loading={true} color='white' />
            ) : (
              <HashLoader loading={true} size={100} color='white' />
            )}

            <Typography variant='h6'>Generando Documento</Typography>
          </Stack>
        </Box>
      </Backdrop>
      <Button
        fullWidth
        style={{ borderRadius: 24, marginBottom: 16 }}
        variant='contained'
        color='primary'
        onClick={handleClick}
        startIcon={<Print />}
        endIcon={anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}>
        Impresiones
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {estudiante.EstatusId > 1 && (
          <Box
            display='flex'
            alignItems='center'
            columnGap='8px'
            paddingRight='16px'
            width='100%'>
            <PrintOption
              title='Historial'
              radioOptions={{
                value: historialLang,
                setValue: setHistorialLang,
                options: [
                  { value: 'es', label: 'ES' },
                  { value: 'en', label: 'EN' },
                ],
              }}
              onClick={getHistorialPDF}
              signedData={{
                isSigned: isHistorialSigned,
                handleChange: (event) => {
                  setIsHistorialSigned(event.target.value);
                },
                showSelect: historialLang === 'en',
              }}
            />
          </Box>
        )}
        <PrintOption
          title='Credencial'
          radioOptions={{
            value: credencialLang,
            setValue: setCredencialLang,
            options: [
              { value: 'es', label: 'ES' },
              { value: 'en', label: 'EN' },
            ],
          }}
          onClick={getCredencialPDF}
        />
        {estudiante.EstatusId >= 3 && (
          <PrintOption
            title='Constancia Digital'
            radioOptions={{
              value: constanciaLang,
              setValue: setConstanciaLang,
              options: [
                { value: 'es', label: 'ES' },
                { value: 'en', label: 'EN' },
              ],
            }}
            onClick={getConstanciaPDF}
          />
        )}
        {R.includes(estudiante.EstatusId, [5]) && (
          <PrintOption
            title='Constancia Finalización'
            onClick={getFinalizacionPDF}
          />
        )}
        <PrintOption title='Constancia Beca' onClick={getConstBecaPDF} />
        {R.includes(estudiante.EstatusId, [5, 6]) && (
          <PrintOption title='Diploma' onClick={getDiplomaPDF} />
        )}
        {estudiante.EstatusId === 5 && (
          <PrintOption
            title='Transcript'
            onClick={getTranscriptPDF}
            radioOptions={{
              value: isSigned,
              setValue: setIsSigned,
              options: [
                { value: true, label: 'Firmado' },
                { value: false, label: 'Sin firma' },
              ],
            }}
          />
        )}
        {estudiante.EstatusId === 5 && (
          <PrintOption title='Apostillado' onClick={getApostilladoPDF} />
        )}
      </Menu>
    </div>
  );
};

export default Prints;
