import AddressEdit from './AddressEdit';
import ContactEdit from './ContactEdit';
import InfoEdit from './InfoEdit';
import React from 'react';
import StudentInfoEdit from './StudentInfoEdit';
import TabPanel from 'components/TabPanel';
import { Grid } from '@mui/material';

const Details = ({ value, index, dir }) => {
  return (
    <>
      <TabPanel value={value} index={index} dir={dir}>
        <Grid container spacing={3} alignItems='stretch'>
          <Grid item xs={12} sm={6}>
            <StudentInfoEdit />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoEdit />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AddressEdit />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ContactEdit />
          </Grid>
        </Grid>
      </TabPanel>
    </>
  );
};

export default Details;
