import React, { useContext, useEffect, useState } from 'react';
import { ResourcesContext } from 'state/ResourcesProvider';
import { handlePeriodos } from 'utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';

export const AddSubject = ({ open, handleClose, handleSubmit, term }) => {
  const { state } = useContext(ResourcesContext);
  const { materias } = state;
  const { periodos: getPeriodos } = handlePeriodos();
  const terms = getPeriodos();

  const [data, setData] = useState({
    calificacion: 0,
    clave: '',
    periodo: '',
  });

  const handleEdit = (key) => (event) => {
    const newData = { ...data };
    newData[key] = event.target.value;
    setData(newData);
  };

  useEffect(() => {
    setData({
      ...data,
      periodo: term || terms?.[0],
    });
  }, [term]);

  useEffect(() => {
    if (!open)
      setData({
        ...data,
        calificacion: 0,
        clave: '',
      });
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md'>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <DialogTitle>Añadir nueva materia</DialogTitle>
        {data.periodo && (
          <DialogTitle sx={{ color: 'secondary.main' }}>
            Periodo {data.periodo}
          </DialogTitle>
        )}
      </Box>
      <DialogContent>
        <DialogContentText sx={{ mb: 4 }}>
          Selecciona la asignatura y la calificación que se le asociará al
          estudiante.
        </DialogContentText>
        <Stack spacing={2} direction='column' alignItems='stretch'>
          <FormControl variant='outlined'>
            <InputLabel id='materia-label'>Asignatura</InputLabel>
            <Select
              labelId='materia-label'
              id='materia'
              value={data.clave}
              label='Asignatura'
              onChange={handleEdit('clave')}
              fullWidth>
              {materias?.map((materia) => (
                <MenuItem key={materia.clave} value={materia.clave}>
                  {materia.titulo}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {term === null && (
            <FormControl variant='outlined'>
              <InputLabel id='periodo-label'>Periodo</InputLabel>
              <Select
                labelId='periodo-label'
                id='periodo'
                value={data.periodo}
                label='Periodo'
                onChange={handleEdit('periodo')}
                fullWidth>
                {terms?.map((term) => (
                  <MenuItem key={term} value={term}>
                    {term}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl variant='outlined'>
            <InputLabel id='calificacion-label'>Calificación</InputLabel>
            <Select
              labelId='calificacion-label'
              id='calificacion'
              value={data.calificacion}
              label='Calificación'
              onChange={handleEdit('calificacion')}
              fullWidth>
              <MenuItem value=''>
                <em>N/A</em>
              </MenuItem>
              {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((val, index) => (
                <MenuItem key={index} value={val}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          variant='contained'
          onClick={handleSubmit(data)}
          color='primary'>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
