import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export const DeleteSubject = ({ open, handleClose, handleDelete }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md'>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <DialogTitle>Eliminar materia</DialogTitle>
      </Box>
      <DialogContent>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          marginBottom='16px'>
          <img
            src='/static/illustrations/delete.svg'
            alt='Materia eliminar'></img>
        </Box>
        <DialogContentText>
          ¿Estás seguro que deseas eliminar esta materia?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button variant='contained' onClick={handleDelete} color='primary'>
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
