import { Box, Button, Typography } from '@mui/material';
import { CustomTextField } from 'components/CustomTextField';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useOnSubmitCenter } from './useOnSubmitCenter';
import { EducationalCentersFormValidationSchema } from './utils';

export const EducationalCentersForm = ({ type, defaultValues }) => {
  const navigate = useNavigate();
  const onSubmit = useOnSubmitCenter(type, defaultValues?.id);

  const formik = useFormik({
    initialValues: {
      centerName: defaultValues?.nombreCentroEducativo || '',
      centerKey: defaultValues?.clave || '',
      managerName: defaultValues?.Direccion?.Usuario?.nombre || '',
      managerEmail: defaultValues?.Direccion?.Usuario?.correo || '',
      postalCode: defaultValues?.Direccion?.codigoPostal || '',
      street: defaultValues?.Direccion?.calle || '',
      intNumber: defaultValues?.Direccion?.numInt || '',
      extNumber: defaultValues?.Direccion?.numExt || '',
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
    validateOnChange: true,
    validationSchema: EducationalCentersFormValidationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        display='flex'
        flexDirection='column'
        rowGap={['24px', '24px', '32px']}>
        <Box
          display='flex'
          columnGap='32px'
          rowGap={['24px', '24px', '32px']}
          flexDirection={['column', 'column', 'row']}>
          <CustomTextField
            id='centerName'
            name='centerName'
            label='Nombre del centro educativo'
            placeholder='Nombre del centro educativo'
            onChange={formik.handleChange}
            value={formik.values.centerName}
            error={formik.errors.centerName}
          />
          <CustomTextField
            id='centerKey'
            name='centerKey'
            label='Clave del centro educativo'
            placeholder='Clave'
            onChange={formik.handleChange}
            value={formik.values.centerKey}
            error={formik.errors.centerKey}
          />
        </Box>
        <Box display='grid' gap='16px'>
          <Typography variant='h6' color='secondary'>
            Encargado
          </Typography>
          <Box
            display='flex'
            columnGap='32px'
            rowGap={['24px', '24px', '32px']}
            flexDirection={['column', 'column', 'row']}>
            <CustomTextField
              id='managerName'
              name='managerName'
              label='Nombre del encargado'
              placeholder='Nombre del encargado'
              onChange={formik.handleChange}
              value={formik.values.managerName}
              error={formik.errors.managerName}
            />
            <CustomTextField
              id='managerEmail'
              name='managerEmail'
              label='Correo del encargado'
              placeholder='Correo del encargado'
              onChange={formik.handleChange}
              value={formik.values.managerEmail}
              error={formik.errors.managerEmail}
            />
          </Box>
        </Box>
        <Box display='grid' gap='16px'>
          <Typography variant='h6' color='secondary'>
            Ubicación
          </Typography>
          <Box
            display='flex'
            columnGap='32px'
            rowGap={['24px', '24px', '32px']}
            flexDirection={['column', 'column', 'row']}>
            <CustomTextField
              id='street'
              name='street'
              label='Calle'
              placeholder='Calle'
              onChange={formik.handleChange}
              value={formik.values.street}
              error={formik.errors.street}
            />
            <CustomTextField
              id='postalCode'
              name='postalCode'
              type='number'
              label='Código postal'
              placeholder='Código postal'
              onChange={formik.handleChange}
              value={formik.values.postalCode}
              error={formik.errors.postalCode}
            />
          </Box>
          <Box
            display='flex'
            columnGap='32px'
            rowGap={['24px', '24px', '32px']}
            flexDirection={['column', 'column', 'row']}>
            <CustomTextField
              id='extNumber'
              name='extNumber'
              type='number'
              label='Número exterior'
              placeholder='Número exterior'
              onChange={formik.handleChange}
              value={formik.values.extNumber}
              error={formik.errors.extNumber}
            />
            <CustomTextField
              id='intNumber'
              name='intNumber'
              type='number'
              label='Número interior'
              placeholder='Número interior'
              onChange={formik.handleChange}
              value={formik.values.intNumber}
              error={formik.errors.intNumber}
            />
          </Box>
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
          columnGap={['24px', '24px', '32px']}
          alignSelf='flex-end'
          width={['100%', '100%', '280px']}>
          <Button
            fullWidth
            variant='outlined'
            type='button'
            onClick={() => navigate(-1)}>
            Cancelar
          </Button>
          <Button fullWidth variant='contained' type='submit'>
            {type === 'edit' ? 'Guardar' : 'Crear'}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

EducationalCentersForm.propTypes = {
  type: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
};
