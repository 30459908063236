import {
  DELETE_ASSOCIATE_STUDENT_SUBJECT,
  POST_ASSOCIATE_STUDENT_SUBJECT,
  PUT_SAVE_GRADE,
} from 'utils/api';
import * as R from 'ramda';
import { useContext } from 'react';
import { ResourcesContext } from 'state/ResourcesProvider';
import { handleAlerts } from 'utils';
import { findSubject } from '../utils';

export const useGradeActions = ({ setDialogs }) => {
  const { state, dispatch } = useContext(ResourcesContext);
  const { successMessage, errorMessage, warningMessage } = handleAlerts();

  const estudiante = state.estudiante;

  const handleDelete = async (row) => {
    try {
      await DELETE_ASSOCIATE_STUDENT_SUBJECT(row.EstudianteMateriaId);
      const newEst = { ...estudiante };
      const periodos = newEst.Periodos;
      const periodoN = R.find(
        (periodo) => periodo.Periodo.periodo === row.ciclo
      )(periodos);
      const Materias = R.filter(
        (materia) => materia.id !== row.EstudianteMateriaId
      )(periodoN.Materias);
      periodoN.Materias = Materias;
      dispatch({ type: 'SET_ESTUDIANTE', payload: newEst });
      successMessage('Materia eliminada correctamente');
    } catch (error) {
      console.log(
        'Error',
        error.message || error.response?.data?.message || error
      );
      errorMessage(error.message || error.response?.data?.message || error);
    } finally {
      setDialogs((dialogs) => ({ ...dialogs, openDelete: false }));
    }
  };

  const handleSubmit = (data) => async (event) => {
    event.preventDefault();
    const materia = !!findSubject(data.clave, estudiante);
    try {
      const response = materia
        ? { exist: true }
        : await POST_ASSOCIATE_STUDENT_SUBJECT(
            data.clave,
            data.calificacion,
            state.estudiante.matricula,
            data.periodo
          );
      if (response.exist) {
        warningMessage('La materia ya existe en el historial del estudiante');
        return;
      }
      if (
        response.data.message ===
        'La materia ya había sido agregada en el periodo seleccionado'
      ) {
        warningMessage(response.data.message);
        return;
      }
      const newEst = { ...estudiante };
      const periodos = newEst.Periodos;
      const periodoN = R.find(
        (periodo) => periodo.Periodo.periodo === data.periodo
      )(periodos);
      if (R.isNil(periodoN)) {
        periodos.push({
          Materias: [
            {
              Materia: response.data.materia,
              id: response.data.estudianteMateria.id,
              Calificacion: response.data.calificacion,
            },
          ],
          Periodo: { periodo: data.periodo },
          Movimientos: [],
          deudas: [],
        });
      } else {
        const Materias = R.propOr([], 'Materias')(periodoN);
        Materias.push({
          Materia: response.data.materia,
          id: response.data.estudianteMateria.id,
          Calificacion: response.data.calificacion,
        });
        periodoN.Materias = Materias;
      }
      dispatch({ type: 'SET_ESTUDIANTE', payload: newEst });
      successMessage(response.data.message);
    } catch (error) {
      console.log('Error', error.response?.data?.message || error);
      errorMessage(error.message || error.response?.data?.message || error);
    } finally {
      setDialogs((dialogs) => ({
        ...dialogs,
        openCreate: false,
        openCreateWithoutTerm: false,
      }));
    }
  };

  const handleSelect = async (event, row) => {
    try {
      if (!event.target.value) {
        warningMessage('Elige un valor numérico');
        return;
      }
      const response = await PUT_SAVE_GRADE(row.id, event.target.value);
      const calificacion = response.data.calificacion;
      const newEst = { ...estudiante };
      const periodos = newEst.Periodos;
      const periodoIdx = R.findIndex(
        R.pathEq(['Periodo', 'periodo'], row.ciclo)
      )(periodos);
      const periodo = periodos[periodoIdx];
      const Materias = periodo.Materias;
      const materiaIdx = R.findIndex(R.pathEq(['Calificacion', 'id'], row.id))(
        Materias
      );
      const materia = Materias[materiaIdx];
      materia.Calificacion = calificacion;
      dispatch({ type: 'SET_ESTUDIANTE', payload: newEst });
      successMessage('Se ha actualizado la calificación correctamente');
    } catch (error) {
      console.log('Error', error.response.data?.message || error.message);
      errorMessage(error.response?.data?.message || error.message);
    }
  };

  return { handleDelete, handleSubmit, handleSelect };
};
