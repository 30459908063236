import React, { useContext, useEffect, useState } from 'react';
import { ResourcesContext } from 'state/ResourcesProvider';
import { handleAlerts, handlePeriodos } from 'utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { GET_ESTUDIANTE, PUT_REASSIGN_SUBJECT } from 'utils/api';

export const EditTerm = ({ open, handleClose, currentRow }) => {
  const { periodos: getPeriodos } = handlePeriodos();
  const terms = getPeriodos();
  const { state, dispatch } = useContext(ResourcesContext);
  const { successMessage, errorMessage } = handleAlerts();

  const [term, setTerm] = useState(terms?.[0]);

  const handleEdit = (event) => {
    setTerm(event.target.value);
  };

  useEffect(() => {
    if (!open) setTerm('');
  }, [open]);

  const handleSubmit = async () => {
    try {
      await PUT_REASSIGN_SUBJECT(currentRow.EstudianteMateriaId, {
        periodo: term,
      });
      const response = await GET_ESTUDIANTE(state.estudiante.matricula);
      dispatch({ type: 'SET_ESTUDIANTE', payload: response.data.estudiante });
      successMessage('Materia reasignada correctamente');
    } catch (error) {
      console.log(
        'Error',
        error.message || error.response?.data?.message || error
      );
      errorMessage(error.message || error.response?.data?.message || error);
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md'>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <DialogTitle>Reasignar materia</DialogTitle>
      </Box>
      <DialogContent>
        <DialogContentText sx={{ mb: 4 }}>
          Selecciona el periodo al que se reasignará la materia
        </DialogContentText>
        <Stack spacing={2} direction='column' alignItems='stretch'>
          <FormControl variant='outlined'>
            <InputLabel id='periodo-label'>Periodo</InputLabel>
            <Select
              labelId='periodo-label'
              id='periodo'
              value={term}
              label='Periodo'
              onChange={handleEdit}
              fullWidth>
              {terms?.map((term) => (
                <MenuItem key={term} value={term}>
                  {term}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button variant='contained' onClick={handleSubmit} color='primary'>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
