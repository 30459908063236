import PaymentTable from './PaymentTable';
import Record from './Record';
import TabPanel from 'components/TabPanel';
import { Grid } from '@mui/material';

const AccountStatus = ({ value, index, dir }) => {
  return (
    <TabPanel value={value} index={index} dir={dir}>
      <Grid container spacing={3} alignItems='stretch'>
        <Grid item xs={12} sm={8}>
          <PaymentTable />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Record />
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default AccountStatus;
