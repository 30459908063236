import React from 'react';
import Router from 'routes';
import ThemeConfig from 'theme';
import GlobalStyles from 'theme/globalStyles';
import ScrollToTop from 'components/ScrollToTop';
import { BaseOptionChartStyle } from 'components/charts/BaseOptionChart';
import { StateProvider } from 'state/StateProvider';
import { ResourcesProvider } from 'state/ResourcesProvider';
import Alert from 'components/Alert';
import { QueryClient, QueryClientProvider } from 'react-query';

export default function App() {
  const queryClient = new QueryClient();
  return (
    <StateProvider>
      <ResourcesProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeConfig>
            <ScrollToTop />
            <GlobalStyles />
            <BaseOptionChartStyle />
            <Router />
            <Alert />
          </ThemeConfig>
        </QueryClientProvider>
      </ResourcesProvider>
    </StateProvider>
  );
}
