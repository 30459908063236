import decode from 'jwt-decode';
import axios from 'axios';
import { setObject, getObject, deleteObject } from './storage';
import backend from './backend';

class SubmissionError extends Error {
  constructor(submissionMessage) {
    super(submissionMessage);
    this.submissionMessage = submissionMessage;
  }
}

class AuthService {
  constructor(domain) {
    this.domain = domain || backend();
    this.login = this.login.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  async login({ usuario, password, rememberme }) {
    const url = `${this.domain}/auth/signin?remember=${rememberme}`;
    return await axios
      .post(url, {
        headers: { crossdomain: true },
        data: {
          correo: usuario,
          password: password,
        },
      })
      .then((response) => {
        this.setToken(response.data.token);
        setObject('roles', JSON.stringify(response.data.roles));
        return Promise.resolve(response);
      })
      .catch((err) => {
        const newError = new SubmissionError(err.response.data.message);
        newError.response = err.response;
        throw newError;
      });
  }

  loggedIn() {
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      const expiresIn = new Date(decoded.expiresIn).getTime();
      return expiresIn < Date.now();
    } catch (err) {
      return false;
    }
  }
  setToken(idToken) {
    setObject('id_token', idToken);
  }

  getToken() {
    return getObject('id_token');
  }

  logout() {
    deleteObject('id_token');
    deleteObject('roles');
  }

  getProfile() {
    return this.getToken() ? decode(this.getToken()) : {};
  }
}

var SingletonAuthService = (function () {
  var instance;

  function createInstance(domain) {
    var service = new AuthService(domain);
    return service;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

var sessionManagement = SingletonAuthService.getInstance();

export { sessionManagement, SubmissionError };
