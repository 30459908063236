import * as R from 'ramda';
import AcademicDetails from 'components/students/Academic';
import AccountStatus from 'components/students/AccountStatus';
import Administration from 'components/students/Administration';
import Details from 'components/students/Information';
import GradeIcon from '@mui/icons-material/Grade';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Page from 'components/Page';
import PaymentIcon from '@mui/icons-material/Payment';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import Prints from 'components/students/Print/Prints';
import React from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StudentHeader from 'components/students/StudentHeader';
import SwipeableViews from 'react-swipeable-views';
import HashLoader from 'react-spinners/HashLoader';
import {
  AppBar,
  Box,
  Breadcrumbs,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  // Link,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { GET_ESTUDIANTE } from '../utils/api';
import { ResourcesContext } from 'state/ResourcesProvider';
import { useParams, Navigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const StudentBreadcrumbs = ({ matricula }) => {
  const breadcrumbs = [
    <Link
      style={{
        color: '#000',
        textDecoration: 'initial',
      }}
      key='1'
      to='/'>
      Inicio
    </Link>,
    <Link
      key='2'
      style={{
        color: '#000',
        textDecoration: 'initial',
      }}
      to='/c/estudiantes/'>
      Estudiantes
    </Link>,
    <Typography key='3' color='text.primary'>
      {matricula}
    </Typography>,
  ];

  return (
    <Stack spacing={2} mb={4} ml={4}>
      <Typography variant='h6'>Información Del Estudiante</Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize='small' />}
        aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

const Secciones = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      <AppBar
        position='static'
        sx={{ bgcolor: 'background.paper', color: '#000' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='secondary'
          textColor='inherit'
          variant='scrollable'>
          <Tab
            icon={<PermIdentityIcon />}
            label='Información Académica'
            {...a11yProps(0)}
          />
          <Tab
            icon={<GradeIcon />}
            label='Historial Académico'
            {...a11yProps(1)}
          />
          <Tab
            icon={<PaymentIcon />}
            label='Estado de Cuenta'
            {...a11yProps(2)}
          />
          <Tab
            icon={<ReceiptIcon />}
            label='Administración'
            {...a11yProps(3)}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}>
        <Details value={value} index={0} dir={theme.direction} />
        <AcademicDetails value={value} index={1} dir={theme.direction} />
        <AccountStatus value={value} index={2} dir={theme.direction} />
        <Administration value={value} index={3} dir={theme.direction} />
      </SwipeableViews>
    </Box>
  );
};

const Estudiante = () => {
  const { matricula } = useParams();
  const { state, dispatch } = React.useContext(ResourcesContext);
  const { estudiante } = state;
  const [loading, setLoading] = React.useState(false);
  const [notFound, setNotFound] = React.useState(false);

  React.useEffect(async () => {
    setLoading(true);
    try {
      const response = await GET_ESTUDIANTE(matricula);
      dispatch({
        type: 'FETCH_ESTUDIANTE',
        payload: { estudiante: response.data.estudiante, matricula },
      });
    } catch (error) {
      setNotFound(true);
    } finally {
      setLoading(false);
    }
  }, [matricula, state.matricula]);

  if (notFound) return <Navigate to='404' replace />;

  if (R.isNil(estudiante)) return null;

  return (
    <Page title='Estudiante | Control Escolar'>
      <StudentBreadcrumbs matricula={matricula} />
      {loading ? (
        <Container maxWidth='xl'>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              position: 'absolute',
              top: '50%',
              left: '50%',
            }}>
            <Stack direction='column' spacing={3}>
              <HashLoader loading={true} size={100} />
              <Typography variant='h6'>Cargando</Typography>
            </Stack>
          </Box>
        </Container>
      ) : (
        <Container maxWidth='xl'>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  action={
                    <Stack direction='row' spacing={1}>
                      <Prints />
                    </Stack>
                  }
                />
                <StudentHeader estudiante={estudiante} />
                <Divider />
                <Secciones />
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default Estudiante;
