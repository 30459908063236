import * as R from 'ramda';
import React from 'react';
import { StateContext } from 'state/StateProvider';
import { ResourcesContext } from 'state/ResourcesProvider';

export const BUCKET = process.env.REACT_APP_BUCKET_S3;

export const capitalize = R.compose(
  R.join(''),
  R.juxt([R.compose(R.toUpper, R.head), R.tail])
);

export const truncate = (str, size) => {
  if (str.length <= size) return str;
  return `${str.slice(0, size)}...`;
};

export const getAge = (value) => {
  const birthDate = new Date(value);
  const now = new Date();
  const age = now.getFullYear() - birthDate.getFullYear();
  const months = now.getMonth() - birthDate.getMonth();
  if (months < 0 || (months === 0 && now.getDate < birthDate.getDate())) {
    return `${age - 1} años`;
  }
  return `${age} años`;
};

export const handlePeriodos = () => {
  const context = React.useContext(ResourcesContext);
  const { state } = context;

  const periodoActual = () => state.periodoActual?.periodo;
  const periodoAnterior = () => state.periodoAnterior?.periodo;
  const periodoSiguiente = () => state.periodoSiguiente?.periodo;
  const periodos = () => state.periodos?.map((p) => p.periodo);

  return { periodoActual, periodoAnterior, periodoSiguiente, periodos };
};

export const handleAlerts = () => {
  const context = React.useContext(StateContext);
  const { dispatch } = context;

  const successMessage = (message) =>
    dispatch({
      type: 'ALERT',
      payload: {
        open: true,
        type: 'success',
        message: message,
      },
    });

  const errorMessage = (message) =>
    dispatch({
      type: 'ALERT',
      payload: {
        open: true,
        type: 'error',
        message: message,
      },
    });

  const warningMessage = (message) =>
    dispatch({
      type: 'ALERT',
      payload: {
        open: true,
        type: 'warning',
        message: message,
      },
    });

  return { successMessage, errorMessage, warningMessage };
};

export const hasPeriodo = (estudiante, periodoId) =>
  hasHistorial(estudiante)
    ? !R.isNil(
        R.find((periodo) => periodo.Periodo.periodo === periodoId)(
          estudiante.Periodos
        )
      )
    : false;

export const hasHistorial = (estudiante) => {
  const historial = estudiante?.Periodos;
  return !R.isNil(historial) && !R.isEmpty(historial);
};

export const toFloat = (value) => Number.parseFloat(value).toFixed(2);

export const getPagado = (pagos) =>
  R.sum(
    R.map((mov) => toPesos(mov))(
      R.filter((mov) =>
        R.contains(mov.status.toLowerCase(), ['paid', 'succeeded'])
      )(pagos)
    )
  );

const toPesos = (deuda) =>
  deuda.moneda?.toLowerCase() === 'usd'
    ? toFloat(deuda.cantidad) * 20
    : toFloat(deuda.cantidad);

export const getAdeudo = (deudas) =>
  R.sum(R.map((deuda) => toPesos(deuda))(deudas));

export const hasAdeudos = (estudiante, lastPeriod) => {
  if (hasHistorial(estudiante)) {
    const ultimoPeriodo = R.find(
      (periodo) => periodo.Periodo.periodo === lastPeriod
    )(estudiante.Periodos);
    if (R.isNil(ultimoPeriodo)) return false;

    const deudas = ultimoPeriodo.deudas;
    if (!R.isEmpty(deudas)) {
      const movimientos = ultimoPeriodo?.Movimientos;
      const adeudo = getAdeudo(deudas);
      const pagado = getPagado(movimientos);
      return adeudo - pagado > 0;
    }
  }
  return false;
};

export const hasAdeudosHist = (pagos, deudas) => {
  const adeudo = getAdeudo(deudas);
  const pagado = getPagado(pagos);
  return adeudo - pagado > 0;
};

export const estatuses = [
  { id: 1, descripcion: 'Correo No Verificado' },
  { id: 2, descripcion: 'Correo Verificado' },
  { id: 3, descripcion: 'Inscrito Sin Pago' },
  { id: 4, descripcion: 'Inscrito' },
  { id: 5, descripcion: 'Graduado' },
  { id: 6, descripcion: 'Baja' },
];

export const formatDate = (date) => {
  if (R.isNil(date) || R.isEmpty(date)) return '';
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  return new Date(date).toLocaleString('default', options);
};

export const formatDateWOTime = (date) => {
  if (R.isNil(date) || R.isEmpty(date)) return '';
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return new Date(date).toLocaleString('default', options);
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const firstUpper = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const concepts = [
  'Inscripción',
  'Reinscripción',
  'Colegiatura',
  'Documentación',
  'Apostillado',
  'Equivalencia',
];

export const defineStatusCardColor = (statusId, statusList) => {
  if (statusId === 6 || !statusList?.find((s) => s.id === statusId)) {
    return 'error';
  } else if (statusId === 4) {
    return 'success';
  } else {
    return 'warning';
  }
};

export const fromCamelCaseToTitle = (input) => {
  // Agrega un espacio antes de cada letra mayúscula, excepto si es la primera letra
  let result = input.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Asegura que la primera letra sea mayúscula y el resto del string sea minúsculas
  result = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();

  return result;
};
