import * as R from 'ramda';
import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  TextField,
  IconButton,
  InputAdornment,
  DialogActions,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Typography,
  Tooltip,
} from '@mui/material';
import { ResourcesContext } from 'state/ResourcesProvider';
import { handleAlerts, toFloat, handlePeriodos, concepts } from 'utils';
import { POST_LINK_CONEKTA } from 'utils/api';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReplayIcon from '@mui/icons-material/Replay';
import Confirma from 'utils/Confirma';

const LinkConekta = () => {
  const { state } = React.useContext(ResourcesContext);
  const { estudiante } = state;
  const { successMessage, errorMessage } = handleAlerts();
  const { periodos: getPeriodos } = handlePeriodos();
  const Periods = getPeriodos();
  const initialData = {
    matricula: estudiante.matricula,
    nombre: estudiante.Persona.nombreCompleto,
    email: estudiante.Persona?.Contacto?.email,
    phone: estudiante.Persona?.Contacto?.telefono,
    periodo: Periods[0],
    concepto: '',
    importe: 0,
    moneda: 'MXN',
    metodoPago: 'cash',
  };
  const [data, setData] = React.useState(initialData);
  const [open, setOpen] = React.useState(false);
  const [conektaInfo, setConektaInfo] = React.useState(null);
  const metodosPago = [
    { id: 'cash', name: 'Efectivo' },
    { id: 'card', name: 'Tarjeta' },
    { id: 'bank_transfer', name: 'Transferencia' },
  ];

  const copyToClipboard = (url) => (_event) => {
    navigator.clipboard.writeText(url);
    successMessage('Se ha copiado el enlace de pago al portapapeles ');
  };

  const handleEdit = (key) => (event) => {
    const newData = { ...data };
    newData[key] = event.target.value;
    setData(newData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await POST_LINK_CONEKTA(data);
      setConektaInfo(response.data.checkout);
    } catch (error) {
      console.log('Error', error.response.data?.message || error);
      errorMessage(
        error.response?.data?.message[0]?.message ||
          error.message ||
          error.response.data?.message ||
          JSON.stringify(error)
      );
    }
  };

  const reset = () => {
    setData(initialData);
    setConektaInfo(null);
    setOpen(false);
  };

  return (
    <>
      <Card>
        <CardHeader
          title='Nuevo Enlace de Pago de Conekta'
          subheader={
            conektaInfo
              ? 'Se ha generado el enlace correctamente'
              : 'Datos para generar nuevo enlace de conekta'
          }
          action={
            <Tooltip placement='bottom' title='Generar nueva referencia'>
              <IconButton onClick={() => setOpen(true)}>
                <ReplayIcon />
              </IconButton>
            </Tooltip>
          }
        />
        {conektaInfo ? (
          <CardContent>
            <Stack spacing={1}>
              <DataDisplayLink keyword='Nombre' value={data.nombre} />
              <DataDisplayLink keyword='Correo' value={data.email} />
              <DataDisplayLink keyword='Periodo' value={data.periodo} />
              <DataDisplayLink keyword='Concepto' value={data.concepto} />
              <DataDisplayLink
                keyword='Importe'
                value={`${toFloat(data.importe)} MXN`}
              />
              <Typography variant='body2'>
                <Typography sx={{ fontWeight: 600 }} component='span'>
                  URL: &nbsp;
                </Typography>
                <Tooltip placement='top' title='Copiar URL'>
                  <IconButton
                    size='small'
                    onClick={copyToClipboard(conektaInfo.url)}>
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
                {conektaInfo.url}
              </Typography>
              <Button
                href={conektaInfo.url}
                startIcon={<OpenInNewIcon />}
                rel='noopener noreferrer'
                target='_blank'>
                Abrir enlace en nueva pestaña
              </Button>
            </Stack>
          </CardContent>
        ) : (
          <form onSubmit={handleSubmit}>
            <CardContent>
              <Grid container spacing={3} alignItems='stretch'>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Matrícula'
                    value={data.matricula}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Nombre'
                    value={data.nombre}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label='Correo del usuario'
                    value={data.email}
                    onChange={handleEdit('email')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label='Telefono'
                    value={data.phone}
                    onChange={handleEdit('phone')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel id='periodo-label'>Periodo</InputLabel>
                    <Select
                      labelId='periodo-label'
                      id='periodo'
                      value={data.periodo}
                      label='Periodo'
                      onChange={handleEdit('periodo')}>
                      {Periods.map((val) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel id='metodos-label'>Métodos de pago</InputLabel>
                    <Select
                      labelId='metodos-label'
                      id='metodoPago'
                      value={data.metodoPago}
                      label='Métodos de pago'
                      onChange={handleEdit('metodoPago')}>
                      {metodosPago.map((val) => (
                        <MenuItem key={val.id} value={val.id}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel id='concepto-label'>Concepto</InputLabel>
                    <Select
                      labelId='concepto-label'
                      id='concepto'
                      value={data.concepto}
                      label='Concepto'
                      onChange={handleEdit('concepto')}>
                      {R.map((concepto) => (
                        <MenuItem key={concepto} value={concepto}>
                          {concepto}
                        </MenuItem>
                      ))(concepts)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    type='number'
                    label='Importe'
                    value={data.importe}
                    onChange={handleEdit('importe')}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>MXN</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <DialogActions sx={{ mr: 1, mb: 1 }}>
              <Button
                startIcon={<AddBoxIcon />}
                type='submit'
                variant='contained'>
                Generar enlace
              </Button>
            </DialogActions>
          </form>
        )}
      </Card>
      <Confirma
        open={open}
        setOpen={(open) => setOpen(open)}
        action={reset}
        titulo='¿Deseas volver a generar el enlace de pago?'
      />
    </>
  );
};

const DataDisplayLink = ({ keyword, value }) => {
  return (
    <Typography variant='body2'>
      <Typography sx={{ fontWeight: 600 }} variant='body2' component='span'>
        {keyword}: &nbsp;
      </Typography>
      {value}
    </Typography>
  );
};

export default LinkConekta;
