import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { CustomTextField } from 'components/CustomTextField';
import CustomSelect from 'components/CustomSelect';
import { useHandleUsers } from './hooks/useHandleUsers';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const FormSchema = ({ hasRole, hasPass }) =>
  yup.object().shape({
    userEmail: yup
      .string()
      .email('Correo inválido')
      .required('Campo requerido'),
    ...(hasRole && { role: yup.string().required('Campo requerido') }),
    ...(hasPass && {
      pass: yup
        .string()
        .required('Campo requerido')
        .min(8, 'La contraseña debe tener al menos 8 caracteres')
        .matches(/[0-9]/, 'La contraseña debe tener al menos un número')
        .matches(
          /[a-z]/,
          'La contraseña debe tener al menos una letra minúscula'
        )
        .matches(
          /[A-Z]/,
          'La contraseña debe tener al menos una letra mayúscula'
        )
        .matches(
          /[^\w]/,
          'La contraseña debe tener al menos un caracter especial'
        ),
    }),
  });

export const EditUserModal = ({ editModalData }) => {
  const { open, type, initialValues, handleClose, refetch, userId } =
    editModalData;

  const [showPassword, setShowPassword] = useState(false);

  const { createUser, editUser } = useHandleUsers({
    handleClose,
    userId,
    refetch,
  });

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validationSchema: FormSchema({
      hasRole: initialValues.role || type === 'add',
      hasPass: type === 'add',
    }),
    onSubmit: (values) => {
      type === 'add' ? createUser(values) : editUser(values);
      formik.resetForm();
    },
  });

  const basicRoles = [
    { value: 'administrativo', label: 'Administrativo' },
    { value: 'sistemas', label: 'Sistemas' },
  ];
  const advancedRoles = basicRoles.concat([
    {
      value: 'superusuario',
      label: 'Super usuario',
    },
  ]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm'>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <DialogTitle color='primary.darker' style={{ paddingBottom: 0 }}>
          <Typography variant='h5'>
            {type === 'add' ? 'Agregar' : 'Editar'} usuario
          </Typography>
        </DialogTitle>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Box display='flex' flexDirection='column' gap='24px'>
            <DialogContentText>
              <Box display='flex' flexDirection='column' gap='8px'>
                <Typography variant='subtitle1' color='secondary'>
                  Por favor, ingresa el correo electrónico y el rol del usuario
                </Typography>
              </Box>
            </DialogContentText>
            <Box display='flex' flexDirection='column' gap='24px'>
              <CustomTextField
                id='email'
                label='Correo electrónico'
                name='userEmail'
                onChange={formik.handleChange}
                value={formik.values?.userEmail}
                error={formik.errors.userEmail}
              />
              {(initialValues.role || type === 'add') && (
                <CustomSelect
                  id='userRole'
                  label='Rol'
                  placeholder='Selecciona un rol'
                  name='role'
                  onChange={formik.handleChange}
                  value={formik.values?.role}
                  error={formik.errors.role}
                  options={type === 'add' ? advancedRoles : basicRoles}
                />
              )}
              {type === 'add' && (
                <CustomTextField
                  id='password'
                  label='Contraseña'
                  name='pass'
                  onChange={formik.handleChange}
                  value={formik.values?.pass}
                  error={formik.errors.pass}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Box>
          </Box>
        </DialogContent>

        <DialogActions sx={{ padding: '16px 24px' }}>
          <Button
            onClick={() => {
              handleClose();
              formik.resetForm();
            }}>
            Cancelar
          </Button>
          <Button variant='contained' type='submit' color='primary'>
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
