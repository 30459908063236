import * as R from 'ramda';
import React from 'react';

const initialState = {
  estudiante: null,
  matricula: null,
  estudiantes: [],
  filtrados: [],
  centros: [],
  materias: [],
  religiones: [],
  page: 0,
  periodoActual: null,
  periodoAnterior: null,
  periodoSiguiente: null,
  periodos: null,
  filters: {
    filterType: 'nombreCompleto',
    filterName: '',
    selectedStatusFilter: null,
    checkedFilters: [],
    filterBySex: null,
    filterByReligion: null,
    filterByCenter: null,
    filterByCountry: null,
  },
  usersFilters: {
    selectedStatusFilter: '',
  },
  dashboard: {
    studentsData: null,
    debtorsData: null,
    studentsStats: null,
    usersStats: null,
  },
  statusList: null,
};

const replaceNil = (value) => (R.isNil(value) ? '' : value);

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'FETCH_ESTUDIANTE': {
      const newEst = payload.estudiante;
      newEst.Persona.nombreCompleto = `${replaceNil(
        newEst.Persona?.nombre
      )} ${replaceNil(newEst.Persona?.apellidoPaterno)} ${replaceNil(
        newEst.Persona?.apellidoMaterno
      )}`;
      const matricula = payload.matricula;
      return { ...state, estudiante: newEst, matricula };
    }
    case 'SET_MATRICULA':
      return { ...state, matricula: payload };
    case 'SET_ESTUDIANTE': {
      const newEst = payload;
      newEst.Persona.nombreCompleto = `${replaceNil(
        newEst.Persona?.nombre
      )} ${replaceNil(newEst.Persona?.apellidoPaterno)} ${replaceNil(
        newEst.Persona?.apellidoMaterno
      )}`;
      return { ...state, estudiante: newEst };
    }
    case 'SET_ESTUDIANTES':
      return { ...state, estudiantes: payload };
    case 'FETCH_BASIC_DATA':
      return {
        ...state,
        centros: payload.centros,
        materias: payload.materias,
        religiones: payload.religiones,
      };
    case 'SET_PAGE':
      return { ...state, page: payload };
    case 'SET_PERIODO_ACTUAL':
      return { ...state, periodoActual: payload };
    case 'SET_PERIODO_ANTERIOR':
      return { ...state, periodoAnterior: payload };
    case 'SET_PERIODO_SIGUIENTE':
      return { ...state, periodoSiguiente: payload };
    case 'SET_PERIODOS':
      return { ...state, periodos: payload };
    case 'SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...payload } };
    case 'SET_DASHBOARD_DATA':
      return { ...state, dashboard: { ...state.dashboard, ...payload } };
    case 'SET_STATUS_LIST':
      return { ...state, statusList: payload };
    case 'SET_USERS_FILTERS':
      return { ...state, usersFilters: { ...state.usersFilters, ...payload } };
    default:
      throw new Error(`Can't recognize the action: ${type}`);
  }
};

export const ResourcesContext = React.createContext({});

export const ResourcesProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <ResourcesContext.Provider value={{ state, dispatch }}>
      {children}
    </ResourcesContext.Provider>
  );
};
