import { Box, Button, Typography } from '@mui/material';
import CustomSelect from 'components/CustomSelect';
import { CustomTextField } from 'components/CustomTextField';
import { useFormik } from 'formik';
import { TermsFormValidationSchema, subcycleOptions } from './utils';
import { useNavigate } from 'react-router-dom';
import { useOnSubmit } from './useOnSubmit';
import { CustomDateField } from 'components/CustomDateField';
import PropTypes from 'prop-types';

export const TermsForm = ({ type, defaultValues }) => {
  const navigate = useNavigate();
  const onSubmit = useOnSubmit(type);

  const formik = useFormik({
    initialValues: {
      id: defaultValues?.id || '',
      title: defaultValues?.periodo || '',
      cycle: defaultValues?.cicloSEP || '',
      subcycle: defaultValues?.subcicloSEP || '',
      termStartDate: defaultValues?.fechaInicio || '',
      termEndDate: defaultValues?.fechaTermino || '',
      enrolmentStartDate: defaultValues?.fechaInicioInscripciones || '',
      enrolmentEndDate: defaultValues?.fechaFinInscripciones || '',
      reEnrolmentStartDate: defaultValues?.fechaInicioReinscripciones || '',
      reEnrolmentEndDate: defaultValues?.fechaFinReinscripciones || '',
      moodleUploadUsersDate: defaultValues?.moodleUploadUsersDate || '',
      moodleUploadCoursesDate: defaultValues?.moodleUploadCoursesDate || '',
      holidaysStartDate: defaultValues?.inicioVacaciones || '',
      holidaysEndDate: defaultValues?.finVacaciones || '',
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
    validationSchema: TermsFormValidationSchema,
    validateOnChange: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        display='flex'
        flexDirection='column'
        rowGap={['24px', '24px', '32px']}>
        <Box
          display='grid'
          columnGap='32px'
          rowGap={['24px', '24px', '32px']}
          gridTemplateColumns={['100%', '100%', 'repeat(2, 1fr)']}>
          <CustomTextField
            id='title'
            name='title'
            label='Nombre'
            placeholder='2023-1'
            onChange={formik.handleChange}
            value={formik.values.title}
            error={formik.errors.title}
          />
          <CustomTextField
            id='cycle'
            name='cycle'
            label='Ciclo SEP'
            onChange={formik.handleChange}
            value={formik.values.cycle}
            error={formik.errors.cycle}
            type='number'
          />
          <CustomSelect
            id='subcycle'
            name='subcycle'
            label='Subciclo SEP'
            placeholder='Selecciona'
            options={subcycleOptions}
            handleChange={formik.handleChange}
            value={formik.values.subcycle}
            error={formik.errors.subcycle}
          />
        </Box>
        <Box display='flex' flexDirection='column' rowGap='16px'>
          <Typography variant='subtitle1' sx={{ color: 'secondary.main' }}>
            Periodo
          </Typography>
          <Box
            display='flex'
            columnGap='32px'
            rowGap={['24px', '24px', '32px']}
            flexDirection={['column', 'column', 'row']}>
            <CustomDateField
              id='termStartDate'
              label='Fecha de inicio'
              onChange={(value) => {
                if (value !== 'Invalid Date') {
                  formik.setFieldValue('termStartDate', value);
                }
              }}
              value={formik.values.termStartDate}
              error={formik.errors.termStartDate}
            />
            <CustomDateField
              id='termEndDate'
              label='Fecha de término'
              onChange={(value) => {
                if (value !== 'Invalid Date') {
                  formik.setFieldValue('termEndDate', value);
                }
              }}
              value={formik.values.termEndDate}
              error={formik.errors.termEndDate}
            />
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' rowGap='16px'>
          <Typography variant='subtitle1' sx={{ color: 'secondary.main' }}>
            Inscripciones
          </Typography>
          <Box
            display='flex'
            columnGap='32px'
            rowGap={['24px', '24px', '32px']}
            flexDirection={['column', 'column', 'row']}>
            <CustomDateField
              id='enrolmentStartDate'
              label='Fecha de inicio'
              onChange={(value) => {
                if (value !== 'Invalid Date') {
                  formik.setFieldValue('enrolmentStartDate', value);
                }
              }}
              value={formik.values.enrolmentStartDate}
              error={formik.errors.enrolmentStartDate}
            />
            <CustomDateField
              id='enrolmentEndDate'
              label='Fecha de término'
              onChange={(value) => {
                if (value !== 'Invalid Date') {
                  formik.setFieldValue('enrolmentEndDate', value);
                }
              }}
              value={formik.values.enrolmentEndDate}
              error={formik.errors.enrolmentEndDate}
            />
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' rowGap='16px'>
          <Typography variant='subtitle1' sx={{ color: 'secondary.main' }}>
            Reinscripciones
          </Typography>
          <Box
            display='flex'
            columnGap='32px'
            rowGap={['24px', '24px', '32px']}
            flexDirection={['column', 'column', 'row']}>
            <CustomDateField
              id='reEnrolmentStartDate'
              label='Fecha de inicio'
              onChange={(value) => {
                if (value !== 'Invalid Date') {
                  formik.setFieldValue('reEnrolmentStartDate', value);
                }
              }}
              value={formik.values.reEnrolmentStartDate}
              error={formik.errors.reEnrolmentStartDate}
            />
            <CustomDateField
              id='reEnrolmentEndDate'
              label='Fecha de término'
              onChange={(value) => {
                if (value !== 'Invalid Date') {
                  formik.setFieldValue('reEnrolmentEndDate', value);
                }
              }}
              value={formik.values.reEnrolmentEndDate}
              error={formik.errors.reEnrolmentEndDate}
            />
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' rowGap='16px'>
          <Typography variant='subtitle1' sx={{ color: 'secondary.main' }}>
            Subida de usuarios y cursos a Moodle
          </Typography>
          <Box
            display='flex'
            columnGap='32px'
            rowGap={['24px', '24px', '32px']}
            flexDirection={['column', 'column', 'row']}>
            <CustomDateField
              id='moodleUploadUsersDate'
              label='Propedéutico'
              onChange={(value) => {
                if (value !== 'Invalid Date') {
                  formik.setFieldValue('moodleUploadUsersDate', value);
                }
              }}
              value={formik.values.moodleUploadUsersDate}
              error={formik.errors.moodleUploadUsersDate}
            />
            <CustomDateField
              id='moodleUploadCoursesDate'
              label='Todas las materias'
              onChange={(value) => {
                if (value !== 'Invalid Date') {
                  formik.setFieldValue('moodleUploadCoursesDate', value);
                }
              }}
              value={formik.values.moodleUploadCoursesDate}
              error={formik.errors.moodleUploadCoursesDate}
            />
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' rowGap='16px'>
          <Typography variant='subtitle1' sx={{ color: 'secondary.main' }}>
            Vacaciones
          </Typography>
          <Box
            display='flex'
            columnGap='32px'
            rowGap={['24px', '24px', '32px']}
            flexDirection={['column', 'column', 'row']}>
            <CustomDateField
              id='holidaysStartDate'
              label='Fecha de inicio'
              onChange={(value) => {
                if (value !== 'Invalid Date') {
                  formik.setFieldValue('holidaysStartDate', value);
                }
              }}
              value={formik.values.holidaysStartDate}
              error={formik.errors.holidaysStartDate}
            />
            <CustomDateField
              id='holidaysEndDate'
              label='Fecha de término'
              onChange={(value) => {
                if (value !== 'Invalid Date') {
                  formik.setFieldValue('holidaysEndDate', value);
                }
              }}
              value={formik.values.holidaysEndDate}
              error={formik.errors.holidaysEndDate}
            />
          </Box>
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
          columnGap={['24px', '24px', '32px']}
          alignSelf='flex-end'
          width={['100%', '100%', '280px']}>
          <Button
            fullWidth
            variant='outlined'
            type='button'
            onClick={() => navigate(-1)}>
            Cancelar
          </Button>
          <Button fullWidth variant='contained' type='submit'>
            {type === 'edit' ? 'Guardar' : 'Crear'}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

TermsForm.propTypes = {
  type: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
};
