import { useContext } from 'react';
import { ResourcesContext } from 'state/ResourcesProvider';
import { handleAlerts } from 'utils';
import { GET_ESTUDIANTE, PUT_MOVIMIENTO } from 'utils/api';

export const useEditConcept = (closeModal, movement, periodo) => {
  const {
    state: { estudiante },
    dispatch,
  } = useContext(ResourcesContext);
  const { successMessage, errorMessage } = handleAlerts();

  const handleEditConcept = async (data, restForm) => {
    try {
      await PUT_MOVIMIENTO(movement.id, {
        id: movement?.id,
        cantidad: movement?.cantidad,
        moneda: movement?.moneda,
        periodo,
        concepto: data.concepto,
        referenceId: movement?.referenceId,
        status: movement?.status,
        referenceType: movement?.referenceType || '',
      });

      const response = await GET_ESTUDIANTE(estudiante.matricula);
      dispatch({ type: 'SET_ESTUDIANTE', payload: response.data.estudiante });

      successMessage('Se editó el concepto correctamente.');
    } catch (error) {
      console.log('Error', error);
      errorMessage(error);
    } finally {
      closeModal();
      restForm();
    }
  };
  return { handleEditConcept };
};
