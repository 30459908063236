import * as React from 'react';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'components/Logo';
import { SidebarButton } from './SidebarButton';

export const DrawerBody = ({ pages }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '56px 16px',
        rowGap: '100px',
      }}>
      <Box
        component={RouterLink}
        to='/'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Logo />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '16px',
        }}>
        {pages.map((page) => (
          <SidebarButton
            key={page.title}
            label={page.title}
            icon={page.icon}
            route={page.path}
            subpages={page.subpages}
          />
        ))}
      </Box>
    </Box>
  );
};
