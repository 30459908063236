import { useContext } from 'react';
import { ResourcesContext } from 'state/ResourcesProvider';
import { handleAlerts } from 'utils';
import { POST_UPDATE_MOVIMIENTO } from 'utils/api';
import * as R from 'ramda';

export const useOnSubmitPayment = (closeModal) => {
  const {
    state: { estudiante },
    dispatch,
  } = useContext(ResourcesContext);
  const { successMessage, errorMessage } = handleAlerts();

  const handleSubmitNewPayment = async (data, restForm) => {
    try {
      const response = await POST_UPDATE_MOVIMIENTO({
        data: {
          EstudianteId: estudiante.id,
          referenceId: data.referenceId,
          periodo: data.periodo,
        },
      });
      const movimiento = response.data.movimiento;
      const periodo = data.periodo;
      const newPagos = R.propOr(
        [],
        'Movimientos',
        R.find((p) => p.Periodo.periodo === periodo)(estudiante.Periodos)
      );
      newPagos.push(movimiento);
      const newEst = { ...estudiante };
      const periodos = newEst.Periodos;
      let last = R.find((p) => p.Periodo.periodo === periodo)(periodos);
      if (R.isNil(last)) {
        last = {
          Materias: [],
          Periodo: { periodo: periodo },
          Movimientos: newPagos,
          deudas: [],
        };
        periodos.push(last);
      } else {
        last.Movimientos = newPagos;
      }
      dispatch({ type: 'SET_ESTUDIANTE', payload: newEst });
      successMessage('Se ha añadido el movimiento correctamente');
    } catch (error) {
      console.log('Error', error.response?.data?.message || error);
      errorMessage(error.response?.data?.message || error.message);
    } finally {
      closeModal();
      restForm();
    }
  };
  return { handleSubmitNewPayment };
};
