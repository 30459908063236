import * as R from 'ramda';
import React from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { AddAlert } from '@mui/icons-material';
import { ResourcesContext } from 'state/ResourcesProvider';
import { PaymentsModal } from './PaymentsModal';
import { PaymentsInfo } from './PaymentsInfo';

const Record = () => {
  const { state } = React.useContext(ResourcesContext);
  const { estudiante } = state;

  const [paymentInfo, setPaymentInfo] = React.useState({
    debt: '',
    currentPayment: '',
  });
  const [periodo, setPeriodo] = React.useState('');
  const [periodosDisp, setPeriodosDisp] = React.useState(null);
  const [modal, setModal] = React.useState(false);

  React.useEffect(() => {
    const periodos = estudiante.Periodos;
    const noVacios = R.sort(R.compose(R.prop('periodo'), R.prop('Periodo')))(
      R.filter(
        (periodo) =>
          !R.isEmpty(periodo.Movimientos) || !R.isEmpty(periodo.deudas)
      )(periodos)
    );
    const strPeriodos = R.map((p) => p.Periodo.periodo)(noVacios);
    setPeriodo(R.last(strPeriodos) || '');
    setPeriodosDisp(strPeriodos);
  }, [estudiante]);

  React.useEffect(() => {
    const term = R.find((p) => p.Periodo.periodo === periodo)(
      estudiante.Periodos
    );

    if (!R.isNil(term)) {
      setPaymentInfo({
        debt: term.deuda,
        currentPayment: term.pagoActual,
      });
    } else {
      setPaymentInfo({
        debt: 0,
        currentPayment: 0,
      });
    }
  }, [periodo, estudiante]);

  return (
    <>
      <PaymentsModal open={modal} handleClose={() => setModal(false)} />
      <Stack direction='column' alignItems='stretch' spacing={2}>
        <Typography variant='subtitle1' color='primary.darker'>
          Movimientos
        </Typography>
        <Button
          variant='contained'
          fullWidth
          startIcon={<AddAlert />}
          onClick={() => setModal(true)}>
          Agregar movimiento
        </Button>
        <Typography variant='subtitle1' color='primary.darker'>
          Resumen por periodo
        </Typography>
        <Typography variant='caption'>
          Selecciona el periodo para obtener un resumen de los pagos y adeudos
          de usuario. Los valores son mostrados en Pesos Mexicanos, incluso si
          hay pagos en Dólares.
        </Typography>
        {periodosDisp ? (
          <FormControl fullWidth>
            <InputLabel id='periodo-label'>Periodo</InputLabel>
            <Select
              labelId='periodo-label'
              id='periodo'
              value={periodo}
              label='Periodo'
              onChange={(event) => setPeriodo(event.target.value)}>
              {periodosDisp?.map((val) => (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Typography variant='body2'>No hay periodos disponibles</Typography>
        )}
        <PaymentsInfo paymentInfo={paymentInfo} />
      </Stack>
    </>
  );
};

export default Record;
