import * as Yup from 'yup';

export const EducationalCentersFormValidationSchema = Yup.object().shape({
  centerName: Yup.string().required('Este campo es requerido'),
  centerKey: Yup.string().required('Este campo es requerido'),
  managerName: Yup.string().required('Este campo es requerido'),
  managerEmail: Yup.string()
    .email('Correo inválido')
    .required('Este campo es requerido'),
  postalCode: Yup.number().required('Este campo es requerido'),
  street: Yup.string().required('Este campo es requerido'),
  intNumber: Yup.number(),
  extNumber: Yup.number().required('Este campo es requerido'),
});
