import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { handleAlerts } from 'utils';
import { POST_CENTRO_EDUCATIVO, PUT_CENTRO_EDUCATIVO } from 'utils/api';

export const useOnSubmitCenter = (type, id) => {
  const navigate = useNavigate();
  const { successMessage, errorMessage } = handleAlerts();

  const queryClient = useQueryClient();

  const onSubmit = async (values) => {
    const data = {
      nombreCentroEducativo: values.centerName,
      clave: values.centerKey,
      encargado: {
        nombre: values.managerName,
        correo: values.managerEmail,
      },
      direccion: {
        codigoPostal: values.postalCode,
        calle: values.street,
        ...(values.intNumber && { numInt: values.intNumber }),
        numExt: values.extNumber,
      },
    };
    try {
      if (type === 'edit') {
        await PUT_CENTRO_EDUCATIVO(id, data);
        successMessage('Se editó correctamente el centro educativo');
        queryClient.invalidateQueries('educationalCenters');
        navigate(-1);
      } else {
        await POST_CENTRO_EDUCATIVO(data);
        successMessage('Se creó correctamente el centro educativo');
        queryClient.invalidateQueries('educationalCenters');
        navigate(-1);
      }
    } catch (error) {
      console.log('Error', error.response?.data?.message || error);
      errorMessage(error.message || error.response?.data?.message || error);
    }
  };

  return onSubmit;
};
