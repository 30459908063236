import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export const SubpageButton = ({ label, icon, route }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isActive = pathname === route;
  return (
    <Button
      sx={{
        width: '100%',
        justifyContent: 'flex-start',
        color: isActive ? 'primary.darker' : 'primary.main',
        ':hover': {
          color: 'primary.darker',
          backgroundColor: 'transparent',
        },
      }}
      variant='ghost'
      startIcon={icon}
      onClick={() => navigate(route)}>
      {label}
    </Button>
  );
};
