import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubpageButton } from './SubpageButton';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export const SidebarButton = ({ label, icon, route, subpages }) => {
  const [showSubpages, setShowSubpages] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isActive = pathname === route;

  const endIcon = () => {
    if (subpages) {
      return showSubpages ? <ExpandLess /> : <ExpandMore />;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
      }}>
      <Button
        sx={{
          width: '100%',
          justifyContent: 'flex-start',
          backgroundColor: isActive ? 'primary.main' : 'transparent',
          color: isActive ? 'primary.contrastText' : 'primary.main',
          ':hover': {
            backgroundColor: isActive ? 'primary.main' : 'primary.lighter',
            color: 'primary.contrastText',
          },
        }}
        variant='ghost'
        startIcon={icon}
        endIcon={endIcon()}
        onClick={() => {
          subpages ? setShowSubpages(!showSubpages) : navigate(route);
        }}>
        {label}
      </Button>
      {subpages && showSubpages && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
            paddingLeft: '32px',
            borderBottom: '1px solid primary.main',
          }}>
          {subpages.map((subpage) => (
            <SubpageButton
              key={subpage.title}
              icon={subpage.icon}
              label={subpage.title}
              route={subpage.path}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
