import * as R from 'ramda';
import CurrentSubjects from './CurrentSubjects';
import React from 'react';
import { Grid, Tooltip, TextField, Typography } from '@mui/material';
import { ResourcesContext } from 'state/ResourcesProvider';
import { hasHistorial } from 'utils';
import { styled } from '@mui/material/styles';

const filterCals = R.filter((materia) => !R.isNil(materia.Calificacion));
const getValues = R.map((materia) => materia.Calificacion.valor);
const getCalsPeriodo = R.map((periodo) =>
  R.compose(getValues, filterCals)(periodo.Materias)
);

const sumCalificaciones = (estudiante) => {
  return hasHistorial(estudiante)
    ? R.sum(R.flatten(getCalsPeriodo(estudiante.Periodos)))
    : 0;
};

const isRequired = (materia) =>
  materia.Materia.optativa === false && !R.isNil(materia.Calificacion) ? 1 : 0;

const reqMats = R.map((periodo) => R.map(isRequired)(periodo.Materias));

const countMatReq = (estudiante) => {
  return hasHistorial(estudiante)
    ? R.sum(R.flatten(reqMats(estudiante.Periodos)))
    : 0;
};

const isOptativa = (materia) =>
  materia.Materia.optativa !== false && !R.isNil(materia.Calificacion) ? 1 : 0;

const optMats = R.map((periodo) => R.map(isOptativa)(periodo.Materias));

const countMatOpt = (estudiante) => {
  return hasHistorial(estudiante)
    ? R.sum(R.flatten(optMats(estudiante.Periodos)))
    : 0;
};

const limitAverage = (value) => Number.parseFloat(value).toFixed(2);

const getGPA = (promedio) => {
  const calGPA = promedio * 10;
  const fn = R.cond([
    [(val) => val >= 90 && val <= 100, R.always(4.0)],
    [(val) => val >= 80 && val < 90, R.always(3.3)],
    [(val) => val >= 70 && val < 80, R.always(2.3)],
    [(val) => val >= 60 && val < 70, R.always(1.0)],
    [R.T, R.always(0)],
  ]);
  return fn(calGPA);
};

const DisabledTextField = styled(TextField)({
  root: {
    '&.Mui-disabled': {
      color: '#000',
    },
  },
});

const Information = () => {
  const { state } = React.useContext(ResourcesContext);
  const { estudiante } = state;
  const [materiasReqCursadas, setMateriasReqCursadas] = React.useState(0);
  const [materiasOptCursadas, setMateriasOptCursadas] = React.useState(0);
  const [totalMaterias, setTotalMaterias] = React.useState(0);
  const [promGPA, setPromGPA] = React.useState(0);
  const [promedio, setPromedio] = React.useState(0);

  React.useEffect(() => {
    if (!R.isNil(estudiante)) {
      const requeridas = countMatReq(estudiante);
      const optativas = countMatOpt(estudiante);
      const total = requeridas + optativas;
      const sumCals = sumCalificaciones(estudiante);
      const promedio = sumCals / total;
      const gpa = getGPA(promedio);
      setMateriasReqCursadas(requeridas);
      setMateriasOptCursadas(optativas);
      setTotalMaterias(requeridas + optativas);
      setPromGPA(gpa);
      setPromedio(promedio);
    }
  }, [estudiante]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>Información General</Typography>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          title='Total de materias obligatorias cursadas'
          placement='top'>
          <DisabledTextField
            fullWidth
            disabled
            variant='standard'
            id='totalMateriasObligadas'
            label='Obligatorias'
            value={materiasReqCursadas}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip title='Total de materias optativas cursadas' placement='top'>
          <TextField
            fullWidth
            disabled
            variant='standard'
            id='totalOptativas'
            label='Optativas'
            value={materiasOptCursadas}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip title='Total de materias cursadas' placement='top'>
          <TextField
            fullWidth
            disabled
            variant='standard'
            id='totalMateriasCursadas'
            label='Total'
            value={totalMaterias}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip title='Promedio GPA' placement='top'>
          <TextField
            fullWidth
            disabled
            variant='standard'
            id='promGPA'
            label='GPA'
            value={promGPA ? promGPA : ''}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip title='Promedio general' placement='top'>
          <TextField
            fullWidth
            disabled
            variant='standard'
            id='promedioGeneral'
            label='Promedio'
            value={promedio ? limitAverage(promedio) : ''}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <CurrentSubjects />
      </Grid>
    </Grid>
  );
};

export default Information;
