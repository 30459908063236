import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Stack,
  Typography,
  DialogActions,
} from '@mui/material';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import React from 'react';
import { ResourcesContext } from 'state/ResourcesProvider';
import { handleAlerts } from 'utils';
import { POST_ENVIA_CORREO } from 'utils/api';

const CorreoBienvenida = () => {
  const { state } = React.useContext(ResourcesContext);
  const { estudiante } = state;
  const { successMessage, errorMessage } = handleAlerts();

  const handleEnviaCorreo = async () => {
    try {
      const response = await POST_ENVIA_CORREO(estudiante.matricula);
      successMessage(response.data.message);
    } catch (error) {
      console.log('Error', error.response.data?.message || error);
      errorMessage(
        error.response?.data?.message || error.message || JSON.stringify(error)
      );
    }
  };

  return (
    <Card>
      <CardHeader title='Enviar correo de bienvenida' />
      <CardContent>
        <Stack direction='row'>
          <Typography variant='body1' gutterBottom>
            Al presionar el botón "Enviar Correo", se le enviará un correo de
            bienvenida al usuario, con las indicaciones que debe seguir para
            continuar con sus trámites de prepaenlinea.mx.
          </Typography>
        </Stack>
      </CardContent>
      <DialogActions sx={{ mr: 1, mb: 1 }}>
        <Button
          startIcon={<ContactMailIcon />}
          variant='contained'
          onClick={handleEnviaCorreo}>
          Enviar Correo
        </Button>
      </DialogActions>
    </Card>
  );
};

export default CorreoBienvenida;
