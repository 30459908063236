import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { GET_PERIODOS } from 'utils/api';
import CardTerms from './CardTerms';

const TermsList = () => {
  const [terms, setTerms] = useState(null);

  useEffect(async () => {
    if (terms === null) {
      const response = await GET_PERIODOS();
      setTerms(response.data.periodos);
    }
  }, [terms]);

  return terms === null ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '70vh',
      }}>
      <ClipLoader color='#61C4B2' />
    </Box>
  ) : (
    <Grid container spacing={2}>
      {terms.map((term) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={term.id}>
          <CardTerms
            id={term.id}
            name={term.periodo}
            dateStart={term.fechaInicio}
            dateEnd={term.fechaTermino}
            defaultValues={term}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TermsList;
