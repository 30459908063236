import { Box, Typography } from '@mui/material';
import CustomSelect from 'components/CustomSelect';
import Page from 'components/Page';
import { DashboardList } from 'components/_dashboard/dashboardV2/DashboardList';
import { Stats } from 'components/_dashboard/dashboardV2/DebtorsStats';
import { StudentsStats } from 'components/_dashboard/dashboardV2/StudentsStats';
import { UsersStats } from 'components/_dashboard/dashboardV2/UsersStats';
import { useDebounce } from 'hooks/useDebounce/useDebounce';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Loader from 'react-spinners/BarLoader';
import { ResourcesContext } from 'state/ResourcesProvider';
import {
  GET_GROUPED_BY_STATUS,
  GET_DEBTORS_DATA,
  GET_USERS_BY_STATUS,
  GET_LIST_OF_DEBTORS,
} from 'utils/api';
// ----------------------------------------------------------------------

const DashboardApp = () => {
  const { dispatch, state } = useContext(ResourcesContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchParam, setSearchParam] = useState('');
  const [typeOfFilter, setTypeOfFilter] = useState('nombreCompleto');
  const [debtorsTerm, setDebtorsTerm] = useState('');

  const debouncedValue = useDebounce(searchParam, 500);

  const { isLoading: isLoadingDebtorsData } = useQuery(
    ['debtors-stats', debtorsTerm],
    () => GET_DEBTORS_DATA(debtorsTerm),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        dispatch({
          type: 'SET_DASHBOARD_DATA',
          payload: { debtorsData: data?.data },
        });
      },
    }
  );

  const { isLoading: isLoadingStudentsDebtors } = useQuery(
    [
      'students-debtors',
      rowsPerPage,
      page,
      debouncedValue,
      typeOfFilter,
      debtorsTerm,
    ],
    () =>
      GET_LIST_OF_DEBTORS({
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        filters: {
          ...(typeOfFilter &&
            debouncedValue && { [typeOfFilter]: debouncedValue }),
          ...(debtorsTerm && { periodo: debtorsTerm }),
        },
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        dispatch({
          type: 'SET_DASHBOARD_DATA',
          payload: { studentsData: data?.data },
        });
      },
    }
  );

  const { isLoading: isStatsLoading } = useQuery(
    ['students-gruped-by-status'],
    () => GET_GROUPED_BY_STATUS(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        dispatch({
          type: 'SET_DASHBOARD_DATA',
          payload: { studentsStats: data?.data },
        });
      },
    }
  );

  const { isLoading: isUsersLoading } = useQuery(
    ['users-gruped-by-status'],
    () => GET_USERS_BY_STATUS(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        dispatch({
          type: 'SET_DASHBOARD_DATA',
          payload: { usersStats: data?.data },
        });
      },
    }
  );

  const isLoading =
    isLoadingDebtorsData ||
    isStatsLoading ||
    isUsersLoading ||
    isLoadingStudentsDebtors;

  const terms = state?.periodos?.map((term) => ({
    value: term.periodo,
    label: term.periodo,
  }));

  useEffect(() => {
    if (state?.periodos?.length > 0) {
      setDebtorsTerm(state?.periodos[0].periodo);
    }
  }, [state?.periodos]);

  return (
    <Page title='Dashboard | Control Escolar'>
      {isLoading ? (
        <Box
          width='100%'
          height='80vh'
          display='flex'
          alignItems='center'
          justifyContent='center'>
          <Loader color='#773286' />
        </Box>
      ) : (
        <Box display='grid' gap='32px' margin='0 auto' maxWidth='1200px'>
          <Box display='flex' gap='16px' flexDirection='column'>
            <Box display='flex' alignItems='center' columnGap='24px'>
              <Typography variant='h6' color='primary.darker'>
                Estudiantes deudores
              </Typography>
              <CustomSelect
                placeholder='Selecciona un periodo'
                options={terms || []}
                value={debtorsTerm}
                handleChange={(e) => {
                  setDebtorsTerm(e.target.value);
                }}
              />
            </Box>
            <Box
              display='flex'
              gap='32px'
              alignItems='center'
              flexDirection={['column-reverse', 'row']}>
              <Box width={['100%', 'calc(100% - 300px - 32px)']}>
                <DashboardList
                  page={page}
                  rowsPerPage={rowsPerPage}
                  searchParam={searchParam}
                  setSearchParam={setSearchParam}
                  setTypeOfFilter={setTypeOfFilter}
                  typeOfFilter={typeOfFilter}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </Box>
              <Stats title='Deudores' />
            </Box>
          </Box>
          <Box display='flex' gap='16px' flexDirection='column'>
            <Typography variant='h6' color='primary.darker'>
              Seguimiento de estudiantes y usuarios
            </Typography>
            <StudentsStats />
            <UsersStats />
          </Box>
        </Box>
      )}
    </Page>
  );
};

export default DashboardApp;
