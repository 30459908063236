import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import Page from 'components/Page';
import { EducationalCentersList } from 'components/administration/educationalCenters/EducationalCentersList';

export const EducationalCenters = () => {
  const navigate = useNavigate();
  return (
    <Page title='Centros Educativos'>
      <Container maxWidth='xl'>
        <Box
          sx={{
            pb: 5,
            display: 'flex',
            justifyContent: 'space-between',
            rowGap: '1rem',
          }}
          flexDirection={['column', 'row']}>
          <Typography variant='h4'>Centros Educativos</Typography>
          <Box
            display='flex'
            sx={{ columnGap: '24px' }}
            justifyContent={['space-between', 'flex-start']}
            alignItems='end'>
            <Button
              variant='contained'
              onClick={() =>
                navigate('/c/adminEscolar/centros-educativos/crear', {
                  state: {
                    title: 'Crear Centro Educativo',
                    type: 'create',
                  },
                })
              }>
              Agregar centro educativo
            </Button>
          </Box>
        </Box>
        <EducationalCentersList />
      </Container>
    </Page>
  );
};
