import { useNavigate } from 'react-router-dom';
import { handleAlerts } from 'utils';
import { POST_MATERIA, PUT_MATERIA } from 'utils/api';

export const useOnSubmit = (type) => {
  const { successMessage, errorMessage } = handleAlerts();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    const subject = {
      titulo: values.title,
      title: values.englishTitle,
      clave: values.subjectKey,
      ingles: values.isEnglish,
      descripcion: values.description,
      optativa: values.isOptional,
      tutor: values.tutor,
      cuatrimestre: values.fourthMonthPeriod,
      creditos: values.credits,
      tipo: values.subjectType,
      valida: values.isValid,
      seriada: values.serialized,
      portugues: false,
    };

    if (type === 'create') {
      try {
        await POST_MATERIA(subject);
        successMessage('Se ha creado la materia correctamente');
      } catch (error) {
        console.log('Error', error.response?.data?.message || error);
        errorMessage(error.message || error.response?.data?.message || error);
      } finally {
        navigate(-1);
      }
    } else {
      try {
        await PUT_MATERIA(values.subjectKey, subject);
        successMessage('Se ha actualizado la materia correctamente');
      } catch (error) {
        console.log('Error', error.response?.data?.message || error);
        errorMessage(error.message || error.response?.data?.message || error);
      } finally {
        navigate(-1);
      }
    }
  };

  return onSubmit;
};
