import { handleAlerts } from 'utils';
import { DELETE_USER, POST_USER, UPDATE_USER } from 'utils/api';

export const useHandleUsers = ({ handleClose, userId, refetch }) => {
  const { successMessage, errorMessage } = handleAlerts();

  const createUser = async (values) => {
    const data = {
      correo: values.userEmail,
      nombreRoles: [values.role],
      password: values.pass,
    };
    try {
      await POST_USER(data);
      successMessage('El usuario ha sido creado exitosamente');
      handleClose();
      refetch();
    } catch (error) {
      errorMessage(error?.response?.data?.message || error?.message);
    }
  };

  const editUser = async (values) => {
    const data = {
      correo: values.userEmail,
      ...(values.role && { nombresRoles: [values.role] }),
    };
    try {
      await UPDATE_USER(userId, data);

      successMessage('El usuario se actualizó correctamente');
      handleClose();
      refetch();
    } catch (error) {
      errorMessage(error?.response?.data?.message || error?.message);
    }
  };

  const deleteUser = async (userId) => {
    try {
      await DELETE_USER(userId);
      successMessage('El usuario se eliminó correctamente');
      handleClose();
      refetch();
    } catch (error) {
      errorMessage(error?.response?.data?.message || error?.message);
    }
  };

  return {
    createUser,
    editUser,
    deleteUser,
  };
};
