import PropTypes from 'prop-types';
import { Paper, Typography } from '@mui/material';

const SearchNotFound = ({ searchQuery = '', ...other }) => {
  return (
    <Paper {...other}>
      <Typography gutterBottom align='center' variant='subtitle1'>
        No encontrado
      </Typography>
      <Typography variant='body2' align='center'>
        Ningún resultado encontrado para &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Verifique errores de
        escritura o intente usar palabras completas.
      </Typography>
    </Paper>
  );
};

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default SearchNotFound;
