import { Box, Typography } from '@mui/material';
export const PaymentsInfo = ({ paymentInfo }) => {
  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        columnGap='8px'
        borderBottom='1px solid #d3d3d37d'
        paddingBottom='12px'>
        <Typography variant='subtitle2' color='secondary.main'>
          Deuda:
        </Typography>
        <Typography variant='body2'>${paymentInfo.debt}</Typography>
      </Box>
      <Box
        display='flex'
        alignItems='center'
        columnGap='8px'
        borderBottom='1px solid #d3d3d37d'
        paddingBottom='12px'>
        <Typography variant='subtitle2' color='secondary.main'>
          Pago Actual:
        </Typography>
        <Typography variant='body2'>${paymentInfo.currentPayment}</Typography>
      </Box>
      <Typography variant='body2' style={{ color: paymentInfo.status }}>
        {paymentInfo.message}
      </Typography>
    </>
  );
};
