import TabPanel from 'components/TabPanel';
import ChangePassword from './ChangePassword';
import LinkConekta from './LinkConekta';
import CorreoBienvenida from './CorreoBienvenida';
import { Box } from '@mui/material';
import { AddDocumentsTrackId } from './AddDocumentsTrackId';
import { UploadToMoodle } from './UploadToMoodle';

const Administration = ({ value, index, dir }) => {
  return (
    <TabPanel value={value} index={index} dir={dir}>
      <Box
        display='flex'
        columnGap='24px'
        rowGap='24px'
        flexDirection={['column', 'column', 'row']}>
        <Box
          display='flex'
          flexDirection='column'
          rowGap='24px'
          columnGap='24px'>
          <ChangePassword />
          <LinkConekta />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          rowGap='24px'
          columnGap='24px'>
          <UploadToMoodle />
          <CorreoBienvenida />
          <AddDocumentsTrackId />
        </Box>
      </Box>
    </TabPanel>
  );
};

export default Administration;
