import { Navigate, useRoutes } from 'react-router-dom';
// import Blog from './pages/Blog';
import DashboardApp from './pages/DashboardApp';
import DashboardLayout from './layouts/dashboard';
import Login from './pages/Login';
import NotFound from './pages/Page404';
// import Products from './pages/Products';
import Register from './pages/Register';
import Estudiantes from './pages/Estudiantes';
import Estudiante from './pages/Estudiante';
import NuevoEstudiante from './pages/NuevoEstudiante';
import { Subjects } from 'pages/Subjects';
import { Terms } from 'pages/Terms';
import { sessionManagement } from './utils/auth';
import { handleAlerts } from 'utils';
import EditForm from 'pages/EditForm';
import PropTypes from 'prop-types';
import { Users } from 'pages/Users';
import { EducationalCenters } from 'pages/EducationalCenters';
import EditEducationalCenter from 'pages/EditEducationalCenter';

const ProtectedRoute = ({ component: Component, ...props }) => {
  const { errorMessage } = handleAlerts();
  const loggedIn = sessionManagement.loggedIn();
  if (!loggedIn)
    errorMessage(
      'Se ha cerrado tu sesión por seguridad, vuelve a iniciar sesión'
    );
  return loggedIn ? <Component {...props} /> : <Navigate to='/login' />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

const LoggedToLogin = ({ component: Component }) => {
  const loggedIn = sessionManagement.loggedIn();
  return loggedIn ? <Navigate to='/c/estudiantes' /> : <Component />;
};

LoggedToLogin.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default function Router() {
  return useRoutes([
    {
      path: '/c',
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          element: <Navigate to='/c/estudiantes' replace={true} />,
        },
        {
          path: 'dashboard',
          element: <ProtectedRoute component={DashboardApp} />,
        },
        {
          path: 'estudiantes',
          element: <ProtectedRoute component={Estudiantes} />,
        },
        {
          path: 'estudiante/nuevo',
          element: <ProtectedRoute component={NuevoEstudiante} replace />,
        },
        {
          path: 'estudiante/:matricula',
          element: <ProtectedRoute component={Estudiante} replace />,
        },
        {
          path: 'adminEscolar/materias',
          element: <ProtectedRoute component={Subjects} />,
        },
        {
          path: 'adminEscolar/periodos',
          element: <ProtectedRoute component={Terms} />,
        },
        {
          path: 'adminEscolar/materias/crear',
          element: <ProtectedRoute component={EditForm} />,
        },
        {
          path: 'adminEscolar/materias/editar/:subjectId',
          element: <ProtectedRoute component={EditForm} />,
        },
        {
          path: 'adminEscolar/periodos/crear',
          element: <ProtectedRoute component={EditForm} />,
        },
        {
          path: 'adminEscolar/periodos/editar/:termId',
          element: <ProtectedRoute component={EditForm} />,
        },
        {
          path: 'adminEscolar/centros-educativos',
          element: <ProtectedRoute component={EducationalCenters} />,
        },
        {
          path: 'adminEscolar/centros-educativos/crear',
          element: <ProtectedRoute component={EditEducationalCenter} />,
        },
        {
          path: 'adminEscolar/centros-educativos/editar/:centerId',
          element: <ProtectedRoute component={EditEducationalCenter} />,
        },
        {
          path: 'usuarios',
          element: <ProtectedRoute component={Users} />,
        },
        // { path: 'config', element: <ProtectedRoute component={Blog} /> },
      ],
    },
    {
      path: '/',
      children: [
        { path: 'login', element: <LoggedToLogin component={Login} /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to='/c' /> },
        { path: '*', element: <Navigate to='/404' /> },
      ],
    },
    {
      path: '/healthcheck',
      element: <div>Healthcheck</div>,
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}
