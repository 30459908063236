import * as React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { Select } from '@mui/material';

const CustomSelect = ({
  value,
  handleChange,
  options,
  label,
  placeholder,
  id,
  name,
  error,
  fullWidth = false,
  size = 'small',
  ...props
}) => {
  return (
    <FormControl
      fullWidth={fullWidth}
      sx={{ minWidth: fullWidth ? 'unset' : 120 }}>
      {label && (
        <Typography
          variant='body2'
          sx={{ fontWeight: 'bold', color: 'grey.600' }}>
          {label}
        </Typography>
      )}
      <Select
        id={id}
        name={name}
        size={size}
        onChange={handleChange}
        value={value}
        fullWidth
        error={!!error}
        helperText={error}
        sx={{ bgcolor: 'white', borderRadius: '8px' }}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Typography variant='body1' sx={{ color: 'grey.500' }}>
                {placeholder}
              </Typography>
            );
          }
          return options.find((option) => option?.value === selected)?.label;
        }}
        {...props}>
        <MenuItem value='' disabled>
          {placeholder || label}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CustomSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  error: PropTypes.string,
};

export default CustomSelect;
