import { integerValidation } from 'utils/validations';
import * as Yup from 'yup';

export const fourthMonthPeriodOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
];

export const subjectTypeOptions = [
  { value: null, label: 'No definido' },
  { value: 'Básico', label: 'Básico' },
  { value: 'Trabajo', label: 'Trabajo' },
  { value: 'Propedeútico', label: 'Propedéutico' },
];

export const SubjectsFormValidationSchema = Yup.object().shape({
  title: Yup.string().required('Este campo es requerido'),
  englishTitle: Yup.string().required('Este campo es requerido'),
  subjectKey: Yup.string().required('Este campo es requerido'),
  tutor: Yup.string().required('Este campo es requerido'),
  credits: Yup.number()
    .test('integer', 'El número debe ser entero', (value) =>
      integerValidation(value)
    )
    .required('Este campo es requerido'),
  fourthMonthPeriod: Yup.string()
    .required('Este campo es requerido')
    .nullable(),
});
