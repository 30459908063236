import React from 'react';
import { isNil, isEmpty } from 'ramda';

const useForm = ({ validations, initialValues, onSubmit }) => {
  const [data, setData] = React.useState(initialValues || {});
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    if (isNil(data) || isEmpty(data)) {
      setData(initialValues);
    }
  }, [initialValues]);

  const handleChange = (key, sanitizeFn) => (event) => {
    const value = sanitizeFn
      ? sanitizeFn(event.target.value)
      : event.target.value;
    const newData = { ...data, [key]: value };
    setData(newData);
    if (errors[key]) {
      delete errors[key];
    }
  };

  const handleChangeEditor = (key, sanitizeFn) => (_event, editor) => {
    const value = sanitizeFn ? sanitizeFn(editor.getData()) : editor.getData();
    setData({ ...data, [key]: value });
    if (errors[key]) {
      delete errors[key];
    }
  };

  const handleChangeCustom = (key, customValue, sanitizeFn) => {
    const value = sanitizeFn ? sanitizeFn(customValue) : customValue;
    setData({ ...data, [key]: value });
    if (errors[key]) {
      delete errors[key];
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validations) {
      let valid = true;
      const newErrors = {};

      Object.keys(validations).forEach((key) => {
        const value = data[key];
        const validation = validations[key];

        if (validation?.required && !value) {
          valid = false;
          newErrors[key] = validation?.required?.message;
        }

        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false;
          newErrors[key] = pattern.message;
        }

        const custom = validation?.custom;
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false;
          newErrors[key] = custom.message;
        }
      });

      if (!valid) {
        setErrors(newErrors);
        return;
      }
    }
    setErrors({});
    if (onSubmit) {
      onSubmit(data);
    }
  };

  const reset = () => {
    setData({});
    setErrors({});
  };

  return {
    data,
    setData,
    handleChange,
    handleChangeEditor,
    handleChangeCustom,
    handleSubmit,
    errors,
    reset,
  };
};

export default useForm;
