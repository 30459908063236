import { Box, Button, Container, Typography } from '@mui/material';
import Page from 'components/Page';
import TermsList from 'components/administration/terms/TermsList';
import { useNavigate } from 'react-router-dom';

export const Terms = () => {
  const navigate = useNavigate();
  return (
    <Page title='Periodos'>
      <Container maxWidth='xl'>
        <Box sx={{ pb: 5, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h4'>Periodos</Typography>
          <Button
            variant='contained'
            onClick={() => {
              navigate('/c/adminEscolar/periodos/crear', {
                state: {
                  title: 'Crear periodo',
                  type: 'create',
                },
              });
            }}>
            Agregar periodo
          </Button>
        </Box>
        <TermsList />
      </Container>
    </Page>
  );
};
