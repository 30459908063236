import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  DialogActions,
  Button,
} from '@mui/material';
import { Cached, Visibility, VisibilityOff } from '@mui/icons-material';
import { ResourcesContext } from 'state/ResourcesProvider';
import { handleAlerts } from 'utils';
import { POST_PASSWORD_STUDENT } from 'utils/api';

const ChangePassword = () => {
  const { state } = React.useContext(ResourcesContext);
  const { estudiante } = state;
  const [password, setPassword] = React.useState('');
  const [confirmacion, setConfirmacion] = React.useState('');
  const { successMessage, errorMessage } = handleAlerts();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmacion) {
      errorMessage('Las contraseñas deben ser iguales');
      return;
    }
    try {
      const response = await POST_PASSWORD_STUDENT(
        estudiante.Persona.UsuarioId,
        password,
        confirmacion
      );
      setPassword('');
      setConfirmacion('');
      successMessage(response.data.message);
    } catch (error) {
      console.log(
        'Error',
        error.message || error.response?.data?.message || error
      );
      errorMessage(error.message || error.response?.data?.message || error);
    }
  };

  return (
    <Card>
      <CardHeader
        title='Cambio de contraseña'
        subheader='Ingresa la nueva contraseña y repítela para confirmar'
      />
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Grid container spacing={3} alignItems='stretch'>
            <Grid item xs={12}>
              <TextField
                value={password}
                type={showPassword ? 'text' : 'password'}
                label='Contraseña'
                variant='outlined'
                onChange={(event) => setPassword(event.target.value)}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={confirmacion}
                type={showPassword ? 'text' : 'password'}
                label='Confirmación de la contraseña'
                variant='outlined'
                fullWidth
                onChange={(event) => setConfirmacion(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <DialogActions sx={{ mr: 1, mb: 1 }}>
          <Button startIcon={<Cached />} type='submit' variant='contained'>
            Actualizar
          </Button>
        </DialogActions>
      </form>
    </Card>
  );
};

export default ChangePassword;
