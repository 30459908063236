import download from 'downloadjs';
import { useContext } from 'react';
import { StateContext } from 'state/StateProvider';
import { handleAlerts } from 'utils';
import { GET_DOCUMENT } from 'utils/api';
import backend from 'utils/backend';

export const useGetDocument = (registrationId, term) => {
  const server = backend();
  const { dispatch: stateDispatch } = useContext(StateContext);
  const { successMessage, errorMessage } = handleAlerts();

  const downloadDocument = async (url, documentName, message) => {
    try {
      stateDispatch({ type: 'START_LOADING' });
      const response = await GET_DOCUMENT(url);
      download(response.data, documentName, 'application/pdf');
      successMessage(message);
    } catch (error) {
      errorMessage(
        error.response?.data?.message || error.response?.statusText || error
      );
    } finally {
      stateDispatch({ type: 'STOP_LOADING' });
    }
  };

  const getBoletaPDF = async (lang, isSigned = false) => {
    const url = `${server}/estudiantes/${registrationId}/documentos?documento=boleta&idioma=${lang}&periodo=${term}&firma=${isSigned}`;
    const document = `boleta_${term}_${registrationId}_ES.pdf`;
    const message = 'Se ha descargado la boleta correctamente';
    await downloadDocument(url, document, message);
  };

  return { getBoletaPDF };
};
