import React from 'react';
import {
  Button,
  Slide,
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Confirma = (props) => {
  const { open, setOpen, titulo = '¿Deseas borrar el pago?', action } = props;

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}>
      <DialogTitle id='alert-dialog-slide-title' color='secondary'>
        {titulo}
      </DialogTitle>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        padding='40px'>
        <img
          alt='delete user'
          src='/static/illustrations/delete.svg'
          style={{ height: '130px' }}
        />
      </Box>
      <DialogActions>
        <Button onClick={action} color='secondary' variant='contained'>
          Sí
        </Button>
        <Button onClick={handleClose} color='secondary' variant='outlined'>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirma;
