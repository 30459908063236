import React, { useContext } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { Upload } from '@mui/icons-material';
import { ResourcesContext } from 'state/ResourcesProvider';
import { UPLOAD_TO_MOODLE } from 'utils/api';
import { handleAlerts } from 'utils';

/**
 * Uploads the student's information to Moodle if certain conditions are met.
 * @returns {JSX.Element} The component for uploading to Moodle.
 */

export const UploadToMoodle = () => {
  const { state } = useContext(ResourcesContext);
  const { estudiante } = state;
  const { errorMessage, successMessage } = handleAlerts();

  const currentTerm = estudiante.Periodos?.find(
    (periodo) => periodo?.Periodo?.periodo === state.periodoActual?.periodo
  );
  const isMinimumPayment = currentTerm?.pagoActual >= 900;
  const isEnrolled = estudiante?.Estatus.descripcion === 'Inscrito';
  const hasScholarship = estudiante?.becado;
  const hasSubjects = currentTerm?.Materias.length > 0;

  // If the student has a scholarship, they only need to be enrolled and have subjects.
  const scholarShipConditions = isEnrolled && hasSubjects;

  // If the student doesn't have a scholarship, they need to be enrolled, have subjects, and have a minimum payment.
  const noScholarShipConditions = isEnrolled && hasSubjects && isMinimumPayment;

  // The student can be uploaded if they meet the conditions.
  const canBeUploaded = hasScholarship
    ? scholarShipConditions
    : noScholarShipConditions;

  const handleUpload = async () => {
    try {
      await UPLOAD_TO_MOODLE(estudiante.matricula);
      successMessage('Subido a Moodle');
    } catch (error) {
      console.error(error);
      errorMessage('Error al subir a Moodle. Vuelve a intentarlo más tarde.');
    }
  };

  return (
    <Card>
      <CardHeader title='Subir a Moodle' />
      <CardContent>
        <Typography variant='body1' color='textSecondary'>
          Una vez que el estudiante esté inscrito, tenga un pago mínimo de $900
          MXN y haya elegido sus materias, se podrá subir a Moodle.
        </Typography>
      </CardContent>
      <Box display='flex' justifyContent='flex-end' marginTop='16px'>
        <Button
          onClick={handleUpload}
          disabled={!canBeUploaded}
          startIcon={<Upload />}
          variant='contained'
          color='primary'>
          Subir a Moodle
        </Button>
      </Box>
    </Card>
  );
};
