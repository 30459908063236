import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { ListItems } from 'components/ListItem';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';

const CardSubjects = ({ subject }) => {
  const navigate = useNavigate();

  return (
    <ListItems>
      <Box
        sx={{
          display: 'grid',
          width: '100%',
          alignItems: 'center',
        }}
        gridTemplateColumns={['2fr 1fr', '2fr 1fr 1fr 1fr']}
        gridTemplateRows={['1fr 1fr', '1fr']}>
        <Typography variant='body1'>{subject.titulo}</Typography>
        <Typography
          variant='overline'
          sx={{
            justifySelf: 'start',
            gridArea: '2/1/3/2',
            '@media (min-width:600px)': {
              gridArea: 'unset',
              justifySelf: 'center',
            },
          }}>
          {subject.clave}
        </Typography>
        <Chip
          label={subject.optativa ? 'Optativa' : 'Obligatoria'}
          color={subject.optativa ? 'secondary' : 'primary'}
          size='small'
          sx={{
            width: '90px',
            justifySelf: 'end',
            gridArea: '2/2/3/3',
            '@media (min-width:600px)': {
              gridArea: 'unset',
            },
          }}
        />
        <IconButton
          sx={{
            color: 'grey.600',
            justifySelf: 'end',
          }}
          onClick={() =>
            navigate(`/c/adminEscolar/materias/editar/${subject.id}`, {
              state: {
                title: 'Editar materia',
                type: 'edit',
                elementKey: subject.clave,
                defaultValues: subject,
              },
            })
          }>
          <EditIcon />
        </IconButton>
      </Box>
    </ListItems>
  );
};

CardSubjects.propTypes = {
  subject: PropTypes.shape({
    titulo: PropTypes.string,
    clave: PropTypes.string,
    optativa: PropTypes.bool,
    id: PropTypes.number,
  }).isRequired,
};

export default CardSubjects;
